<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" :max-width="new_or_search=='search'?1000:900" content-class="k-tall-dialogx" overlay-opacity="0.9" persistent scrollable>
		<v-card>
			<v-card-title class="indigo darken-3 white--text" style="border-bottom:1px solid #999; padding:8px 8px 8px 16px;">
				<b v-html="cdialog_title"></b>
				<v-spacer/>
				<!-- hidden button to show search scores -->
				<v-btn x-small style="opacity:0" @click="$store.state.show_search_match_scores=!$store.state.show_search_match_scores">sms</v-btn>
				<v-spacer/>
				<div v-if="!search_only" class="mr-4" v-show="searchable_items.length"><v-btn :text="!at_least_one_item_selected" :color="at_least_one_item_selected?'#fff':'#999'" @click="add_selected_items"><v-icon v-show="at_least_one_item_selected" small class="mr-2">fas fa-check</v-icon>Add selected item{{max_items!=1?'s':''}}</v-btn></div>
				<v-btn color="#fff" text @click="done_clicked">Done<v-icon class="ml-2">fas fa-xmark</v-icon></v-btn>
			</v-card-title>
			<v-card-text class="k-search-card pt-3">
				<!-- Search/Create toggle -->
				<div v-if="!search_only" class="my-1 d-flex"><v-spacer/>
					<v-btn-toggle dense active-class="k-toggle-btn-active-class indigo darken-4" class="k-toggle-btn" v-model="new_or_search" mandatory>
						<v-btn style="width:240px" light class="k-nocaps-btn k-tight-btn" value="search" @click.stop=""><v-icon small :color="new_or_search=='search'?'white':'black'" class="mr-2">fas fa-search</v-icon>Search EXISTING</v-btn>
						<v-btn light class="k-nocaps-btn k-tight-btn" value="shared" @click.stop=""><v-icon small :color="new_or_search=='shared'?'white':'black'" class="mr-2">fas fa-share</v-icon>Import SHARED</v-btn>
						<v-btn light class="k-nocaps-btn k-tight-btn" value="new" @click.stop=""><v-icon small :color="new_or_search=='new'?'white':'black'" class="mr-2">fas fa-plus</v-icon>Create NEW</v-btn>
					</v-btn-toggle>
				<v-spacer/></div>

				<!-- Create interface -->
				<div v-show="new_or_search=='new'">
					<div v-if="citem_types.length>1" class="mt-6 mb-4 d-flex align-center"><v-spacer/>
						<b style="font-size:20px">Create New:</b>
						<v-btn v-if="citem_types.includes('lessons')" class="ml-3" dark color="blue darken-3" @click="create_lesson_start"><span class="pl-3 pr-5"><v-icon small class="mr-2">fas fa-rectangle-list</v-icon>Lesson Plan</span></v-btn>
						<v-btn v-if="citem_types.includes('activities')" class="ml-3" dark color="deep-purple darken-3" @click="create_activity_start"><v-icon small class="mr-2">fas fa-star</v-icon>{{site_config.sparkl_app_name}} Student Activity</v-btn>
						<v-btn v-if="citem_types.includes('resources')" class="ml-3" dark color="teal darken-3" @click="create_new_resource"><v-icon small class="mr-2">fas fa-link</v-icon>Other Resource</v-btn>
					<v-spacer/></div>
					<div v-if="citem_types.length==1" class="mt-6 mb-4 d-flex align-center"><v-spacer/>
						<v-btn v-if="citem_types[0]=='lessons'" dark color="blue darken-3" @click="create_lesson_start"><span class="pl-3 pr-5"><v-icon small class="mr-2">fas fa-rectangle-list</v-icon>Create New Lesson Plan</span></v-btn>
						<v-btn v-if="citem_types[0]=='activities'" dark color="deep-purple darken-3" @click="create_activity_start"><v-icon small class="mr-2">fas fa-star</v-icon>Create New {{site_config.sparkl_app_name}} Student Activity</v-btn>
						<v-btn v-if="citem_types[0]=='resources'" dark color="teal darken-3" @click="create_new_resource"><v-icon small class="mr-2">fas fa-link</v-icon>Create New Resource</v-btn>
					<v-spacer/></div>
					<v-hover v-slot:default="{hover}" v-if="show_alba_btn"><div style="position:absolute; right:104px; bottom:4px; background-color:#ccc; font-size:14px; line-height:14px; padding:4px; border-radius:6px; cursor:pointer" :style="'opacity:'+(hover?'0.5':'0')" @click="alba_showing=true">ALBA</div></v-hover>
					<v-hover v-slot:default="{hover}" v-if="show_gavs_btn"><div style="position:absolute; right:4px; bottom:4px; background-color:#ccc; font-size:14px; line-height:14px; padding:4px; border-radius:6px; cursor:pointer" :style="'opacity:'+(hover?'0.5':'0')" @click="bulk_activity_editor_showing=true">GAVS IMPORT</div></v-hover>
				</div>

				<!-- Import interface -->
				<div v-show="new_or_search=='shared'">
					<div v-if="citem_types.length>1" class="mt-6 mb-4 d-flex align-center"><v-spacer/>
						<b style="font-size:20px">Import Shared:</b>
						<v-btn v-if="citem_types.includes('lessons')" class="ml-3" dark color="blue darken-3" @click="import_shared_item('lesson')"><span class="pl-3 pr-5"><v-icon small class="mr-2">fas fa-rectangle-list</v-icon>Lesson Plan</span></v-btn>
						<v-btn v-if="citem_types.includes('activities')" class="ml-3" dark color="deep-purple darken-3" @click="import_shared_item('activity')"><v-icon small class="mr-2">fas fa-star</v-icon>{{site_config.sparkl_app_name}} Student Activity</v-btn>
						<v-btn v-if="citem_types.includes('resources')" class="ml-3" dark color="teal darken-3" @click="import_shared_item('resource')"><v-icon small class="mr-2">fas fa-link</v-icon>Other Resource</v-btn>
					<v-spacer/></div>
					<div v-if="citem_types.length==1" class="mt-6 mb-4 d-flex align-center"><v-spacer/>
						<v-btn v-if="citem_types[0]=='lessons'" dark color="blue darken-3" @click="import_shared_item('lesson')"><span class="pl-3 pr-5"><v-icon small class="mr-2">fas fa-rectangle-list</v-icon>Import Shared Lesson</span></v-btn>
						<v-btn v-if="citem_types[0]=='activities'" dark color="deep-purple darken-3" @click="import_shared_item('activity')"><v-icon small class="mr-2">fas fa-star</v-icon>Import Shared {{site_config.sparkl_app_name}} Student Activity</v-btn>
						<v-btn v-if="citem_types[0]=='resources'" dark color="teal darken-3" @click="import_shared_item('resource')"><v-icon small class="mr-2">fas fa-link</v-icon>Import Shared Resource</v-btn>
					<v-spacer/></div>
					<div>
						<div v-for="(sr, i) in imported_items" :key="sr.item_id">
							<div v-if="i==0" class="k-full-resource-search-result-category">
								<b>Imported Items ({{imported_items.length}})</b>
							</div>
							<div class="k-resource-folder-resource-item-full-wrapper k-resource-folder-resource-item-hovered k-full-resource-search-result-already-added" style="display:flex;">
								<div v-if="!search_only"><v-checkbox class="shrink mt-0 pt-0 d-inline-block" :disabled="true" hide-details v-model="sr.selected"></v-checkbox></div>
								<div style="flex:1 0 100px"><ResourceCollectionItem :key="sr.item_id" :full_width_resource="true"
									:item="sr.value"
									:option_overrides="rci_option_overrides(sr)"
									:lp_context="collection_showing"
									@edit_item_start="edit_item_start" @unpublish_item_start="unpublish_item_start"
								/></div>
							</div>
						</div>
					</div>
				</div>

				<!-- Search interface -->
				<div v-show="new_or_search=='search'">
					<div class="mb-2 d-flex flex-wrap justify-center align-center">
						<div class="d-flex mx-2 my-2 py-2 k-search-options-holder">
							<div class="mr-3" style="margin-top:6px"><b>Search In:</b></div>

							<v-menu offset-y bottom v-model="limit_menu_showing">
								<template v-slot:activator="{on}"><v-btn v-on="on" class="k-nocaps-btn" style="letter-spacing:0.25px" color="indigo darken-3" dark>
									<!-- <v-icon small class="mr-1">fas fa-filter</v-icon>Select… -->
									<div v-if="selected_home_lesson" class="k-search-options-search-in-descriptor">
										<b>Resources tagged to this lesson</b>
									</div>
									<div v-if="selected_home_collection" class="k-search-options-search-in-descriptor">
										{{home_collection.title}}
									</div>
									<div v-if="selected_my" class="k-search-options-search-in-descriptor">
										{{site_config.default_my_collection_label}}
									</div>
									<div v-if="selected_collection" class="k-search-options-search-in-descriptor">
										{{selected_collection.title}}
									</div>
									<div v-if="search_all" class="k-search-options-search-in-descriptor">
										All items in {{site_config.app_name}}
									</div>
									<v-icon color="#fff" class="ml-2">fas fa-caret-down</v-icon>
								</v-btn></template>
								<v-list min-width="450" dense>
									<v-list-item v-if="home_lesson&&allow_add_from_home" @click.stop="selected_home_lesson=!selected_home_lesson"><v-list-item-icon style="margin-top:8px;margin-bottom:12px;"><span :class="selected_home_lesson?'indigo darken-3':''" style="width:30px;height:30px;border-radius:30px;text-align:center;line-height:30px;"><v-icon style="margin-top:-3px;width:30px;height:30px;" :color="selected_home_lesson?'#fff':''" small>fas fa-rectangle-list</v-icon></span></v-list-item-icon><v-list-item-title :style="selected_home_lesson?'font-weight:bold':''" :class="selected_home_lesson?'indigo--text text--darken-3':''">Resources tagged to this lesson</v-list-item-title></v-list-item>
									<v-list-item @click.stop="select_none"><v-list-item-icon style="margin-top:8px;margin-bottom:12px;"><span :class="search_all?'indigo darken-3':''" style="width:30px;height:30px;border-radius:30px;text-align:center;line-height:30px;"><v-icon style="margin-top:-3px;width:30px;height:30px;" :color="search_all?'#fff':''" small>fas fa-globe</v-icon></span></v-list-item-icon><v-list-item-title :style="search_all?'font-weight:bold':''" :class="search_all?'indigo--text text--darken-3':''">All items in {{site_config.app_name}}</v-list-item-title></v-list-item>
									<!-- can't add from my_default_collection TO my_default_collection; also if the user isn't signed in, no sandbox -->
									<v-list-item v-if="signed_in&&home_collection!=my_default_collection" @click.stop="selected_my=!selected_my"><v-list-item-icon style="margin-top:8px;margin-bottom:12px;"><span :class="selected_my?'indigo darken-3':''" style="width:30px;height:30px;border-radius:30px;text-align:center;line-height:30px;"><v-icon style="margin-top:-3px;width:30px;height:30px;" :color="selected_my?'#fff':''" small>fas fa-cubes-stacked</v-icon></span></v-list-item-icon><v-list-item-title :style="selected_my?'font-weight:bold':''" :class="selected_my?'indigo--text text--darken-3':''">{{site_config.default_my_collection_label}}</v-list-item-title></v-list-item>
									<v-list-item v-if="home_collection&&allow_add_from_home" @click.stop="selected_home_collection=!selected_home_collection"><v-list-item-icon style="margin-top:8px;margin-bottom:12px;"><span :class="selected_home_collection?'indigo darken-3':''" style="width:30px;height:30px;border-radius:30px;text-align:center;line-height:30px;"><v-icon style="margin-top:-3px;width:30px;height:30px;" :color="selected_home_collection?'#fff':''" small>{{home_collection.collection_icon()}}</v-icon></span></v-list-item-icon><v-list-item-title :style="selected_home_collection?'font-weight:bold':''" :class="selected_home_collection?'indigo--text text--darken-3':''">This content collection (“{{home_collection.title}}”)</v-list-item-title></v-list-item>
									<v-list-item @click.stop=""><v-list-item-icon v-if="selected_collection" style="margin-top:12px"><span :class="selected_collection?'indigo darken-3':''" style="width:30px;height:30px;border-radius:30px;text-align:center;line-height:30px;"><v-icon style="margin-top:-3px;width:30px;height:30px;" :color="selected_collection?'#fff':''" small>{{selected_collection.collection_icon()}}</v-icon></span></v-list-item-icon><v-list-item-title><div class="mt-2">
										<v-autocomplete clearable v-model="selected_collection" :items="course_selection_array" label="Choose a Course, Repository, or Collection" outlined hide-details dense background-color="#fff" @change="">
											<template v-slot:item="data"><div style="font-size:14px;">
												<v-icon small class="mr-1" color="#000" style="font-size:14px; margin-top:-2px">{{ data.item.value.collection_icon() }}</v-icon>
												{{ data.item.value.title }}
											</div></template>
										</v-autocomplete>
									</div></v-list-item-title></v-list-item>
								</v-list>
							</v-menu>
						</div>

						<div v-if="unit_selection_array" class="d-flex mx-2 my-2 align-center k-search-options-holder" style="padding-top:6px; padding-bottom:6px;">
							<div class="mr-3"><b>Unit:</b></div>
							<div style="width:300px">
								<v-select v-model="selected_unit" :items="unit_selection_array" outlined label="" hide-details dense>
									<template v-slot:item="data"><div style="font-size:14px;" :style="data.item.value==null?'font-weight:bold; width:100%;text-align:center':''">
										<v-icon v-if="data.item.value!=null" small class="mr-1" color="#000" style="font-size:14px; margin-top:-2px">fas fa-circle-arrow-right</v-icon>
										{{ data.item.text }}
									</div></template>
								</v-select>
							</div>
						</div>

						<div v-if="search_all" class="d-flex mx-2 my-2 pr-0 k-search-options-holder">
							<div class="mr-3"><b>Search By:</b></div>
							<v-radio-group v-model="search_crit" class="mt-0 pt-0 d-inline-block" hide-details row>
								<v-radio label="Keywords/search terms" color="indigo darken-3" :value="'keywords'" off-icon="far fa-circle" on-icon="far fa-circle-dot"></v-radio>
								<v-radio label="Standards" color="indigo darken-3" :value="'standards'" off-icon="far fa-circle" on-icon="far fa-circle-dot"></v-radio>
							</v-radio-group>
						</div>

						<div v-if="search_all && !chosen_framework_override" class="d-flex mx-2 my-2 align-center k-search-options-holder" style="padding-top:6px; padding-bottom:6px;">
							<div class="mr-3"><b>Choose a Subject:</b></div>
							<div style="width:300px"><v-select v-model="subject" :items="subjects" outlined label="" hide-details dense :menu-props="{maxHeight:700}">
								<template v-slot:item="data"><div style="font-size:14px;" :style="data.item.value=='all'?'font-weight:bold; border-top:1px solid #000; width:100%;text-align:center; padding-top:8px;':''">
									<v-icon v-if="data.item.value!='all'" small class="mr-1" color="#000" style="font-size:14px; margin-top:-2px">fas fa-map</v-icon>
									{{ data.item.text }}
								</div></template>
							</v-select></div>
						</div>

					</div>

					<div v-if="search_all && current_server_search_terms && !chosen_cfitem" class="d-flex mb-4">
						<v-spacer/>
						<div class="k-search-options-holder">
							<nobr>Search terms:</nobr>
							<b class="indigo--text text--darken-4 ml-1" style="font-size:18px;">{{ current_server_search_terms }}</b>
							<v-btn small class="ml-4 k-tight-btn" color="primary" @click="reset_search_all">New Search <v-icon small class="ml-2">fas fa-rotate-left</v-icon></v-btn>
						</div>
						<v-spacer/>
					</div>

					<div v-if="search_all && chosen_cfitem" class="d-flex mb-4">
						<v-spacer/>
						<div class="k-search-options-holder d-flex" style="line-height:22px">
							<!-- <nobr class="mr-2">Current standard:</nobr> -->
							<span v-html="rendered_cfitem(chosen_cfitem)" style="white-space:normal"></span>
							<v-btn v-if="!chosen_framework_override" small class="ml-2 k-tight-btn" color="primary" @click="reset_search_all">Change Standard <v-icon small class="ml-2">fas fa-rotate-left</v-icon></v-btn>
						</div>
						<v-spacer/>
					</div>

					<div v-if="!search_all || (search_all && subject)" class="mb-4 d-flex align-center">
						<v-spacer/>
						<div style="flex:0 0 auto; width:600px; max-width:calc(100vw - 240px)">
							<v-text-field light rounded outlined solo hide-details clearable color="indigo darken-3"
								:placeholder="search_box_placeholder"
								v-model="search_terms"
								:prepend-inner-icon="(search_all && (!current_server_search_terms && !chosen_cfitem)) ? 'fas fa-search' : 'fas fa-filter'" 
								@click:prepend-inner="initiate_search_from_keyboard_or_search_icon"
								@click:clear="execute_search_clear"
								@keyup="search_field_keyup"
							></v-text-field>
						</div>
						<v-btn v-if="search_all && keyword_search && !current_server_search_terms" color="primary" class="ml-3" @click="initiate_search_from_keyboard_or_search_icon"><v-icon class="mr-2">fas fa-search</v-icon>Search</v-btn>
						<v-btn v-if="search_all && standard_search && !satchel_showing && !chosen_cfitem" color="primary" class="ml-3 k-tight-btn" @click="browse_standards"><v-icon class="mr-2">fas fa-map</v-icon>Browse Standards</v-btn>

						<v-spacer/>
					</div>

					<div v-if="search_all && search_all_help" class="text-center my-2"><i v-html="search_all_help"></i></div>
					<div v-if="no_results_help" class="my-2 k-resource-search-results-no-results" v-html="no_results_help"></div>

					<div>
						<div v-if="searchable_items.length>0" class="d-flex align-center mb-2">
							<v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="item_type_showing" mandatory>
								<v-btn v-if="searchable_category_count['1lesson']" class="k-tight-btn" style="width:168px; min-width:168px;" small light :value="'1lesson'" @click.stop="">
									Lesson Plans 
									<span v-if="!search_all||search_crit=='standards'" class="ml-1">({{searchable_category_count['1lesson']}})</span>
								</v-btn>
								<v-btn v-if="searchable_category_count['2sparkl']" class="k-tight-btn" style="width:168px; min-width:168px;" small light :value="'2sparkl'" @click.stop="">
									Student Activities 
									<span v-if="!search_all||search_crit=='standards'" class="ml-1">({{searchable_category_count['2sparkl']}})</span>
								</v-btn>
								<v-btn v-if="searchable_category_count['3resource']" class="k-tight-btn" style="width:168px; min-width:168px;" small light :value="'3resource'" @click.stop="">
									<span class="mr-1" v-if="searchable_category_count['1lesson'] || searchable_category_count['2sparkl']">Other</span> Resources 
									<span v-if="!search_all||search_crit=='standards'" class="ml-1">({{searchable_category_count['3resource']}})</span>
								</v-btn>
							</v-btn-toggle>
							<v-spacer/>
							<div v-if="selected_my_or_sandbox" class="d-flex" style="flex:0 0 100px">
								<nobr style="font-size:14px" class="mr-2">Sort by:</nobr>
								<div>
									<v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="resource_search_sort_by" mandatory>
										<v-btn x-small width="80px" class="k-tight-btn" light :value="'title'" @click.stop="resource_search_sort_by = 'title'">Title</v-btn>
										<v-btn x-small width="80px" class="k-tight-btn" light :value="'created_at'" @click.stop="set_resource_search_sort_order">Date Created</v-btn>
									</v-btn-toggle>
								</div>
							</div>
						</div>
						
						<div v-if="search_crit=='standards' && !chosen_cfitem" class="k-standards-search-results-wrapper">
							<div v-for="(cfitem, i) in standard_search_results" :key="cfitem.identifier" v-if="i < 25" class="k-standards-search-result d-flex px-2 pb-1 pt-2">
								<v-btn small icon color="primary" class="mr-2" style="margin-top:-4px;" @click="browse_standards(cfitem)"><v-icon small>fas fa-map</v-icon></v-btn>
								<v-btn x-small color="primary" class="mr-2 k-tight-btn" @click="choose_cfitem_for_search(cfitem)">Choose</v-btn>
								<div v-html="rendered_cfitem(cfitem)"></div>
							</div>
							<div v-if="standard_search_results.length>25" class="mt-2 text-center"><i><b>Showing first 25 of {{standard_search_results.length}} items; enter additional terms to improve search</b></i></div>
						</div>

						<div v-for="(sr, i) in visible_search_results" :key="sr.item_id" class="k-resource-folder-resource-container-width-full">
							<div class="k-resource-folder-resource-item-full-wrapper k-resource-folder-resource-item-hovered" style="display:flex;" :class="asset_css(sr)">
								<div v-if="!search_only" style="flex:0 1 auto"><v-checkbox class="shrink mt-0 pt-0 d-inline-block" :disabled="sr.already_added" hide-details v-model="sr.selected" @change="item_checked(sr)"></v-checkbox></div>
								<!-- show search match scores: vapp.$store.state.show_search_match_scores = true -->
								<div v-if="keyword_search&&$store.state.show_search_match_scores" style="font-size:12px; margin-top:3px;">{{ match_scores[sr.item_id] }}</div>
								<div style="flex:1 0 100px;"><ResourceCollectionItem :key="sr.item_id" :full_width_resource="true"
									:item="sr.value"
									:title_override="sr.text"
									:option_overrides="rci_option_overrides(sr)"
									:lp_context="collection_showing"
									@edit_item_start="edit_item_start" @unpublish_item_start="unpublish_item_start"
								/></div>
							</div>
						</div>

						<div v-if="visible_search_results.length > 0 && visible_search_results.length < searchable_category_count[item_type_showing]" class="text-center mt-3 mb-1">
							<v-btn small color="indigo darken-3" dark @click="show_more_results(item_type_showing)"><v-icon small class="mr-2">fas fa-plus</v-icon> Show More</v-btn>
						</div>
						<div v-if="search_all && visible_search_results.length > 0 && visible_search_results.length >= searchable_category_count[item_type_showing]" class="text-center mt-3 mb-1">
							<i>End of search results. Refine your search terms to find additional resources.</i>
						</div>
					</div>
				</div>

			</v-card-text>
		</v-card>

		<ResourceEditor v-if="new_resource_placeholder" :original_resource="new_resource_placeholder" :course="this.home_collection"
			@edit_resource_cancel="create_resource_cancel"
			@edit_resource_saved="create_resource_saved"
		/>

		<v-dialog v-if="previewed_lesson" v-model="previewed_lesson" max-width="900" persistent scrollable content-class="k-resource-collection-item-lesson-card-dialog">
			<v-card class="k-resource-collection-item-lesson-card">
				<div class="d-flex align-center pb-2" style="border-bottom:1px solid #999;">
					<v-icon class="mr-2">fas fa-rectangle-list</v-icon>
					<div class="k-lesson-title" style="font-weight:bold" v-html="previewed_lesson.lesson_title"></div>
				</div>
				<LessonView :lesson="previewed_lesson" />
				<v-card-actions class="px-1 pt-2 pb-0" style="border-top:1px solid #999;">
					<v-spacer/>
					<v-btn color="secondary" dark @click="previewed_lesson=false"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-if="new_lesson" v-model="new_lesson" :max-width="use_enhanced_lesson_editor?1100:900" persistent scrollable :retain-focus="false" content-class="k-resource-collection-item-lesson-card-dialog k-lpe-editor-dialog">
			<v-card class="k-resource-collection-item-lesson-card">
				<div class="d-flex">
					<v-icon class="mr-2">fas fa-rectangle-list</v-icon>
					<div class="k-lesson-title" style="font-weight:bold">New Lesson Plan</div>
					<v-spacer/>
					
					<div v-if="!show_lesson_variant_switcher&&use_enhanced_lesson_editor" style="cursor:pointer" @click="show_llm_lesson_plan_description"><v-icon color="light-blue" class="mr-2">fas fa-info-circle</v-icon>Lesson Plan Companion<v-icon class="k-lpe-dog-icon mx-2" style="margin-top:-5px">fas fa-dog</v-icon>(<b class="red--text text--darken-3">BETA</b>)</div>
					<v-checkbox v-if="show_lesson_variant_switcher" class="mt-0 pt-0" v-model="use_llm_for_new_lessons" @click="use_llm_clicked" hide-details off-icon="far fa-square" on-icon="fas fa-check-square"><template v-slot:label><span style="color:#444">Use Lesson Plan Companion <v-icon class="k-lpe-dog-icon" style="margin-top:-5px">fas fa-dog</v-icon> (<b class="red--text text--darken-3">BETA</b>)</span></template></v-checkbox>
				</div>
				<div :class="(use_enhanced_lesson_editor)?'k-lpe-lesson-edit-outer':'k-lesson-edit-outer'">
					<LessonEditorEnhanced ref="lesson_editor" v-if="use_enhanced_lesson_editor" :original_lesson="new_lesson" :lesson_class="new_lesson_class" :course_code="new_lesson_course_code" :lp_unit_id="new_lesson_lp_unit_id" @edit_lesson_cancel="create_lesson_cancel" @edit_lesson_saved="create_lesson_saved" />
					<LessonEditor ref="lesson_editor" v-else :original_lesson="new_lesson" :lesson_class="new_lesson_class" :course_code="new_lesson_course_code" :lp_unit_id="new_lesson_lp_unit_id" @edit_lesson_cancel="create_lesson_cancel" @edit_lesson_saved="create_lesson_saved" />
				</div>
			</v-card>
		</v-dialog>

		<BulkActivityImport v-if="bulk_activity_editor_showing" :unit="home_unit" :collection="home_collection" @dialog_close="bulk_activity_editor_showing=false"></BulkActivityImport>
		<AILessonBuilder v-if="alba_showing" :unit="home_unit" :collection="home_collection" @dialog_close="alba_showing=false"></AILessonBuilder>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ResourceEditor from '../resources/ResourceEditor'
import LessonView from '../lessons/LessonView'
import LessonEditor from '../lessons/LessonEditor'
import LessonEditorEnhanced from '../lessons/LessonEditorEnhanced'
import BulkActivityImport from './BulkActivityImport'
import AILessonBuilder from './AILessonBuilder'
import ResourceCollectionItem from '../resources/ResourceCollectionItem'

export default {
	name: 'ResourceSearchNew',
	components: { ResourceEditor, LessonView, LessonEditor, LessonEditorEnhanced, BulkActivityImport, AILessonBuilder, ResourceCollectionItem },
	props: {
		// req: { type: String, required: true },
		dialog_title: { type: String, required: false, default() { return 'Search Lessons, Activities, and other Resources'} },
		item_types: { required: false, default() { return ['resources','lessons','activities']} },
		existing_resources: { required: false, default() { return [] } },
		existing_lessons: { required: false, default() { return [] } },
		existing_activities: { required: false, default() { return [] } },
		home_lesson: { type: Object, required: false, default() { return null } },
		home_unit: { type: Object, required: false, default() { return null } },
		home_collection: { type: Object, required: false, default() { return null } },
		// MC: No longer necessary?
		allow_add_from_home: { type: Boolean, required: false, default() { return false } },
		max_items: { required: false, default() { return 0 }},	// 0 = no max; 1 = choose one
		adding_to_lesson: { required: false, default() { return false }},	// send true when we launch from LessonEditor
		search_only: { required: false, default() { return false }},	// send true to disable the import shared / create new options

		default_source: { required: false, default() { return null }},	// send 'site', 'home_collection', or 'my' to default to searching there

		// these are for external searches by standard
		default_framework_identifier: { required: false, default() { return '' }},
		starting_item_identifier: { required: false, default() { return '' }},
	},
	data() { return {
		dialog_open: true,

		search_terms: '',
		current_server_search_terms: '',
		last_search_terms: '',
		search_term_res: [],

		satchel_showing: false,
		chosen_cfitem: null,
		chosen_framework_override: '',

		// searchable_items holds the "pool" of items we're showing
		searchable_items: [],
		searchable_category_count: {'1lesson':0, '2sparkl':0, '3resource':0},

		// filtered_items shows items that match the current filters (including the search term filter)
		filtered_items: [],
		filtered_category_count: {'1lesson':0, '2sparkl':0, '3resource':0},

		standard_search_results: [],
		server_search_results: {},
		no_filter_search_text: '',

		keyword_search_asset_count_per_category: 100,

		selected_home_lesson: false,
		selected_home_collection: false,
		selected_my: false,
		selected_collection: '',

		selected_unit: null,

		previewed_lesson: null,
		new_lesson: null,
		new_lesson_class: '',
		new_lesson_course_code: '',
		new_lesson_lp_unit_id: 0,

		open_sparkl_activity_embed_object: null,
		sparkl_closed_from_embed: false,
		new_activity: null,
		new_activity_saved: false,

		limit_menu_showing: false,

		search_result_icons: {
			resource: 'fas fa-link',
			activity: 'fas fa-star',
			lesson: 'fas fa-rectangle-list',
		},

		last_previewed_item: null,

		imported_items: [],
		new_resource_placeholder: null,

		items_per_page: 25,
		items_showing_count: {},

		bulk_activity_editor_showing: false,
		alba_showing: false,
		match_scores: {}
	}},
	computed: {
		...mapState(['user_info', 'site_config', 'all_courses_loaded', 'all_courses', 'lst', 'added_my_courses', 'asset_search_vectors', 'asset_search_term_vectors']),
		...mapGetters(['my_default_collection', 'signed_in']),

		// determine whether or not to allow the user to toggle between the LPC and the "OG" lesson editor
		show_lesson_variant_switcher() {
			return U.show_lesson_variant_switcher(this.new_lesson?.lesson_id, this.home_collection?.course_code)
		},
		
		// now separately, determine whether or not to *use* the LPC or the OG lesson editor
		use_enhanced_lesson_editor() { return this.new_lesson.lp_variant=='B' },

		// and determine which version to use for new lessons
		use_llm_for_new_lessons: {
			get() { return U.use_llm_for_new_lessons(this.home_collection?.course_code) },
			set(val) { this.$store.commit('lst_set', ['use_llm_for_new_lessons', val]) }
		},

		citem_types() {
			// start with the item_types from props, but eliminate any that we're not allowing via supported_content_types
			let arr = []
			for (let t of this.item_types) {
				if (this.site_config.supported_content_types.includes(t)) arr.push(t)
			}
			return arr
		},
		cdialog_title() {
			let s = this.dialog_title
			// strip 'lessons' or 'activities' out of dialog title if we're not supporting them
			if (!this.site_config.supported_content_types.includes('lessons')) s = s.replace(/lessons/i, '')
			if (!this.site_config.supported_content_types.includes('activities')) s = s.replace(/activities/i, '')
			if (this.site_config.supported_content_types.length < 3) s = s.replace(/,/g, '')
			return s
		},
		resource_search_sort_by: {
			get() { return this.$store.state.lst.resource_search_sort_by },
			set(val) { this.$store.commit('lst_set', ['resource_search_sort_by', val]) }
		},
		resource_search_sort_by_created_at_order: {
			get() { return this.$store.state.lst.resource_search_sort_by_created_at_order },
			set(val) { this.$store.commit('lst_set', ['resource_search_sort_by_created_at_order', val]) }
		},
		new_or_search: {
			get() { 
				// if the search_only param is true, we only do search (duh)
				if (this.search_only) return 'search'
				return this.$store.state.lst.resource_selector_new_or_search 
			},
			set(val) { this.$store.commit('lst_set', ['resource_selector_new_or_search', val]) }
		},

		search_box_placeholder() {
			if (this.search_all) {
				if (this.standard_search && !this.chosen_cfitem) return 'Enter search terms for standards here, or click BROWSE at right'
				if (this.keyword_search && this.server_search_results.count == 0) return 'Enter keywords/search terms, then click SEARCH at right'
				return 'Enter additional search terms to filter results'
			} else {
				return 'Enter keywords/search terms to filter results'
			}
		},
		item_type_showing: {
			// '1lesson', '2sparkl', or '3resource'
			get() { 
				// if the value in lst isn't one of the currently-selected options, chose another one
				return this.$store.state.lst.search_item_type_showing 
			},
			set(val) { this.$store.commit('lst_set', ['search_item_type_showing', val]) }
		},
		search_crit: {
			// 'keywords' or 'standards'
			get() { return this.$store.state.lst.search_crit },
			set(val) { this.$store.commit('lst_set', ['search_crit', val]) }
		},

		sanitized_search_terms() {
			let st = $.trim(this.search_terms)
			if (empty(st)) return ''
			return U.sanitize_html(this.search_terms)
		},

		// we're doing a keyword search any time we're *not* searching all items, or if search_crit is set to 'keywords'
		keyword_search() { return !this.search_all || this.search_crit == 'keywords' },
		// standard_search is the opposite
		standard_search() { return this.search_all && this.search_crit == 'standards' },

		subjects() {
			let arr = []
			for (let subject in this.$store.state.subjects) {
				// if we're searching by standards, only include subjects that have framework_identifiers specified
				if (this.standard_search && empty(this.$store.state.subjects[subject].framework_identifier)) {
					continue
				}
				arr.push({ value: subject, text: subject})
			}

			// if we're searching by keyword and searching all items, include an 'all' option
			if (this.search_all && this.search_crit == 'keywords') {
				arr.push({value: 'all', text: 'ALL SUBJECTS'})
			}

			return arr
		},
		chosen_framework_identifier() { 
			// this allows us to specify a framework for searching "externally"
			if (!empty(this.chosen_framework_override)) return this.chosen_framework_override

			if (!this.subject) return ''
			// if 'all' is selected, we'll return ''
			return this.$store.state.subjects[this.subject]?.framework_identifier ?? ''
		},

		search_all() { return !this.selected_home_lesson && !this.selected_home_collection && !this.selected_my && !this.selected_collection },
		search_all_help() {
			return ''
			if (empty(this.last_search_terms)) {
				if (!empty(this.search_terms)) return `Tap the enter key or click the <i class="fas fa-search"></i> search icon to load search results`
				else return `Enter search terms, and/or choose a different option from SEARCH IN…, to show search results`
			}

			// if we get to here, we have done a search
			// if no searchable items, that will be expressed in the line below search_all_help
		},
		no_results_help() {
			// show a message IF we have no searchable_items AND other conditions are met...
			if (this.searchable_items.length > 0) return ''

			if (this.standard_search && !empty(this.chosen_cfitem)) {
				return 'No items are aligned to the chosen standard.'
			}

			return ''
		},
		course_selection_array() {
			let arr = []
			for (let lp of this.all_courses) {
				// skip inactive courses if user isn't an admin for the course
				if (lp.active != 'yes' && !(lp.user_can_view_lp && lp.user_can_view_lp())) continue

				// skip home_collection
				if (this.home_collection && this.home_collection.course_code == lp.course_code) continue

				arr.push({value:lp, text: lp.title})
			}
			let priority_collections = []
			let added_courses = new Set()
			// 1st priority is the last viewed collections
			priority_collections.push(...arr.filter( ({value}) => {
				if (value.course_code == 'default') return false
				const exists = this.lst.last_collections_viewed.includes(`${value.course_code}`)
				if (exists && !added_courses.has(value.course_code)) {
					added_courses.add(value.course_code)
					return exists
				}
			}))
			// 2nd priority is the user created
			priority_collections.push(...arr.filter( ({value}) => {
				if (value.course_code == 'default') return false
				const exists = this.user_info.admin_rights.includes(`lp.course.${value.course_code}`)
				if (exists && !added_courses.has(value.course_code)) {
					added_courses.add(value.course_code)
					return exists
				}
			}))
			// 3rd priority is the added my courses
			priority_collections.push(...arr.filter( ({value}) => {
				if (value.course_code == 'default') return false
				const exists = this.added_my_courses.includes(`${value.course_code}`)
				if (exists && !added_courses.has(value.course_code)) {
					added_courses.add(value.course_code)
					return exists
				}
			}))
			const non_priority = arr.filter((e) => !priority_collections.includes(e))
			// sort alphabetically
			non_priority.sort((a,b)=>U.natural_sort(a.value.title, b.value.title))
			return [...priority_collections, ...non_priority]
		},

		unit_selection_array() {
			// we show a unit select menu if we're showing a collection
			if (!this.selected_collection && !this.selected_home_collection) {
				return null
			}

			let c = this.selected_collection || this.home_collection
			if (!c) return null	// shouldn't happen

			let arr = []
			// start with an option for all units (this is the default); then add an item for each unit
			arr.push({value: null, text: 'ALL UNITS'})
			for (let unit of c.units) {
				if (empty(unit.title)) continue

				let text = unit.title
				if (!empty(unit.display_number)) text = `${unit.display_number} ${text}`
				arr.push({value: unit.lp_unit_id, text: text})
			}
			return arr
		},

		at_least_one_item_selected() {
			for (let item of this.searchable_items) {
				if (item.selected && !item.already_added) return true
			}
			return false
		},
		user_is_collection_editor() {
			if (!this.selected_collection) return false
			if (this.selected_collection.user_is_lp_admin()) return true
			return false
		},
		new_items_are_agency_sanctioned() {
			// if we're adding items to a non-agency-sanctioned collection, the items will never be agency_sanctioned
			if (!this.home_collection || !this.home_collection.agency_sanctioned) return false

			// if we're adding items to a shadow unit, the items will never be agency_sanctioned
			if (!this.home_unit || this.home_unit.shadows_lp_unit_id) return false

			// if we're adding items to a lesson, match the lesson's agency_sanctioned flag
			if (this.home_lesson) return this.home_lesson.agency_sanctioned

			// if we get to here, items *should* be agency_sanctioned
			return true
		},

		// TEMP: whether or not to show the alba easter-egg button
		show_alba_btn() {
			// hiding as of 1/1/2025, because there are other ways to launch the LPC now
			return false
			// // always show to pepper
			// if (this.user_info.email.indexOf('pepper') == 0) return true
			// if (this.home_collection && this.site_config.alba_collections && this.site_config.alba_collections.length > 0 && this.site_config.alba_collections.includes(this.home_collection.course_code)) return true
			// return false
		},
		// TEMP: whether or not to show the gavs easter-egg button
		show_gavs_btn() {
			// show to commongoodlt emails, or the admin@henry.k12.ga.us email
			if (this.user_info.email.indexOf('commongoodlt') > -1) return true
			if (this.user_info.email == 'admin@henry.k12.ga.us') return true
			return false
		},

		collection_showing() {
			// this returns the collection whose content is currently being shown
			if (this.selected_collection) return this.selected_collection
			else if (this.selected_home_collection) return this.home_collection
			// if selected_my, use the users default collection
			else if (this.selected_my) return this.my_default_collection

			// if we get to here, we're not showing a collection
			return null
		},
		selected_my_or_sandbox() {
			// this could be true if either selected_my is true, or if the user is searching from the sandbox tile
			return this.collection_showing && (this.collection_showing == this.my_default_collection)
		},

		rci_option_overrides() { return (sr) => {
			let o = {
				edit: false,
				remove: false,
				unpublish: false,
				copy_item_for_my_use: false,
				show_original: false,
				duplicate: false,
			}

			// let superusers edit and unpublish from search
			if (vapp.has_admin_right('su')) {
				o.edit = true
				o.unpublish = true
			}

			// also, for sparkl activities, it's confusing if teachers can't edit their own activities from search
			if (sr.value?.type == 'sparkl') {
				// delete the o.edit property, so that ResourceCollectionItem will determine if they're allowed to edit based on the factors there
				// Note that if the user changes the title of the activity (for example), that title change won't currently be reflected in the resource
				delete o.edit
			}
			return o
		}},

		subject: {
			get() { 
				// we only want to return a subject when we're searching all
				if (!this.search_all) return ''
				// but we store subject in state.lst so we restore to the last subject the user chose
				return this.$store.state.lst.search_subject 
			},
			set(val) { 
				this.$store.commit('lst_set', ['search_subject', val]) 
			}
		},

		visible_search_results() {
			// this is the array of search results we're currently showing, based on item_type_showing and items_showing_count[item_type_showing]
			let arr = []
			for (let sr of this.searchable_items) {
				if (sr.category == this.item_type_showing) arr.push(sr)
				if (arr.length >= this.items_showing_count[sr.category]) break
			}
			return arr
		},

		chosen_framework_record() { 
			// this is used when searching by standards
			if (empty(this.chosen_framework_identifier)) return {}

			let fr = this.$store.state.framework_records.find(x=>x.lsdoc_identifier == this.chosen_framework_identifier)
			if (empty(fr)) console.warn('couldn’t get framework_record for ' + this.chosen_framework_identifier)
			return fr || {}
		}
	},
	watch: {
		subject() {
			// when subject is selected/changed...

			// always reset the search (the old search terms will still be showing)
			this.reset_search_all()

			// TODO? if user chooses 'all', warn them that this is inefficienct

			// load the current subject's case doc if necessary
			this.load_framework_for_search()
		},

		// These watchers take care of what we need to do when user chooses a different filter value
		search_all() {
			this.limit_menu_showing = false
			if (this.search_all == true) {
				this.$store.commit('lst_set', ['selected_resource_filter', 'none'])

				// clear all match scores
				this.clear_all_match_scores()

				// load the current subject's case doc if necessary
				if (this.search_crit == 'standards') {
					this.load_framework_for_search()
				}
			}
		},
		selected_home_lesson() {
			this.limit_menu_showing = false
			if (this.selected_home_lesson == true) {
				this.selected_home_collection = false
				this.selected_collection = ''
				this.selected_my = false
				// store in selected_resource_filter so we'll go back to this filter when we come back to this dialog again
				this.$store.commit('lst_set', ['selected_resource_filter', 'home_lesson'])

				// clear all match scores
				this.clear_all_match_scores()
			}

			this.generate_searchable_items()
		},
		selected_home_collection() {
			this.limit_menu_showing = false
			if (this.selected_home_collection == true) {
				this.selected_home_lesson = false
				this.selected_my = false
				this.selected_collection = ''
				// store in selected_resource_filter so we'll go back to this filter when we come back to this dialog again
				this.$store.commit('lst_set', ['selected_resource_filter', 'home_collection'])

				// reset selected_unit when collection changes
				this.selected_unit = null

				// call this when collection changes
				this.adjust_item_type_showing_for_collection()

				// clear all match scores
				this.clear_all_match_scores()
			}

			// if we have to load standards-aligned assets for this collection, do so and wait for that to complete; that fn will call generate_searchable_items when it's done
			if (this.load_standards_aligned_assets_for_collection(this.home_collection)) {
				return
			}

			this.generate_searchable_items()
		},
		selected_my() {
			this.limit_menu_showing = false
			if (this.selected_my == true) {
				this.selected_home_lesson = false
				this.selected_home_collection = false
				this.selected_collection = ''
				// store in selected_resource_filter so we'll go back to this filter when we come back to this dialog again
				this.$store.commit('lst_set', ['selected_resource_filter', 'my'])

				// call this when collection changes
				this.adjust_item_type_showing_for_collection()

				// clear all match scores
				this.clear_all_match_scores()
			}

			this.generate_searchable_items()
		},
		selected_collection() {
			this.limit_menu_showing = false
			if (this.selected_collection) {
				this.selected_home_lesson = false
				this.selected_home_collection = false
				this.selected_my = false
				this.$store.commit('lst_set', ['selected_resource_filter', this.selected_collection.course_code])

				// reset selected_unit when collection changes
				this.selected_unit = null

				// call this when collection changes
				this.adjust_item_type_showing_for_collection()

				// clear all match scores
				this.clear_all_match_scores()

				// load course if necessary
				if (empty(this.selected_collection.lp_id) || !this.selected_collection.fully_loaded) {
					U.loading_start()
					this.$store.dispatch('get_learning_progression', this.selected_collection.course_code).then((found)=>{
						U.loading_stop()
						this.selected_collection = this.all_courses.find(x=>x.course_code == this.selected_collection.course_code)

						// if we have to load standards-aligned assets for this collection, do so and wait for that to complete; that fn will call generate_searchable_items when it's done
						if (this.load_standards_aligned_assets_for_collection(this.selected_collection)) {
							return
						}
					})
				} else {
					// if we have to load standards-aligned assets for this collection, do so and wait for that to complete; that fn will call generate_searchable_items when it's done
					if (this.load_standards_aligned_assets_for_collection(this.selected_collection)) {
						return
					}
				}
			}

			this.generate_searchable_items()
		},
		resource_search_sort_by() { this.apply_resource_search_sort() },
		resource_search_sort_by_created_at_order() { this.apply_resource_search_sort() },

		chosen_cfitem() {
			if (empty(this.chosen_cfitem)) {
				// this.reset_search_all()
			} else {
				this.get_items_from_server_standard()
			}
		},

		search_crit() {
			// reset when search_crit is changed (we might be able to be more elegant about this...)
			this.reset_search_all()

			// load the current subject's case doc if necessary
			if (this.search_crit == 'standards') {
				this.load_framework_for_search()
				// also in this case, clear the search terms; otherwise this looks wonky
				this.execute_search_clear()
			}

			// also reset chosen_framework_override when this is changed; this shows previously-hidden interface controls if the user started by searching for a non-subject-aligned framework
			this.chosen_framework_override = ''
		},

		selected_unit() {
			// when the selected unit changes, call generate_searchable_items to filter based on the unit
			this.generate_searchable_items()
		},

		search_terms() {
			// establish the debounce fn if necessary
			if (empty(this.search_terms_debounced)) {
				this.search_terms_debounced = U.debounce(function() {
					// if we're searching all and haven't gotten the first-stage results yet, return
					if (this.search_all && empty(this.current_server_search_terms)) return
					
					// otherwise call the service to get the vector for the given search terms if appropriate
					if (!empty(this.search_terms)) {
						if (this.sanitized_search_terms.length >= 4) {
							let search_term_vector = this.asset_search_term_vectors[this.sanitized_search_terms]
							// if we don't have the vector, call the asset_search_by_keywords service to get it
							if (!search_term_vector) {
								const payload = {
									user_id: this.user_info.user_id, 
									search_terms: this.sanitized_search_terms, 
									return_search_term_vector_only: 'yes',
								}

								// U.loading_start()
								U.ajax('asset_search_by_keywords', payload, result=>{
									// U.loading_stop()
									// console.warn('returned search_terms vector', result)
									this.$store.commit('set', [this.asset_search_term_vectors, payload.search_terms, result.vector])
									this.calculate_search_term_vector_matches()
								})

							// else call the fn to calculate matches
							} else {
								this.calculate_search_term_vector_matches()
							}
						}
					}
				}, 500)
			}
			// call the debounce fn
			this.search_terms_debounced()

			if (this.sanitized_search_terms.length < 4) {
				// if we get to here, we're searching a collection and we don't have search_terms (or it's too short to actually search), so clear match_scores
				this.match_scores = {}
				this.apply_resource_search_sort()
			}
		},
	},
	created() {
		vapp.search_component = this

		if (!this.all_courses_loaded) this.$store.dispatch('get_all_courses').then(x=>this.restore_previously_selected_filter())
		else this.restore_previously_selected_filter()
	},
	mounted() {
		this.previous_resource_search_sort_by = this.resource_search_sort_by

		// if default_source was specified, deal with it
		if (this.default_source == 'home_collection') this.selected_home_collection = true
		else if (this.default_source == 'my') this.selected_my = true
		else if (this.default_source == 'site') this.select_none()

		// if default_framework_identifier was specified, deal with it
		if (!empty(this.default_framework_identifier)) {
			this.search_crit = 'standards'

			/////////////////////////////////
			// fn to run once the lsdoc is loaded
			const default_framework_cb_fn = ()=>{
				// if the default_framework_identifier is one of our subject frameworks, set subject
				for (let subject in this.$store.state.subjects) {
					if (this.$store.state.subjects[subject].framework_identifier == this.default_framework_identifier) {
						this.subject = subject
					}
				}

				if (empty(this.subject)) {
					// if the default framework isn't a subject framework, set chosen_framework_override;
					// this will hide the "choose a subject" menu and "change standard" btn, but the user can just keep browsing in Satchel if they want to choose a different standard
					this.chosen_framework_override = this.default_framework_identifier
				}

				// note that setting the subject will trigger a watcher that will call reset_search_all, so set the starting cfitem on nextTick...

				if (this.starting_item_identifier) this.$nextTick(x=>{
					const cfitem = this.chosen_framework_record.json.CFItems.find(x=>x.identifier == this.starting_item_identifier)
					if (empty(cfitem)) { // shouldn't happen
						this.$alert('Error locating searched-for CASE item.')
						return
					}

					this.chosen_cfitem = {
						framework_identifier: this.default_framework_identifier,
						cfitem: cfitem
					}
				})
			}
			/////////////////////////////////
			
			// load the default_framework_identifier case doc if necessary
			const fr = this.$store.state.framework_records.find(x=>x.lsdoc_identifier == this.default_framework_identifier)
			if (empty(fr) || !fr.framework_json_loaded) {
				U.loading_start()
				this.$store.dispatch('get_lsdoc', this.default_framework_identifier).then(x=> {
					U.loading_stop()
					default_framework_cb_fn()
				})
			} else {
				default_framework_cb_fn()
			}

		// else if we're going to be searching standards, load the current subject's case doc if necessary
		} else if (this.search_crit == 'standards') {
			this.load_framework_for_search()
		}

		// get lesson_masters if we haven't already done so
		this.$store.dispatch('get_lesson_masters')

		this.reset_items_showing_count()
		this.reset_server_search_results()
	},
	methods: {
		load_standards_aligned_assets_for_collection(collection) {
			// if any units in the selected course don't have standards_aligned_assets_loaded, load assets for all units
			for (let u of collection.units) {
				if (!u.standards_aligned_assets_loaded) {
					collection.get_standards_aligned_assets().then(x=>{
						// when this completes, call generate_searchable_items
						this.generate_searchable_items()
					})
					// return true to tell the caller that standards-aligned assets need to be loaded
					return true
				}
			}
			return false
		},

		load_selected_item_vectors() {
			// we do this unless we're searching all -- in which case we'll do the vector search on the server
			if (this.search_all) return

			// create collection of item_ids that we need vectors for
			let asset_ids_to_get = []
			for (let item of this.searchable_items) {
				if (empty(this.asset_search_vectors[item.item_id])) {
					asset_ids_to_get.push(item.item_id)
				}
			}

			// if we already have all the vectors, return
			if (asset_ids_to_get.length == 0) return

			// console.warn('load_selected_item_vectors:', asset_ids_to_get)

			// call the asset_search_by_keywords service to get the vectors
			const payload = {
				user_id: this.user_info.user_id, 
				asset_ids_to_get: asset_ids_to_get,
				return_asset_vectors_only: 'yes',
			}

			// U.loading_start()
			U.ajax('asset_search_by_keywords', payload, result=>{
				// U.loading_stop()
				// console.warn('returned', result)
				for (let v of result.vectors) {
					this.$store.commit('set', [this.asset_search_vectors, v.asset_id, JSON.parse(v.vector)])
				}
			})
		},

		set_resource_search_sort_order() {
			if (this.previous_resource_search_sort_by == 'created_at') {
				if (this.resource_search_sort_by_created_at_order == 'desc') this.$store.commit('lst_set', ['resource_search_sort_by_created_at_order', 'asc'])
				else this.$store.commit('lst_set', ['resource_search_sort_by_created_at_order', 'desc'])
			}
			this.previous_resource_search_sort_by = 'created_at'
		},

		// this calculates, when appropriate, vector matches between assets and search terms
		calculate_search_term_vector_matches() {
			this.match_scores = {}

			// if we don't have search terms, or we don't have a vector for the current search terms, we can't do this
			if (empty(this.sanitized_search_terms)) return
			let st_vector = this.asset_search_term_vectors[this.sanitized_search_terms]
			if (empty(st_vector)) return

			// do it...
			let o = {}
			for (let item of this.searchable_items) {
				let item_vector = this.asset_search_vectors[item.item_id]
				if (item_vector) {
					let cs = U.cosine_similarity(st_vector, item_vector)
					o[item.item_id] = (cs > 0) ? Math.round(cs * 1000) : 0
				}
			}
			this.match_scores = o

			// whenever we do this, sort
			this.apply_resource_search_sort()
		},

		clear_all_match_scores() {
			this.match_scores = {}
			this.server_search_match_scores = {}
		},

		apply_resource_search_sort() {
			// if we did a server-side search, the arr will already be in the order we want
			// if (this.search_all) return arr

			// Group the input array by category(Lesson, Activity, Resource), then alphabetically or by created_at date within the category
			this.searchable_items.sort((a, b) => {
				// const category_comparison = U.natural_sort(a.category, b.category)
				// if (category_comparison !== 0) return category_comparison

				// filtered_items go on top
				if (this.filtered_items.includes(a)) {
					if (!this.filtered_items.includes(b)) return -1
				} else if (this.filtered_items.includes(b)) return 1

				// if we have match_scores, use them. This should be the case if either a) we're searching anything other than the whole site, or b) search_all and we're doing a "second-stage" filtering search
				if (!empty(this.match_scores[b.item_id]) && !empty(this.match_scores[a.item_id])) return this.match_scores[b.item_id] - this.match_scores[a.item_id]

				// else if we have server_search_match_scores, use them. This should be the case if we've done a search_all
				if (!empty(this.server_search_match_scores[b.item_id]) && !empty(this.server_search_match_scores[a.item_id])) return this.server_search_match_scores[b.item_id] - this.server_search_match_scores[a.item_id]

				// if sorting by created_at date...
				if (this.selected_my_or_sandbox && this.resource_search_sort_by == 'created_at') {
					if (this.resource_search_sort_by_created_at_order == 'asc') {
						return U.natural_sort(b.created_at + '', a.created_at + '')
					} else {
						return U.natural_sort(a.created_at + '', b.created_at + '')
					}
				}

				// default to alpha sort
				return U.natural_sort(a.text, b.text)
			})
		},
		// restore the filter the user previously selected the last time they were adding content
		restore_previously_selected_filter() {
			if (this.$store.state.lst.selected_resource_filter == 'none') {
				this.selected_home_lesson = false
				this.selected_home_collection = false
				this.selected_my = false
				this.selected_collection = ''

			} else if (this.$store.state.lst.selected_resource_filter == 'home_lesson') {
				this.selected_home_lesson = (this.home_lesson != null)	// true if we were given a home lesson; false otherwise
				this.selected_home_collection = false
				this.selected_my = false
				this.selected_collection = ''

			} else if (this.$store.state.lst.selected_resource_filter == 'home_collection' || this.$store.state.lst.selected_resource_filter === this.home_collection?.course_code) {
				this.selected_home_lesson = false
				this.selected_home_collection = (this.home_collection != null)	// true if we were given a home course; false otherwise
				this.selected_my = false
				this.selected_collection = ''

			} else if (this.$store.state.lst.selected_resource_filter == 'my') {
				this.selected_home_lesson = false
				this.selected_home_collection = false
				this.selected_my = true
				this.selected_collection = ''

			} else {
				this.selected_home_lesson = false
				this.selected_home_collection = false
				this.selected_my = false
				this.selected_collection = this.all_courses.find(x=>x.course_code == this.$store.state.lst.selected_resource_filter) ?? ''
			}

			// if we're not allowing the user to add other resources from the home collection/unit, make sure we don't start with these options selected (v-ifs above will make sure the options aren't allowed to select)
			if (!this.allow_add_from_home) {
				if (this.selected_my && this.home_collection == this.my_default_collection) {
					this.select_none()
				} else if (this.selected_home_lesson || this.selected_home_collection) {
					this.select_none()
				} else if (this.home_collection) {
					if (this.selected_collection && this.selected_collection.course_code == this.home_collection.course_code) this.select_none()
				}
			}
		},

		select_none() {
			// clear all the selected_xx values, so that we're searching everything in cureum
			this.selected_home_lesson = false
			this.selected_home_collection = false
			this.selected_my = false
			this.selected_collection = ''
		},

		matches_search(s) {
			if (U.strings_match_search_term_res(this.search_term_res, [s])) {
				return true
			}
			return false
		},

		// this is called when the user is searching all items and clicks "New Search"
		reset_search_all() {
			// console.warn('reset', this.current_server_search_terms)
			this.execute_search_clear()
			this.search_terms = this.current_server_search_terms
			this.current_server_search_terms = ''
			this.chosen_cfitem = null
			this.reset_server_search_results()
			this.generate_searchable_items()
		},

		reset_items_showing_count() {
			this.items_showing_count = {
				'1lesson': this.items_per_page,
				'2sparkl': this.items_per_page,
				'3resource': this.items_per_page
			}
		},

		reset_server_search_results() {
			this.server_search_results = { 
				resources:[], 
				activities:[], 
				lessons:[], 
				count: 0 
			}
		},

		search_field_keyup(evt) {
			// when enter key is tapped, call initiate_search_from_keyboard_or_search_icon
			this.reset_items_showing_count()
			if (evt.keyCode == 13) this.initiate_search_from_keyboard_or_search_icon()
			else this.execute_search()
		},

		initiate_search_from_keyboard_or_search_icon() {
			// if we're *not* filtering by an lp or my resources, this is where we poll the server for potential search results
			if (this.search_all) {
				// but if we've already done the "first-stage" search, don't do this again here
				if (!empty(this.current_server_search_terms)) {
					// instead call execute_search
					this.execute_search()
					return
				}
				this.get_items_from_server_keywords()
			}
			// else execute search? maybe not, since we would have executed based on the last thing you typed
		},

		browse_standards(cfitem) {
			// if the button to do this is clicked, the user must have chosen a subject
			let data = { 
				framework_identifier: this.chosen_framework_identifier, 
				item_identifier: '' 
			}

			// if we really received a cfitem (not an event), start with it
			if (cfitem.identifier) data.item_identifier = cfitem.identifier

			let show_data = { 
				// set embed_hide_callback_fn to ... when the user closes the chooser
				embed_hide_callback_fn: ()=>{ this.satchel_showing = false },
				// set hide_fn to hide the standards chooser if/when the search dialog is no longer visible
				hide_fn: ()=>{ return ($('.k-search-card').is(':visible') == false) },
			}

			this.satchel_showing = true
			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((chosen_item) => {
						// console.warn(chosen_item)
						this.choose_cfitem_for_search(chosen_item)
					})
				})
			}).catch(()=>this.satchel_showing = false)	// this will execute when the standards are hidden
		},

		choose_cfitem_for_search(cfitem) {
			// add framework_identifier if not part of cfitem
			if (empty(cfitem.framework_identifier)) {
				cfitem = {
					framework_identifier: this.chosen_framework_identifier,
					cfitem: cfitem
				}
			}

			// remember this.current_server_search_terms
			this.current_server_search_terms = this.search_terms
			
			// set chosen_cfitem
			this.chosen_cfitem = cfitem

			// make sure satchel isn't showing (?)
			this.satchel_showing = false
			vapp.$refs.satchel.execute('hide')
		},

		rendered_cfitem(cfitem) {
			// allow for cfitem to be a "raw" cfitem or an object containing a cfitem
			if (cfitem.cfitem) cfitem = cfitem.cfitem

			let s = ''
			if (cfitem.humanCodingScheme) s += `<b>${cfitem.humanCodingScheme}</b> `
			s += U.render_latex(cfitem.fullStatement)

			const item_type = U.item_type(cfitem)
			const el = U.grade_level_display(cfitem.educationLevel).replace(/\b0/g, '')
			if (item_type || el) {
				s += ' <i style="font-size:0.8em">('
				if (item_type) {
					s += `<nobr>${item_type}</nobr>`
				}
				if (el) {
					if (item_type) s += ', '
					s += `<nobr>Grade ${el}</nobr>`
				}
				s += ')</i>'
			}
			return s
		},

		// this loads the current subject's framework, if it isn't already fully loaded
		load_framework_for_search() {
			console.warn('load_framework_for_search')
			this.$nextTick(x=>{
				if (this.chosen_framework_identifier) {
					const fr = this.$store.state.framework_records.find(x=>x.lsdoc_identifier == this.chosen_framework_identifier)
					if (empty(fr) || !fr.framework_json_loaded) {
						// this should be quick, so we shouldn't need to show the loader
						// U.loading_start()
						this.$store.dispatch('get_lsdoc', this.chosen_framework_identifier).then(x=> {
							// U.loading_stop()
						})
					}
				}
			})
		},

		execute_search() {
			// establish the debounce fn if necessary
			if (empty(this.execute_search_debounced)) {
				this.execute_search_debounced = U.debounce(function() {
					// console.log('execute_search_debounced: ' + this.search_terms)
					this.search_term_res = U.create_search_re(this.search_terms)

					// if we're doing a standards search and haven't chosen a standard, search through standards for the selected subject
					if (this.standard_search && this.subject && !this.chosen_cfitem) {
						// if no re's (e.g. because search_terms is empty), just clear filtered_items
						if (this.search_term_res.length == 0) {
							this.standard_search_results = []
							return
						}

						if (empty(this.chosen_framework_record.json)) {
							console.error('execute_search for standards, but we don’t have a framework_record!')	// shouldn't happen
							return
						}

						let arr = []
						for (let cfitem of this.chosen_framework_record.json.CFItems) {
							if (cfitem.humanCodingScheme && this.matches_search(cfitem.humanCodingScheme)) arr.push(cfitem)
							else if (this.matches_search(cfitem.fullStatement)) arr.push(cfitem)
						}
						this.standard_search_results = arr
						return
					}

					let arr = []
					this.filtered_category_count = {'1lesson':0, '2sparkl':0, '3resource':0}
					for (let item of this.searchable_items) {
						// if search terms are in the title (or description?) itself, put in filtered items
						if (empty(this.search_terms) || this.matches_search(item.text_lc)) {
						// if (empty(this.search_terms) || this.matches_search(item.text_lc) || (this.matches_search(item.value?.long_description))) {
							arr.push(item)
							++this.filtered_category_count[item.category]
						}
					}
					this.filtered_items = arr

					this.apply_resource_search_sort()
				}, 15)
			}
			// call the debounce fn
			this.execute_search_debounced()
		},

		asset_css(item) {
			if (this.filtered_items.length == this.searchable_items.length) return ''
			const is_filtered = this.filtered_items.includes(item)
			return 'k-resource-search-in-filtered-items-' + is_filtered
		},

		execute_search_clear() {
			this.search_terms = ''
			this.last_search_terms = ''
			this.filtered_items = []
			this.search_term_res = []
			this.reset_items_showing_count()
			this.match_scores = {}
			this.execute_search()
		},

		get_items_from_server_keywords() {
			this.reset_server_search_results()

			if (this.search_terms.length < 4) {
				this.$alert('Please enter at least four characters in your search string.')
				return
			}

			const payload = {
				user_id: this.user_info.user_id, 
				asset_count_per_category: this.keyword_search_asset_count_per_category,
				search_terms: U.sanitize_html(this.search_terms), 
				// types: this.selected_resource_search_types, 
				// for now at least we'll always search all three types
				types: [0, 1, 2], 
			}

			// include a subject if one is chosen
			if (!empty(this.subject) && this.subject != 'all') {
				payload.subjects = [this.subject]
			}

			// include a collection if one is chosen
			if (this.selected_collection) payload.search_collection_id = this.selected_collection.lp_id
			else if (this.selected_home_collection) payload.search_collection_id = this.home_collection.lp_id

			// include searchable_items if needed (e.g. sandbox??)
			// if (this.selected_my && this.searchable_items.length > 0) {
			if (!this.search_all && this.searchable_items.length > 0) {
				payload.searchable_item_ids = this.searchable_items.map(item => item.item_id)
			}

			U.loading_start()
			U.ajax('asset_search_by_keywords', payload, result=>{
				U.loading_stop()

				// set current_server_search_terms to the terms we just searched for, and clear search terms
				this.current_server_search_terms = this.search_terms
				this.search_terms = ''

				// store the match scores we get from the server in server_search_match_scores; also store the vectors in asset_search_vectors
				let server_search_match_scores = {}
				this.server_search_results.count = 0
				if (result.top_lessons) {
					for (let asset_row of result.top_lessons) {
						server_search_match_scores[asset_row.asset_id] = (asset_row.match_val > 0) ? asset_row.match_val : 0
						this.$store.commit('set', [this.asset_search_vectors, asset_row.asset_id, JSON.parse(asset_row.vector)])
						asset_row.asset_data.lesson_id = asset_row.asset_id
						this.server_search_results.lessons.push(new Lesson(asset_row.asset_data))
						++this.server_search_results.count
					}
				}
				if (result.top_activities) {
					for (let asset_row of result.top_activities) {
						server_search_match_scores[asset_row.asset_id] = (asset_row.match_val > 0) ? asset_row.match_val : 0
						this.$store.commit('set', [this.asset_search_vectors, asset_row.asset_id, JSON.parse(asset_row.vector)])
						asset_row.asset_data.resource_id = asset_row.asset_id
						this.server_search_results.activities.push(new Resource(asset_row.asset_data))
						++this.server_search_results.count
					}
				}
				if (result.top_resources) {
					for (let asset_row of result.top_resources) {
						server_search_match_scores[asset_row.asset_id] = (asset_row.match_val > 0) ? asset_row.match_val : 0
						this.$store.commit('set', [this.asset_search_vectors, asset_row.asset_id, JSON.parse(asset_row.vector)])
						asset_row.asset_data.resource_id = asset_row.asset_id
						this.server_search_results.resources.push(new Resource(asset_row.asset_data))
						++this.server_search_results.count
					}
				}
				this.server_search_match_scores = server_search_match_scores

				this.generate_searchable_items()
			})
		},

		get_items_from_server_standard() {
			this.reset_server_search_results()

			const payload = {
				user_id: this.user_info.user_id, 
				search_strings: JSON.stringify([this.search_terms]), 
				// types: this.selected_resource_search_types, 
				// for now at least we'll always search all three types
				types: [0, 1, 2], 
				cfitem_identifiers: [this.chosen_cfitem.cfitem.identifier], 
			}
			// I don't think there will ever be any need to include searchable_item_ids here...

			U.loading_start()
			U.ajax('asset_search_by_standards', payload, result=>{
				U.loading_stop()

				// set current_server_search_terms to the terms we just searched for, and clear search terms
				this.current_server_search_terms = this.search_terms
				this.search_terms = ''

				this.server_search_results.count = 0
				if (result.top_lessons) {
					for (let asset_row of result.top_lessons) {
						asset_row.asset_data.lesson_id = asset_row.asset_id
						this.server_search_results.lessons.push(new Lesson(asset_row.asset_data))
						++this.server_search_results.count
					}
				}
				if (result.top_activities) {
					for (let asset_row of result.top_activities) {
						asset_row.asset_data.resource_id = asset_row.asset_id
						this.server_search_results.activities.push(new Resource(asset_row.asset_data))
						++this.server_search_results.count
					}
				}
				if (result.top_resources) {
					for (let asset_row of result.top_resources) {
						asset_row.asset_data.resource_id = asset_row.asset_id
						this.server_search_results.resources.push(new Resource(asset_row.asset_data))
						++this.server_search_results.count
					}
				}
				this.generate_searchable_items()
			})
		},

		generate_searchable_items() {
			this.reset_items_showing_count()

			// establish the debounce fn if necessary
			if (empty(this.generate_searchable_items_debounced)) {
				this.generate_searchable_items_debounced = U.debounce(function(x) {

					// this fn is for traversing a resource collection generated from a common cartridge (i.e. HMH resources in HenryConnects)
					function traverse(resource_arr, collection_id, node, collection_inclusions, parent_included) {
						// we assume here that each node is *either* a resource (where the resource_id is specified by 'r') or a "folder" (with children in 'c')
						if (!empty(node.r)) {
							// if this resource's parent is included, include the resource
							if (parent_included) resource_arr.push(new Resource({
								resource_id: node.r,
								type: 'collection_item',
								teacher_facing: (node.i == 1),	//
								description: node.t,
							}))

						} else if (!empty(node.c)) {
							// this is a folder; if it is either implicitly or explicitly included, its child resources should be included
							let node_included_explicitly = empty(collection_inclusions) || !empty(collection_inclusions.find(x=>x==node.f))	// node.f = identifier, from CC file
							let node_included_implicitly = !node_included_explicitly && parent_included

							for (let child of node.c) {
								// traverse child; parent_included is true if nn is either explicitly or implicitly included
								traverse(resource_arr, collection_id, child, collection_inclusions, (node_included_explicitly || node_included_implicitly))
							}
						}
					}

					// fn for adding an item to the the return array
					let add_item = (item, type, text, lp_unit_id = 0) => {
						let o = {}
						if (type == 'resource') {
							// only add each item once
							if (arr.find(x=>x.value.resource_id == item.resource_id)) return
							// also skip duplicate urls for resources
							if (arr.find(x=>x.value.url == item.url)) return
							// mark items that already exist
							if (this.existing_resources.find(x=>x.resource_id == item.resource_id)) o.already_added = true
							// add item_id
							o.item_id = item.resource_id

							// sparkl resources go in the sparkl category
							if (item.type == 'sparkl') o.category = '2sparkl'
							else o.category = '3resource'
						}
						if (type == 'lesson') {
							if (arr.find(x=>x.value.lesson_id == item.lesson_id)) return
							if (this.existing_lessons.find(x=>x.lesson_id == item.lesson_id)) o.already_added = true
							o.item_id = item.lesson_id
							o.category = '1lesson'
						}
						if (type == 'activity') {
							if (arr.find(x=>x.value.resource_id == item.resource_id)) return
							if (this.existing_activities.find(x=>x.resource_id == item.resource_id)) o.already_added = true
							o.item_id = item.resource_id
							o.category = '2sparkl'
						}
						o.value = item
						o.type = type
						o.text = text
						o.created_at = item.created_at
						o.lp_unit_id = lp_unit_id
						o.text_lc = o.text.toLowerCase()
						o.selected = o.already_added
						arr.push(o)

						// add to searchable_category_count as we go
						this.searchable_category_count[o.category] += 1
					}

					this.searchable_category_count = {'1lesson':0, '2sparkl':0, '3resource':0}
					let arr = []

					// assets for a collection, including the user's sandbox
					if (this.selected_collection || this.selected_home_collection || this.selected_my) {
						let lp = this.collection_showing
						
						for (let unit of lp.units) {
							// if we have a selected unit, only include items for that unit
							if (!empty(this.selected_unit)) {
								if (unit.lp_unit_id != this.selected_unit) continue
							}
							// ? add the unit title for collections (not selected_my, which is the sandbox)
							let unit_title = ''
							// if (!this.selected_my && !empty(unit.title)) {
							// 	unit_title = `<span class="k-full-resource-search-result-unit">${unit.title}</span> `
							// }

							// resources
							for (let r of unit.resources) {
								if (r.placeholder == true) continue		// don't add the placeholder item
								add_item(r, 'resource', `${r.description}${unit_title}`, unit.lp_unit_id)
							}

							// lessons, and resources in lessons
							for (let l of unit.lessons) {
								add_item(l, 'lesson', `${l.lesson_title}${unit_title}`, unit.lp_unit_id)

								// TODO: do we need to load the full lessons in order to get the list of resources??
								for (let r of l.resources) {
									// ${unit_title}
									add_item(r, 'resource', `${r.description}<span class="k-full-resource-search-result-from-lesson">(from Lesson)</span>`, unit.lp_unit_id)
								}
								// TODO: lessons an now include other lessons
							}

							// add standards_aligned_assets if we have them for the unit
							for (let a of unit.standards_aligned_assets) {
								// note that the items here aren't lessons/resources directly
								if (!arr.find(x=>x.item_id == a.asset_id)) {
									if (a.asset_type == 'lesson') {
										if (!a.lesson) {
											// console.warn(`standards-aligned lesson ${a.asset_id} not loaded`)
											continue
										}
										add_item(a.lesson, 'lesson', `${a.lesson.lesson_title}${unit_title}`, unit.lp_unit_id)
									} else {
										if (!a.resource) {
											// console.warn(`standards-aligned resource ${a.asset_id} not loaded`)
											continue
										}
										add_item(a.resource, 'resource', `${a.resource.description}${unit_title}`, unit.lp_unit_id)
									}
								}
							}
						}
						
						// LEGACY: add course_wide_resources (I don't think we have these any more)
						for (let r of lp.course_wide_resources) {
							add_item(r, 'resource', `Course-Wide: ${r.description}`)
						}

						// ?? add common cartridge items if there
						for (let rc of lp.resource_collections) {
							let col_arr = []
							traverse(col_arr, rc.resource_id, rc.collection_json, null, true)
							for (let r of col_arr) {
								add_item(r, 'resource', `TEXTBOOK: ${r.description}`)
							}
						}

						// TODO?: if this is an agency-sanctioned repo, add items from the user's "shadow units"

					} else if (this.selected_home_lesson) {
						for (let r of this.home_lesson.resources) {
							add_item(r, 'resource', r.description)
						}
						for (let r of this.home_lesson.lessons) {
							add_item(r, 'lesson', r.lesson_title)
						}

					} else {
						// results of server-side search
						for (let l of this.server_search_results.lessons) {
							add_item(l, 'lesson', l.lesson_title)

							// resources from the lesson
							for (let r of l.resources) {
								add_item(r, 'resource', `${r.description}<span class="k-full-resource-search-result-from-lesson">(from Lesson)</span>`)
							}
						}

						for (let a of this.server_search_results.activities) {
							add_item(a, 'activity', a.description)
						}

						for (let r of this.server_search_results.resources) {
							add_item(r, 'resource', r.description)	//  ($2)
						}
					}

					this.searchable_items = arr
					this.execute_search()

					// now that we know we have searchable_items, load vectors for the selected items if necessary
					this.load_selected_item_vectors()
				}, 10)
			}
			// console.log(this.searchable_items)
			// call the debounce fn
			this.generate_searchable_items_debounced()
		},
		show_more_results(type) {
			this.items_showing_count[type] += this.items_per_page
			// this.get_items_from_server_keywords()
			// this.generate_searchable_items(this.items_showing_count)
		},

		adjust_item_type_showing_for_collection() {
			this.$nextTick(x=>{
				// if we just loaded a collection's items, item_type_showing might be set to an item type that this collection doesn't have any of, which causes a problem.
				if (empty(this.searchable_category_count[this.item_type_showing]) || this.searchable_category_count[this.item_type_showing] == 0) {
					for (let item_type in this.searchable_category_count) {
						if (this.searchable_category_count[item_type] > 0) {
							console.warn('found new item_type: ' + item_type)
							this.item_type_showing = item_type
							return
						}
					}
				}
				// if we get to here, the collection may not have any items at all; don't change item_type_showing
			})
		},

		result_class(sr, hover) {
			let s = ''
			if (sr.already_added) s += ' k-full-resource-search-result-already-added'
			if (sr == this.last_previewed_item) s += ' indigo lighten-5'
			return s
		},

		created_date(sr) {
			let d 
			if (sr.category === "1lesson") {
				d = new Date(sr.value.created_at*1000)
			} else {
				d = date.parse(sr.value.created_at, 'YYYY-MM-DD HH:mm:ss')
			}
			return date.format(d, 'MMM D, YYYY')	// Jan 3, 2020 3:04 PM
		},

		item_checked(sr) {
			// if max_items is 1, only allow one item to be checked; so uncheck any item except sr
			if (this.max_items == 1) {
				for (let i = 0; i < this.searchable_items.length; ++i) {
					if (this.searchable_items[i] != sr) {
						this.searchable_items[i].selected = false
					}
				}
			}
		},

		add_selected_items() {
			if (!this.at_least_one_item_selected) return

			// add items that are selected and aren't already in the collection we're adding to
			let arr = this.searchable_items.filter(x=>x.selected && !x.already_added)

			// extract list of lessons and sparkls we're adding
			let arr2 = arr.filter(x=>x.category == '1lesson' || x.category == '2sparkl')

			// if we're not adding resources *to* a lesson,
			// AND we're adding lessons or activities from selected_my (items from the user's default collection)
			// OR if we are moving from a collection that the user is an editor of,
			// ask the user if they want to COPY or MOVE
			// SF: Simplified this logic to remove the this.selected_home_collection, as this is trumped by this.user_is_collection_editor and it was causing an issue when adding directly to "My Content" from the active collection
			const user_can_move_resource = this.user_is_collection_editor || this.selected_my

			if (!this.adding_to_lesson && (user_can_move_resource)) {
				let noun = (arr.length == 1) ? 'item' : `items`
				let noun2 = (arr.length == 1) ? 'an ' + noun : noun
				let noun3 = (arr.length == 1) ? 'it' : 'them'
				let origin_collection = this.selected_my ? 'your Default Collection' : `“${this.selected_collection.title}”`
				let origin_collection_abbrev = this.selected_my ? 'your Default Collection' : 'the original collection'
				let destination_noun = 'collection'
				if (this.selected_home_collection) {
					origin_collection = `another unit in “${this.home_collection.title}”`
					origin_collection_abbrev = 'the other unit'
					destination_noun = 'unit'
				}
				this.$confirm({
					title: 'Copy or Move?',
					text: `You’ve selected ${noun2} from ${origin_collection}. Would you like to:<ul><li class="mt-1"><b>COPY</b> the selected ${noun} from ${origin_collection_abbrev} to the current ${destination_noun}, or</li><li class="mt-1"><b>MOVE</b> the selected ${noun} from ${origin_collection_abbrev} to the current ${destination_noun} (removing ${noun3} from ${origin_collection_abbrev})?</li></ul>`,
					extraBtnText: '  Copy',
					extraBtnIconAfter: 'fas fa-copy',
					acceptText: '  Move',
					acceptIconAfter: 'fas fa-truck-moving',
					cancelIcon: 'fas fa-times',
					dialogMaxWidth: 640,
				}).then(result => {
					// if they click the 'extra' btn, we will get result === 'extra', which means COPY
					if (result === 'extra') {
						// if we have any lessons/sparkls, we have to copy them before adding
						if (arr2.length > 0) {
							this.copy_items_before_adding(arr, arr2)
						
						// else we want to leave all the non-sparkl resources in my and add them to the new collection, so proceed
						} else {
							this.add_selected_items_finish(arr)
						}

					// else user clicked the accept button, meaning we're MOVING
					} else {
						// here we have to remove the items from my before adding them to the new collection
						this.move_items_before_adding(arr)
					}
				}).catch(n=>{console.log(n)}).finally(f=>{})
			
			// else we're adding from somewhere else, so we never move
			} else {
				// if we're not adding to a lesson and we have any lessons/sparkls, we have to copy them before adding
				if (!this.adding_to_lesson && arr2.length > 0) {
					this.copy_items_before_adding(arr, arr2)
				
				// else we want to leave all the non-sparkl resources where they are and add them to the new collection, so proceed
				} else {
					this.add_selected_items_finish(arr)
				}
			}
		},

		move_items_before_adding(arr) {
			// We allow moving from your default collection AND from any collection user is the owner of, so we just have to first remove the items in arr whichever of those is the origin here
			let payload = {
				user_id: this.user_info.user_id,
				resource_ids: [],
				lesson_ids: [],
			}
			if (this.selected_home_collection) this.selected_collection = this.home_collection

			// move_items_before_adding will set agency_sanctioned for the to-be-moved assets if we tell it to here
			if (this.home_collection) payload.agency_sanctioned = this.new_items_are_agency_sanctioned ? 'yes' : 'no'

			for (let sr of arr) {
				let lp_id = ''
				let user_id = 0
				// If we are moving from a collection other than the default collection, we need to pass the collection ID in to the service
				if (!this.selected_my) lp_id = this.selected_collection.lp_id
				else {
					// even if the item is in my sandbox, it might also be tied to a different LP; if so we want to move it from there
					let cam = this.$store.state.my_ca_mappings.find(x=>x.asset_id==sr.item_id)
					if (cam) {
						lp_id = cam.collection_id
						user_id = cam.user_id		// in this case the user_id might be 0 or the user's id
					}
				}

				// for each item, add [item_id, lp_id, user_id] (where lp_id might be empty, and user_id might be 0); the service will use these to decide what to remove
				if (sr.type == 'lesson') payload.lesson_ids.push([sr.item_id, lp_id, user_id])
				else payload.resource_ids.push([sr.item_id, lp_id, user_id])
			}

			U.loading_start()
			U.ajax('remove_assets_from_collection', payload, result => {
				U.loading_stop()
				for (let sr of arr) {
					// If we are moving from the default collection...
					if (this.selected_my) {
						// remove the items from the user's default collection -- lessons are in unit 0, activities in unit 1, and other resources in unit 2
						if (sr.type == 'lesson') {
							let i = this.my_default_collection.units[0].lessons.findIndex(x => x.lesson_id == sr.item_id)
							if (i > -1) this.$store.commit('set', [this.my_default_collection.units[0].lessons, 'SPLICE', i])
						} else if (sr.type == 'activity') {
							let i = this.my_default_collection.units[1].resources.findIndex(x => x.resource_id == sr.item_id)
							if (i > -1) this.$store.commit('set', [this.my_default_collection.units[1].resources, 'SPLICE', i])
						} else {
							let i = this.my_default_collection.units[2].resources.findIndex(x => x.resource_id == sr.item_id)
							if (i > -1) this.$store.commit('set', [this.my_default_collection.units[2].resources, 'SPLICE', i])
						}
					} else {
						// Otherwise, we are moving from a collection 'owned' by the current user
						// Here we need to figure out which unit the item was coming from and remove it...
						const unit_index = this.selected_collection.units.findIndex(x => x.lp_unit_id == sr.lp_unit_id)
						if (unit_index > -1) {
							if (sr.type == 'lesson') {
								let i = this.selected_collection.units[unit_index].lessons.findIndex(x => x.lesson_id == sr.item_id)
								if (i > -1) this.$store.commit('set', [this.selected_collection.units[unit_index].lessons, 'SPLICE', i])
							} else {
								let i = this.selected_collection.units[unit_index].resources.findIndex(x => x.resource_id == sr.item_id)
								if (i > -1) this.$store.commit('set', [this.selected_collection.units[unit_index].resources, 'SPLICE', i])
							}
						}
					}
				}
				// If we didn't move from default collection...
				if (!this.selected_my) {
					// ...update the origin LP in database This will remove the moved lessons and resources from the lp json 
					this.$store.dispatch('save_learning_progression', {no_lock: true, lp: this.selected_collection}).then(() => {
						this.add_selected_items_finish(arr)
					})

				// Otherwise, we did move from the default collection...
				} else {
					this.add_selected_items_finish(arr)
				}
			})
		},

		copy_items_before_adding(arr, arr2) {
			// make copies of assets in arr2, then add the copies
			let payload = {
				user_id: this.user_info.user_id, 
				resource_ids: [], 
				lesson_ids: [],
				agency_sanctioned: 'no',
				// when we copy an activity this way -- when adding to a collection -- we *always* copy the activity's exercises, even if it's an agency-sanctioned activity
				copy_exercises: 'yes',
			}

			// if we don't have a home_collection for some reason, assume the copy shouldn't be agency_sanctioned
			if (this.home_collection) payload.agency_sanctioned = this.new_items_are_agency_sanctioned ? 'yes' : 'no'

			for (let sr of arr2) {
				if (sr.type == 'lesson') payload.lesson_ids.push(sr.item_id)
				else payload.resource_ids.push(sr.item_id)
			}
			U.loading_start()
			U.ajax('copy_assets_for_adding_to_collection', payload, result=>{
				U.loading_stop()

				if (result.status != 'ok') {
					this.$alert(result.status)
					return
				}

				// replace the asset id's in arr with the newly-created assets
				for (let o of result.copies) {
					// note that arr contains references to the objects in sr 
					let sr = this.searchable_items.find(x=>x.item_id == o.original_item_id)	// don't worry about searching on type, because lesson item_ids are integers and resource/activity item_ids are guids
					if (sr) {	// this should always evaluate to true
						// switch to the copied item_id and copied lesson/resource record
						sr.item_id = o.copied_item_id
						if (sr.type == 'lesson') sr.value = new Lesson(o.lesson_data)
						else sr.value = new Resource(o.resource_data)
					}
				}

				// now continue adding; we will add the copies
				this.add_selected_items_finish(arr)
			})

			// TODO: account for indicating, in search results, items where we have already added copies of the item into the current collection
		},

		add_selected_items_finish(arr) {
			// if the collection we're adding to *is* agency_sanctioned, and any items are *not* agency_sanctioned, we have to mark those items as agency_sanctioned
			if (this.new_items_are_agency_sanctioned) {
				let payload = { user_id: this.user_info.user_id, assets: [] }
				for (let sr of arr) {
					if (!sr.value.agency_sanctioned) {
						payload.assets.push({type: sr.type, id: sr.item_id})
						// mark the asset as agency_sanctioned here, so we don't have to wait for mark_assets_as_agency_sanctioned to return (not sure if sr.value is in store, but set just in case)
						this.$store.commit('set', [sr.value, 'agency_sanctioned', true])
					}
				}
				if (payload.assets.length > 0) {
					U.ajax('mark_assets_as_agency_sanctioned', payload, result=>{
						if (result.status != 'ok') {
							this.$alert('mark_assets_as_agency_sanctioned: ' + result.status)
							return
						}
					})
				}
			}

			this.$emit('add_items_from_search', arr)

			// mark these items as already_added, using set for quick lookup
			const arr_item_ids = new Set(arr.map((item) => item.item_id))
			for (let i = 0; i < this.searchable_items.length; ++i) {
				if (arr_item_ids.has(this.searchable_items[i].item_id)) {
					let sr = Object.assign({}, this.searchable_items[i])
					sr.already_added = true
					this.searchable_items.splice(i, 1, sr)
				}
			}
		},

		show_sparkl(sparkl_item_to_open, embed_mode) {
			// originally borrowed from ResourceCollectionItem.vue
			// This structure holds the info used in SparklEmbed, which we extract here from the resource object
			let activity_record = {
				tool_activity_id: sparkl_item_to_open.url,
				lti_resource_link_id: sparkl_item_to_open.resource_id,
				activity_title: sparkl_item_to_open.description,
				creator_user_id: sparkl_item_to_open.creator,
			}

			// override sparkl_origin if the collection tells us to do so (used for AP Sparkl activities, e.g.)
			let sparkl_origin_override = null
			if (this.home_collection && !empty(this.home_collection.sparkl_origin_override)) {
				sparkl_origin_override = this.home_collection.sparkl_origin_override
			}

			this.open_sparkl_activity_embed_object = {
				resource: sparkl_item_to_open,
				activity_record: activity_record,
				embed_mode: embed_mode,	// 'view' or 'edit'
				in_unit_editor: true,	// TODO: this might be false
				in_my_default_collection: false,	// TODO: this might be true
				// viewing_original_of_in_my_collections: false,
				controller_component: this,
				sparkl_origin_override: sparkl_origin_override,
			}
			vapp.$refs.sparkl_embed.show_activity(this.open_sparkl_activity_embed_object)

			// hide active dialogs/overlays while the activity shows (e.g. the unit editor might be showing)
			$('.v-dialog__content--active, .v-overlay--active').hide()
		},

		// this is called by the SparklEmbed component when the user clicks the "CLOSE" btn in Cureum (controller_component.close_sparkl())
		close_sparkl() {
			// if the activity is open for editing, we need to ask Sparkl if anything needs to be saved
			if (this.open_sparkl_activity_embed_object.embed_mode == 'edit') {
				U.loading_start()	// we'll close the spinner in close_sparkl_finish
				// send the host_activity_saved message TO sparkl, so that Sparkl saves anything that might have been edited there
				vapp.$refs.sparkl_embed.execute('host_activity_saved', {})

				this.sparkl_closed_from_embed = true
				// ... then once sparkl is done saving, sparkl_activity_saved (below) will be called, and since sparkl_closed_from_embed is true, sparkl_activity_saved will call close_sparkl_finish

			} else {
				// not open for editing, so close immediately
				this.close_sparkl_finish()
			}
		},

		close_sparkl_finish() {
			U.loading_stop()
			// reset sparkl_closed_from_embed
			this.sparkl_closed_from_embed = false

			// clear open_sparkl_activity_embed_object
			this.open_sparkl_activity_embed_object = null

			// call sparkl_embed.hide_activity
			vapp.$refs.sparkl_embed.hide_activity()

			// re-show active dialogs/overlays
			$('.v-dialog__content--active, .v-overlay--active').show()

			if (this.new_activity) {
				// clear new_activity and new_activity_saved if set
				this.new_activity = null
				this.new_activity_saved = false
				this.$emit('dialog_cancel')
			}
		},

		// TODO: fill this in...
		copy_to_my_content() {
			this.$alert('You are currently previewing this activity.')
		},

		///////////////////////////////////////// Import
		import_shared_item(item_type) {
			let params = {acceptText:'Import'}
			if (item_type == 'lesson') {
				params.title = 'Import Shared Lesson'
				params.text = 'Enter the shared Lesson ID:<div class="mt-2" style="font-size:14px;font-style:italic">This ID should start with an “L”.</div>'
			} else if (item_type == 'activity') {
				params.title = 'Import Shared Activity'
				params.text = 'Enter the shared Activity ID:<div class="mt-2" style="font-size:14px;font-style:italic">This ID should start with an “A”.</div>'
			} else {
				params.title = 'Import Shared Resource'
				params.text = 'Enter the shared Resource ID:<div class="mt-2" style="font-size:14px;font-style:italic">This ID should start with “R-”.</div>'
			}

			this.$prompt(params).then(entered_item_id => {
				if (entered_item_id.search(/^([ALR])(-?)([\w-]+)$/) == -1) {
					this.$alert('The ID you entered is not valid.').then(()=>this.import_shared_item())
					return
				}
				let item_id_prefix = RegExp.$1
				let item_id = RegExp.$3

				// for a non-sparkl resource, you can't add the same resource two times in the same unit, so reject if this is the case
				// note that if adding_to_lesson is true, we're importing to a *lesson*, and there you can do this (?)
				if (item_type == 'resource' && !this.adding_to_lesson && this.home_unit) {
					if (this.home_unit.resources.find(x=>x.resource_id==item_id)) {
						this.$alert('The specified resource already exists in this unit. You cannot import the same resource twice in the same unit.')
						return
					}
				}
				// for lessons and sparkls, this will create a copy, which is a feature

				// use copy_assets_for_adding_to_collection to copy the specified item
				let payload = {
					user_id: this.user_info.user_id, 
					resource_ids: [], 
					lesson_ids: [],
					agency_sanctioned: 'no',
				}

				// if we're using a sparkl_origin_override for this collection, send it in
				if (this.home_collection && !empty(this.home_collection.sparkl_origin_override)) {
					payload.sparkl_origin_override = this.home_collection.sparkl_origin_override
				}

				// if we don't have a home_collection for some reason, assume the copy shouldn't be agency_sanctioned
				if (this.home_collection) payload.agency_sanctioned = this.new_items_are_agency_sanctioned ? 'yes' : 'no'

				if (item_id_prefix == 'L') payload.lesson_ids.push(item_id)
				else payload.resource_ids.push(item_id)

				U.loading_start()
				U.ajax('copy_assets_for_adding_to_collection', payload, result=>{
					U.loading_stop()

					if (result.status != 'ok') {
						// the service could fail because the user enters an invalid item ID
						if (result.status != 'bad_item_id') result.status = `The Item ID you entered (${item_id_prefix}${item_id}) was invalid.`
						this.$alert(result.status)
						return
					}

					// create a search result object for the copied item
					let sr
					if (item_id_prefix == 'L') {
						let l = new Lesson(result.copies[0].lesson_data)
						sr = {
							type: 'lesson',
							item_id: l.lesson_id,
							value: l,
							text: l.lesson_title,
							already_added: true,
							selected: true,
						}
					} else {
						let r = new Resource(result.copies[0].resource_data)
						sr = {
							type: 'resource',
							item_id: r.resource_id,
							value: r,
							text: r.description,
							already_added: true,
							selected: true,
						}
					}

					// add to imported_items
					this.imported_items.push(sr)

					// add the item like we do with search -- the handler of add_items_from_search will add the item to whatever collection we're saving
					this.$emit('add_items_from_search', [sr])
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		///////////////////////////////////////// Create
		create_new_resource() {
			// console.log('here', this.home_collection)
			let o = {
				resource_id: 'new',
				agency_sanctioned: this.new_items_are_agency_sanctioned,
				description: '', 
				type: this.$store.state.lst.resource_editor_resource_type,
				teacher_facing: this.$store.state.lst.resource_editor_teacher_facing,
			}

			// if we have default values for standards, set them
			if (this.$store.state.lst.resource_editor_standards.length > 0) {
				o.standards = object_copy(this.$store.state.lst.resource_editor_standards)
			}

			// if we have default values for "extensions", set them
			let extensions = {}
			if (this.$store.state.lst.resource_editor_content_source) extensions.content_source = this.$store.state.lst.resource_editor_content_source
			if (this.$store.state.lst.resource_editor_content_type) extensions.content_type = this.$store.state.lst.resource_editor_content_type
			if (this.$store.state.lst.resource_editor_license_uri) extensions.license_uri = this.$store.state.lst.resource_editor_license_uri
			if (this.$store.state.lst.resource_editor_license_text) extensions.license_text = this.$store.state.lst.resource_editor_license_text
			if (U.object_has_keys(extensions)) o.extensions = extensions

			// if we're in the context of a collection with a subject and/or grade, set mappings (e.g. 'grade-4', or 'subject-Math')
			if (this.home_collection) {
				let mappings = []
				if (this.home_collection.subject) mappings.push('subject-' + this.home_collection.subject.toLowerCase())

				if (this.home_collection.grade_low == 'K' && this.home_collection.grade_high == '5') mappings.push('grade-elem')
				else if (this.home_collection.grade_low == '6' && this.home_collection.grade_high == '8') mappings.push('grade-mid')
				else if (this.home_collection.grade_low == '9' && this.home_collection.grade_high == '12') mappings.push('grade-high')
				else if (this.home_collection.grade_low) mappings.push(('grade-' + this.home_collection.grade_low).toLowerCase())

				if (mappings.length > 0) o.mappings = mappings
			}

			let r = new Resource(o)
			r.is_new_resource = true
			this.new_resource_placeholder = r
		},

		create_resource_saved(resource_data) {
			// add created resource like we do with search
			let r = new Resource(resource_data)
			let sr = {
				type: 'resource',
				item_id: r.resource_id,
				value: r,
				text: r.description,
				already_added: true,
				selected: true,
			}
			// the handler of add_items_from_search will add the resource to whatever collection we're saving
			this.$emit('add_items_from_search', [sr])
			this.create_resource_cancel()
			this.$emit('dialog_cancel')
		},

		create_resource_cancel() {
			this.new_resource_placeholder = null
		},

		create_lesson_start() {
			// create new_lesson
			this.new_lesson = new Lesson({
				lesson_title: '', 
				lp_variant: this.use_llm_for_new_lessons ? 'B' : 'A',
				creator_user_id: this.user_info.user_id,
				agency_sanctioned: this.new_items_are_agency_sanctioned,
				course_code: this.home_collection ? this.home_collection.course_code : '',
				lp_unit_id: this.home_unit ? this.home_unit.lp_unit_id : 0,
			})

			// set new_lesson_class; by default it's 'teacher'
			this.new_lesson_class = 'teacher'
			// TODO: determine if this should be a template lesson
			// if (this.item_to_show.is_lesson_template) this.new_lesson_class = 'template'

			// if we're using the LPC and this is the first time the user has created a lesson in this session, show the LPC info dialog
			if (this.use_llm_for_new_lessons && !vapp.lpc_info_shown) {
				vapp.show_llm_lesson_plan_description()
			}

			console.log('create_lesson_start', this.new_lesson)
		},

		create_lesson_cancel() {
			this.new_lesson = null
		},

		create_lesson_saved(args) {
			// the lesson editor will have saved the resource to the db
			let l = new Lesson(args.updated_lesson)
			let sr = {
				type: 'lesson',
				item_id: l.lesson_id,
				value: l,
				text: l.lesson_title,
				already_added: true,
				selected: true,
			}

			// apply *_showing values from edited_lesson
			if (args.edited_lesson) {	// this should always be true
				l.resources_showing = args.edited_lesson.resources_showing
				l.standards_showing = args.edited_lesson.standards_showing
				l.student_description_showing = args.edited_lesson.student_description_showing
				for (let i = 0; i < args.edited_lesson.lesson_plan.length; ++i) {
					l.lesson_plan[i].lc_showing = args.edited_lesson.lesson_plan[i].lc_showing
					l.lesson_plan[i].lc_open_for_editing = args.edited_lesson.lesson_plan[i].lc_open_for_editing
				}
			}

			// if we just saved the newly-created lesson for the first time...
			if (this.new_lesson.lesson_id == 0) {
				// add the lesson like we do with search -- the handler of add_items_from_search will add the lesson to whatever collection we're saving
				this.$emit('add_items_from_search', [sr])

				// set new_lesson to l, and allow the user to continue to edit it (unless the flag says to close)
				if (args.flag != 'and_close') {
					this.new_lesson = null
					this.$nextTick(x=>this.new_lesson = l)
					console.log('create_lesson_saved!:', l)
				}

			} else {
				// else the user saved once and continued editing, so use edit_item_saved to save it
				this.$emit('edit_item_saved', {type:'lesson', updated_lesson: l})
			}

			// if the flag is 'and_close', close the editor
			if (args.flag == 'and_close') {
				this.create_lesson_cancel()
				this.$emit('dialog_cancel')
			}
		},

		create_activity_start() {
			console.log('create_activity_start')
			this.$prompt({
				title: `Create New ${this.site_config.sparkl_app_name} Student Activity`,
				text: 'Enter new activity title:',
				initialValue: '',
				disableForEmptyValue: true,
				acceptText: 'Create Activity',
				acceptIconAfter: 'fas fa-circle-arrow-right',
			}).then(title => {
				title = $.trim(title)
				if (empty(title)) return

				// create a stub of the new activity to be created
				this.new_activity = new Resource({
					resource_id: 'new',	// triggers a new guid
					agency_sanctioned: this.new_items_are_agency_sanctioned,
					type: 'sparkl',
					url: '0',	// sparkl activity_id; this triggers sparkl to create a new sparkl activity
					description: title,
					creator: this.user_info.user_id,
				})
				this.new_activity_saved = false
				console.log('create_activity_start', this.new_activity)
				
				// open sparkl_embed to show the activity
				this.show_sparkl(this.new_activity, 'edit')
				// as user saves/closes, sparkl_activity_saved will be called

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		sparkl_activity_saved(activity_data_from_sparkl) {
			console.log('sparkl_activity_saved in ResourceSearch', activity_data_from_sparkl)
			// this is called when Sparkl issues a 'sparkl_activity_saved' message
			// activity_data_from_sparkl should include sparkl_activity_id, stars_available, activity_instructions, activity_title, activity_editors
			// note that for a newly-created activity, activity_id and tool_activity_id will both initially be 0

			// NOTE: removing code from ResourceCollectionItem that is executed when sparkl_activity_owned_by_user is false... we might need to bring this back; it is used either when "finishing the process of copying the activity to my content", or when editing a resource that is actually owned by the teacher, mostly in the unit editor context

			// NOTE: Code below needs to be kept in synch with ResourceCollectionItem (it's not identical, but some things are the same)
			let changed = false
			let resource_copy = this.open_sparkl_activity_embed_object.resource.copy_for_save()

			// if sparkl_activity_id is changed, set it
			if (activity_data_from_sparkl.sparkl_activity_id && activity_data_from_sparkl.sparkl_activity_id != resource_copy.url) {
				resource_copy.url = activity_data_from_sparkl.sparkl_activity_id
				changed = true
			}
			
			// ditto the activity title
			if (activity_data_from_sparkl.activity_title && activity_data_from_sparkl.activity_title != resource_copy.description) {
				resource_copy.description = activity_data_from_sparkl.activity_title
				changed = true
			}

			// ditto case_alignments
			if (activity_data_from_sparkl.case_alignments && !this.open_sparkl_activity_embed_object.resource.standards_match(activity_data_from_sparkl.case_alignments)) {
				resource_copy.standards = activity_data_from_sparkl.case_alignments
				changed = true
			}

			// build activity description from the rest of the activity_data_from_sparkl; if changed, set it in resource.long_description
			// (we're not currently using this description, but we might in the future)
			if (activity_data_from_sparkl.stars_available) {
				let d = `Student activity with ${activity_data_from_sparkl.stars_available} star${activity_data_from_sparkl.stars_available == 1 ? '' : 's'} available to be earned`
				
				if (resource_copy.long_description != d) {
					resource_copy.long_description = d
					changed = true
				}
			}

			// if open_to_exercise_bank is 'bravo', that means this is sparkl_bank resource; otherwise it's a "normal" sparkl activity
			let resource_type = (activity_data_from_sparkl.open_to_exercise_bank == 'bravo') ? 'sparkl_bank' : 'sparkl'
			if (resource_type != resource_copy.type) {
				resource_copy.type = resource_type
				changed = true
			}

			// if we changed anything, save the activity resource to the db (if it was a new activity, the sparkl_activity_id/url will have changed from 0 to the newly-created sparkl activity_id, so changed will have been set to true above)
			if (changed) {
				console.log('saving sparkl activity (ResourceSearch)...')
				U.loading_start()
				this.$store.dispatch('save_resource', { resource: new Resource(resource_copy) }).then(saved_resource_data => {
					U.loading_stop()

					let r = new Resource(saved_resource_data)
					let sr = {
						type: 'resource',
						item_id: r.resource_id,
						value: r,
						text: r.description,
						already_added: true,
						selected: true,
					}

					// if we're showing a newly-created activity...
					if (this.new_activity) {
						// follow pattern from create_lesson_saved: if we just saved a newly-created activity for the first time...
						if (!this.new_activity_saved) {
							// add the activity like we do with search -- the handler of add_items_from_search will add the activity to whatever collection we're saving
							this.$emit('add_items_from_search', [sr])
							this.new_activity_saved = true

						} else {
							// else the user saved once and continued editing, so use edit_item_saved to save it
							this.$emit('edit_item_saved', {type:'resource', updated_resource: r})
						}

						// set this.new_activity to the new version of the resource
						this.new_activity = r
					
					// else user is previewing an activity they are an editor for, and has made a change (we can't stop them from doing this)
					} else {
						// emit edit_item_saved to update the activity in whatever collection we're editing
						this.$emit('edit_item_saved', {type:'resource', updated_resource: r})
					}

					// set open_sparkl_activity_embed_object.activity_record to the new activity resource data
					this.open_sparkl_activity_embed_object.activity_record = {
						tool_activity_id: r.url,
						lti_resource_link_id: r.resource_id,
						activity_title: r.description,
						creator_user_id: r.creator,
					}

					// once activity is saved, if sparkl_closed_from_embed is true, finish closing sparkl
					if (this.sparkl_closed_from_embed) this.$nextTick(()=>this.close_sparkl_finish())
				})
				// return here so we don't call close_sparkl_finish too quickly below 
				return
			}

			// if we didn't have to save, and sparkl_closed_from_embed is true, finish closing sparkl here
			if (this.sparkl_closed_from_embed) this.close_sparkl_finish()
		},

		///////////////////////////////////////
		done_clicked() {
			if (this.at_least_one_item_selected) {
				this.$confirm({
					title: 'Add Item(s) Before Closing?',
					text: `You have selected at least one item to add. Would you like to add this/these items before closing the search interface?`,
					cancelText: 'Add items, then close',
					acceptText: 'Close without adding',
					dialogMaxWidth: 600,
					focusBtn: true,		// focus on the accept btn when dialog is rendered
				}).then(y => {
					this.$emit('dialog_cancel')
				}).catch(n=>{
					// note that the "add" option is in the cancel position
					this.add_selected_items()
					this.$emit('dialog_cancel')
				}).finally(f=>{})
			} else {
				this.$emit('dialog_cancel')
			}
		},

		use_llm_clicked(confirmed) {
			// if we're here, the user has clicked to switch between the OG and LLC editor. warn them if they've already started editing
			if (!empty(this.$refs.lesson_editor.edited_lesson.lesson_title) && confirmed !== true) {
				this.$confirm({
					text: 'If you switch the lesson editor interface at this time, you will lose any work you’ve already done on this lesson in the current editor interface. Are you sure you want to switch?',
					acceptText: 'Yes, Switch',
				}).then(y => {
					this.use_llm_clicked(true)
					
				}).catch(n=>{
					// if they switch to cancel, revert use_llm_for_new_lessons and don't switch
					this.use_llm_for_new_lessons = !this.use_llm_for_new_lessons

				}).finally(f=>{})
				return
			}

			// copy through the title if entered
			this.new_lesson.lesson_title = this.$refs.lesson_editor.edited_lesson.lesson_title

			if (this.use_llm_for_new_lessons) {
				this.new_lesson.lp_variant = 'B'
				this.show_llm_lesson_plan_description()
			} else {
				this.new_lesson.lp_variant = 'A'
			}
		},
		show_llm_lesson_plan_description() { vapp.show_llm_lesson_plan_description() },

		edit_item_start() {
			this.$inform({text: `Note that if you make changes, you may need to refresh your browser window to see those changes reflected in the ${this.site_config.app_name} user interface.`, timeout:5000})
		},
		unpublish_item_start() {
			this.$inform({text: `Note that if you unpublish this item, you may need to refresh your browser window to see the change reflected in the ${this.site_config.app_name} user interface.`, timeout:5000})
		},
	}
}
</script>

<style lang="scss">

.k-search-card {
	font-size:16px;
	color:#000;
	background-color:#eee;

	.v-input--selection-controls__input {
		margin-right:4px;
	}
	.v-label {
		color:#000;
		padding-left:0;
	}

	.v-btn.k-toggle-btn-active-class {
		background-color:$v-indigo-darken-3!important;
	}

	input::placeholder {
		color:$v-indigo-lighten-1!important;
	}
	.v-input--is-focused {
		input::placeholder {
			color:$v-indigo-lighten-4!important;
		}
	}

	.v-text-field .fa-filter {
		font-size:20px;
		margin-left:-4px;
	}
}

.k-search-options-holder {
	white-space:nowrap; 
	background-color:#fff; padding:14px 12px; 
	border-radius:6px;
}

.k-full-resource-search-result-category {
	background-color:$v-indigo-lighten-4;
	padding:4px 8px;
	border-radius:3px;
	margin-top:20px;
}

.k-full-resource-search-result {
	display:flex;
	// margin:2px 0 6px 0;
	padding:4px 0 0px 4px;
	font-size:14px;
	border-radius:4px;
}

.k-full-resource-search-result-unit {
	color:#555;
	font-size:0.85em;
	margin-left:8px;
}

.k-full-resource-search-result-from-lesson {
	color:#555;
	font-size:0.85em;
	margin-left:8px;
}

.k-full-resource-search-result-already-added {
	opacity:0.7;
}

// use this class for the button that initializes a search; assumes by default that the v-btn is size small
.k-search-initialize-btn {
	background-color:#fff;
	position:relative;
	padding-right:42px!important;
	// border-width:2px;
	border-color:#444;
	color:#444!important;
	font-weight:normal!important;
	letter-spacing:0.25px;
	border-radius:4px!important;
	text-transform:none!important;
	.k-search-initialize-btn-icon-div {
		position:absolute;
		right:-42px;
		top:-6px;
		height:26px;
		width:30px;
		background-color:#444;
		border-radius:0 3px 3px 0;
		color:#fff;
		.fa-search {
			padding-top:5px;
		}
	}
}

.k-search-initialize-btn.v-size--default {
	padding-right:48px!important;
	height:40px!important;
	font-size:16px!important;
	// width:240px;
	.k-search-initialize-btn-icon-div {
		right:-48px;
		top:-10px;
		height:39px;
		.fa-search {
			font-size:18px!important;
			padding-top:10px;
		}
	}
}

.k-standards-search-results-wrapper {
	max-width:800px;
	margin:0 auto;
	font-size:16px;
	line-height:20px;

	.k-standards-search-result:nth-child(even) { background-color:#fff; }
	.k-standards-search-result:nth-child(odd) { background-color:#f8f8f8; }
}

.k-resource-search-in-filtered-items-true {
	position:relative;
}
.k-resource-search-in-filtered-items-true::before {
	content: "";
	position: absolute;
	top: 0; left: 0; right: 0; bottom: 0;
	background-color: rgba(255, 255, 0, 0.2);
	mix-blend-mode: multiply;
	z-index: 1;
	pointer-events: none; /* So it doesn't block clicks */
}

// .k-resource-search-in-filtered-items-false {
// 	opacity:0.8;
// }

.k-resource-search-results-no-results {
	text-align:center;
	border-radius:4px;
	background-color:$v-red-lighten-5;
	padding:8px;
	width:400px;
	margin-left:auto;
	margin-right:auto;
}
</style>

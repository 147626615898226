// Various utility functions, some created by PW and some brought in from the intertubes (all open source)
// Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams

/** Checks whether a value is empty, defined as null or undefined or "".
 *  Note that 0 is defined as not empty.
 *  @param {*} val - value to check
 *  @returns {boolean}
 */
window.empty = function(val) {
	// you can also call this fn as empty(o, 'foo', 'bar'), which will return true if:
	// - o is empty OR o is not an object
	// - o.foo is empty OR o.foo is not an object
	// - o.foo.bar is empty OR o.foo.bar is not an object
	// this simplifies an if clause like `if (empty(o) || empty(o.foo) || empty(o.foo.bar))` to `if (empty(o, 'foo', 'bar'))`
	if (arguments.length > 1) {
		if (empty(val) || typeof(val) != 'object') {
			return true
		}

		// if we get to here, arguments[1] might be an array or the first of a series of strings; handle both cases
		let args
		if ($.isArray(arguments[1])) {
			args = arguments[1]
		} else {
			// copy arguments, then use splice to get everything from index 1 on
			args = $.merge([], arguments).splice(1)
		}

		// if we're here, we know that val is itself an object
		if (empty(val[args[0]])) {
			return true
		} else if (args.length > 1) {
			// shift the first value out of args and recurse
			val = val[args[0]]
			args.shift()
			return empty(val, args)
		}
		return false
	}

	// note that we need === because (0 == "") evaluates to true
	return (val === null || val === "" || val === undefined)
}

// look for a nested property of an object; if any property in the list is empty, return null
// obj = {alpha: {bravo:'charlie'}}
// oprop(obj, 'alpha', 'bravo') // == 'charlie'
window.oprop = function(obj) {
	if (empty(obj)) return null
	if (arguments.length == 1 || typeof(obj) != 'object') return obj

	// if we get to here, arguments[1] might be an array or the first of a series of strings; handle both cases
	let args
	if ($.isArray(arguments[1])) {
		args = arguments[1]
	} else {
		// copy arguments, then use splice to get everything from index 1 on
		args = $.merge([], arguments).splice(1)
	}

	// if args[0] isn't a property of obj, return null
	if (empty(obj[args[0]])) {
		return null

	// if we still have more than 1 arg, shift the first value out of args and recurse
	} else if (args.length > 1) {
		obj = obj[args[0]]
		args.shift()
		return oprop(obj, args)

	} else {
		return obj[args[0]]
	}
}


/** Return val or default_val, depending on whether val isn't or is empty (as defined by window.empty above)
 *  SHORTCUT: dv()
 */
window.default_value = function(val, default_val) {
	if (!empty(val)) return val
	return default_val
}
window.dv = window.default_value

/** Set a property of an object to a default value if the property is currently empty (as defined by window.empty above)
 *  SHORTCUT: sdp()
 *  Two versions:
 *      1. sdp(o, 'foo', 'bar')
 *          - sets o.foo to 'bar', unless o.foo is already set
 *      2. sdp(o, p, 'foo', 'bar')
 *          - if p.foo is set, then set o.foo to p.foo; otherwise set o.foo to 'bar'
 *
 *  Also do some limited type checking: if default_val is a Boolean or a number, make sure the value we ultimately set
 *      is a Boolean or number; if converting it to the correct type fails, throw an error
 *  Version 2 can also include a "legal_vals" array, for enumeration checking
 */
window.set_default_property = function(o) {
	var prop, default_val, val, legal_vals
	if (arguments.length >= 4) {
		legal_vals = arguments[4]
		default_val = arguments[3]
		prop = arguments[2]
		var p = arguments[1]
		if (!empty(p) && !empty(p[prop])) val = p[prop]
		else val = default_val
	} else {
		prop = arguments[1]
		default_val = arguments[2]
		if (!empty(o[prop])) val = o[prop]
		else val = default_val
	}

	// if default_val is true or false, make sure the value we set is also a boolean
	if (default_val === true || default_val === false) {
		if (val === 'true') val = true
		if (val === 'false') val = false
		if (val != true && val != false) {
			if (typeof(prop) == 'object') prop = '[object]'
			throw new Error(sr('Boolean argument expected for property $1; $2 received', prop, val))
		}
		// convert 1/0 to true/false
		val = (val == true)

	// if default_val is a number, make sure the value we set is also a number
	} else if (typeof(default_val) == 'number') {
		let new_val = val * 1
		if (isNaN(new_val)) {
			throw new Error(sr('Numeric argument expected for property $1; $2 received', prop, val))
		}
		val = new_val
	}

	// if we got legal_vals (which must be an array), check to make sure the value is one of those; use default_val otherwise
	if (!empty(legal_vals)) {
		if (legal_vals.find(x => x == val) == null) {
			console.log(sr('illegal value found for prop “$1”: “$2”', prop, val))
			val = default_val
		}
	}

	o[prop] = val
}
window.sdp = window.set_default_property

/** Replace variables in a string, a la PHP
 * e.g.:
 * str_replace("replace value $bar.", {bar: "foo"})
 *    =>
 * "replace value foo."
 *
 * o.bar can be either a scalar value or a function that returns a scalar value
 *
 * Or, you can pass variables in directly, and specify them with $1, $2, $3, etc.
 * str_replace("replace value $1.", "foo")
 *    =>
 * "replace value foo."
 *
 * SHORTCUT: sr()
 *
 *  @param {string} s
 *  @param {object|array} [o] - if this is an array, it will be assumed to be an array of objects. if this is not an array or an object, it will treat the arguments array as a list of scalars
 *  @returns {*}
 */
window.str_replace = function(s, o) {
	// if o is an array, recursively process each object in the array
	if ($.isArray(o)) {
		for (var i = 0; i < o.length; ++i) {
			s = str_replace(s, o[i])
		}

	} if (typeof(o) == "object") {
		// find all instances of $xxx
		var matches = s.match(/\$(\w+)\b/g)
		if (!empty(matches)) {
			for (var i = 0; i < matches.length; ++i) {
				var key = matches[i].substr(1)
				var val = null
				// scalars
				if (typeof(o[key]) == "string" || typeof(o[key]) == "number") {
					val = o[key]
				} else if (typeof(o[key]) == "function") {
					val = o[key]()
				}
				if (val !== null) {
					var re = new RegExp("\\$" + key + "\\b", "g")
					s = s.replace(re, val)
				}
			}
		}
	} else {
		for (var i = 1; i < arguments.length; ++i) {
			var re = new RegExp("\\$" + i + "\\b", "g")
			s = s.replace(re, arguments[i])
		}
	}
	return s
}
// shortcut
window.sr = str_replace

// shortcut for doing a jquery extend; this is useful for console.logging vue reactive objects
window.extobj = function(o, stringify) {
	o = $.extend(true, {}, o)
	if (stringify) return JSON.stringify(o, null, 4)
	return o
}

// actually this works better in many circumstances
window.object_copy = function(o, stringify) {
	o = JSON.parse(JSON.stringify(o))
	if (stringify) return JSON.stringify(o, null, 4)
	return o
}

window.U = {}

// universal user info model for all apps
U.user_info = {}
U.set_user_info = function(payload) {
	sdp(U.user_info, payload, 'user_id', 0)
	sdp(U.user_info, payload, 'user_key', '')
	sdp(U.user_info, payload, 'displayname', '')
	sdp(U.user_info, payload, 'email', '')
	sdp(U.user_info, payload, 'role', 0)
}
U.user_is_instructor = function() {
	return U.user_info.role >= 1000
}
// use !U.user_is_instructor() to identify students

// we use this to transmit data to services when the data might include html that might get flagged/rejected by firewalls (e.g. saving Sparkl exercises to the GaDOE velocity server)
U.encode_blocked_keywords = function(s) {
	s = s.replaceAll('iframe', 'ifxzxzxrame')
	s = s.replaceAll('embed', 'emxzxzxbed')
	s = s.replaceAll('object', 'obxzxzxject')
	s = s.replaceAll('http', 'htxzxzxtp')		// this will also deal with "https"
	s = s.replaceAll('script', 'scxzxzxript')	// this will also deal with "javascript"
	s = s.replaceAll('onclick', 'oncxzxzxlick')
	s = s.replaceAll('style', 'stxzxzxyle')
	s = s.replaceAll('data-', 'daxzxzxta-')		// added 1/14/25
	s = s.replaceAll('data:image', 'encodedxzyzximage')	// updated 1/14/25 to encode any data:image (previously searched for data:image/webp;base64)

	return s
}

// session_id is set in app.vue
U.session_id = ''

U.ajax = function(service_name, data, callback_fn, override_options) {
	var url = "/src/ajax.php"

	if (data instanceof FormData) {
		data.append('service_name', service_name)
	} else {
		data.service_name = service_name
	}

	// add session_id to data if we have it
	if (U.session_id) {
		if (data instanceof FormData) {
			data.append('session_id', U.session_id)
		} else {
			data.session_id = U.session_id
		}
	}

	// if we're simulating another user, send actual_user_info.user_id in for verification purposes
	if (vapp.$store.state.actual_user_info.user_id) {
		data.actual_user_id = vapp.$store.state.actual_user_info.user_id
	}
	
	var options = {
		type: "POST",
		url: url,
		cache: false,
		data: data,
		dataType: "text",
		success: function(str, text_status) {
			var result
			if (empty(str)) {
				result = {"status": "Ajax returned with no status"}
			} else {
				// console.log(sr('ajax $1: $2', data.service_name, U.format_bytes(str.length)))
				try {
					result = JSON.parse(str)
				} catch(e) {
					result = {"status": str}
				}
			}

			if (empty(result.status)) {
				result.status = "Ajax returned with no status"
			}
			if (result.status != "ok") {
				// error
				console.log("ajax success but not 'ok'", result)
			}

			if (!empty(callback_fn)) {
				callback_fn(result)
			}

		},
		error: function(jqXHR, textStatus, errorThrown) {
			if (jqXHR.responseText == 'Session expired - please re-launch') {
				alert('Your session has expired.')
				// TODO: figure out what we want to do here...
			}

			var result = {
				"status": "Ajax server error",
				"ajax_name": service_name,
				"textStatus": textStatus,
				"errorThrown": errorThrown,
				"responseText": jqXHR.responseText
			}

			if (!empty(callback_fn)) {
				callback_fn(result)
			}
		}
	}

	// override any options coming in
	if (!empty(override_options)) {
		for (var key in override_options) {
			if (key == 'sparklsalt') continue
			options[key] = override_options[key]
		}
	}

	$.ajax(options)
}

// this is from SparklSALT; used for retrieving CASE json files
U.get_json_file = function(filename, callback_fn) {
	// ajax calls are posted to to ajax.php, with the service_name included in the post data
	// local development with 'npm run serve'
	if (document.location.host.indexOf('localhost') > -1) {
	 	url = "/src/ajax.php"
	// server, or local testing with 'npm run build'
	} else {
		url = "/src/ajax.php"
	}

	let data = {
		service_name: 'retrieve_file',
		filename: filename,
		user_id: window.vapp.$store.state.user_info.user_id,
	}

	// for Inspire, we must have a session_id, retrieved in the code above when utilities.js...
	if (U.session_id) {
		data.session_id = U.session_id
	}

	var options = {
		type: "POST",
		url: url,
		cache: false,	// true??
		data: data,
		dataType: "text",
		success: function(str, text_status) {
			let json = {}
			if (!empty(str)) {
				try {
					json = JSON.parse(str)
				} catch(e) {
					console.log(str)
					vapp.$alert('An error occurred when parsing the JSON for retrieved file ' + filename)
					json = {}
				}
			}

			callback_fn(json)
		},
		error: function(jqXHR, textStatus, errorThrown) {
			var result = {
				"status": "Ajax server error",
				"ajax_name": service_name,
				"textStatus": textStatus,
				"errorThrown": errorThrown,
				"responseText": jqXHR.responseText
			}
			console.log(result)
			vapp.$alert('An error occurred when retrieving file ' + filename)

			if (!empty(callback_fn)) {
				callback_fn('')
			}
		}
	}

	$.ajax(options)
}

// general-purpose fn for retrieving any json file, via the 'scrape_page' service
// MAKE SURE YOU KNOW THAT THE INCOMING JSON IS SAFE TO RETURN IF YOU'RE USING THIS!
U.fetch_json = function(url) {
	return new Promise((resolve, reject) => {
		U.ajax('scrape_page', {user_id: vapp.user_info.user_id, url: url, use_headless_chrome: 'no'}, result=>{
			if (result.status != 'ok') { 
				// console.log('scrape_page fail', result.status)
				reject(result.status)
				return
			}

			if (result.html === false) {
				// console.log('scrape_page fail3', result.status)
				reject('no returned data (url may not have been valid)')
				return
			}

			try { 
				let json = JSON.parse(result.html)
				// console.log('scrape_page success', result.status)
				resolve(json)
			} catch(e) { 
				// console.log('scrape_page fail2', result.status)
				reject('retrieved data could not be JSON.parsed')
			}
		})
	})
}

// try to find a named Vue component of the starting component
// directives_wrapper_component() { return U.find_ancestor_vue_component(this, 'DirectivesWrapper') },
// U.find_ancestor_vue_component(this, ['LessonView', 'LessonEdit'])
U.find_ancestor_vue_component = function(this_component, names_to_find) {
	if (typeof(names_to_find) == 'string') names_to_find = [names_to_find]
	let p = this_component.$parent
	while (p) {
		if (names_to_find.includes(p.$options.name)) return p
		p = p.$parent
	}
	return null
}

U.loading_start = function(msg) {
	$('#spinner-wrapper').show()
	if (!empty(msg)) {
		$('#spinner-wrapper').append(sr('<div id="spinner-wrapper-msg" style="text-align:center;margin:20% 30px 0 30px;font-size:24px;font-weight:bold;font-family:sans-serif;color:#fff;">$1</div>', msg))
	}
}

U.loading_stop = function() {
	$('#spinner-wrapper').hide()
	$('#spinner-wrapper-msg').remove()
}

// check the location search string for a given key, and optionally for a val_to_check for the key
U.check_get_string_param = function(key, val_to_check) {
	let val = (new URL(window.location)).searchParams.get(key)
	if (empty(val_to_check)) return val != null
	else return val != val_to_check
}

// clear the location search string without reloading the page or generating a new history entry
U.clear_location_search = function() {
	window.history.replaceState(null, '', window.location.pathname)
}

U.object_has_keys = function(o) {
	if (typeof(o) != 'object') return false
	return Object.keys(o).length > 0
}

U.download_file = function(data, filename) {
	// this works for a csv or a tsv; probably also for other data types
	// slightly hacky solution from here: https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
	var data_str = 'data:text/json;charset=utf-8,' + encodeURI(data)
	var download_anchor_node = document.createElement('a')
	download_anchor_node.setAttribute('href', data_str)
	// set filename of downloaded file
	download_anchor_node.setAttribute('download', filename)
	document.body.appendChild(download_anchor_node) // required for firefox
	download_anchor_node.click()
	download_anchor_node.remove()
}

U.download_json_file = function(json, filename) {
	// slightly hacky solution from here: https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
	let dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json))
	let downloadAnchorNode = document.createElement('a')
	downloadAnchorNode.setAttribute('href', dataStr)
	// set filename of downloaded json file
	downloadAnchorNode.setAttribute('download', sr('$1.json', filename))
	document.body.appendChild(downloadAnchorNode) // required for firefox
	downloadAnchorNode.click()
	downloadAnchorNode.remove()
}

/**
 * Randomize array element order in-place.
 * Using Durstenfeld shuffle algorithm.
 * https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 */
U.shuffle_array = function(array) {
	for (var i = array.length - 1; i > 0; i--) {
		var j = Math.floor(Math.random() * (i + 1));
		var temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
}

// if one argument is specified, it's max and we return >= 0  and < max (use for an array index, e.g.)
// if two arguments are specified, they're min and max, and we return >= min and <= max
// if the first argument is a function, assume it's a random number generator to use instead of Math.random()
U.random_int = function() {
	let rng = Math.random
    let args
	if (arguments.length > 0 && typeof(arguments[0]) == "function") {
		rng = arguments[0]
        args = [arguments[1], arguments[2]]
	} else {
        args = arguments
    }

	if (empty(args[0])) return 0

	let min, max
	if (empty(args[1])) {
		min = 0
		max = args[0] * 1
	} else {
		min = args[0] * 1
		max = args[1] * 1 + 1
	}
	if (isNaN(min) || isNaN(max)) {
		return 0
	}
	return min + Math.floor(rng() * (max-min))
}

// dot_product and cosine_similarity fn
U.dot_product = function(x, y) {
	function dotp_sum(a, b) { return a + b; }
	function dotp_times(a, i) { return x[i] * y[i]; }
	return x.map(dotp_times).reduce(dotp_sum, 0);
}
  
U.cosine_similarity = function(A,B) {
	return U.dot_product(A, B) / (Math.sqrt(U.dot_product(A,A)) * Math.sqrt(U.dot_product(B,B)));
}

U.word_count = function(text) {
	if (empty(text)) return 0
	text = '' + text

	// remove entities
	text = text.replace(/\&[#\w]+/g, ' ')

	// insert spaces at breaks
	text = text.replace(/\b/g, ' ')

	// remove tags
	text = text.replace(/<.*?>/g, ' ')

	// remove non-letters
	text = text.replace(/[^\w\s]/g, '')

	// trim
	text = $.trim(text)

	// split on spaces and return count
	let arr = text.split(/\s+/)
	return arr.length;
}

U.copy_to_clipboard = function(s) {
	// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
	// https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
	let fallback = (s) => {
		$('body').append('<textarea class="k-copy-to-clipboard-input-textarea"></textarea>')
		let jq = $('body').find('.k-copy-to-clipboard-input-textarea')
		jq.val(s)
		jq[0].select()
		document.execCommand("copy")
		jq.remove()
	}

	if (!navigator.clipboard) {
		fallback(s)
	} else {
		navigator.clipboard.writeText(s).then(function() {
			console.log('Async: Copy to clipboard was successful')
		}, function(err) {
			console.error('Async: Could not copy text; trying fallback', err)
			fallback(s)
		})
	}
}

// plural string / plural string with number
// ps('word', 1) => 'word'
// ps('word', 2) => 'words'
// psn('word', 2) => '2 words'
// ps('a word', 2, 'the words') => 'the words'
U.ps = function(s, val, plural_s) {
	if (val*1 == 1) return s
	if (!empty(plural_s)) return plural_s
	return s + 's'
}
U.psn = function(s, val, plural_s) {
	s = U.ps(s, val, plural_s)
	return sr('$1 $2', val, s)
}

U.capitalize_word = function(s) {
	if (empty(s) || typeof(s) != 'string') return ''
	return s[0].toUpperCase() + s.toLowerCase().substr(1)
}

U.capitalize_words = function(s) {
	s = s.replace(/(\w+)/g, ($0, $1) => {
		return U.capitalize_word($1)
	})
	return s
}

U.longest_word_length = function(s) {
	if (empty(s)) return 0
	let arr = s.split(/(\s+)|(<wbr>)/)
	arr.sort((a,b)=>b.length-a.length)
	return arr[0].length
}

// parse and return unique characters in a string
// note that the last copy of each repeated letter will be returned
// note that this is case sensitive -- U.unique_chars('fFoobbar') returns 'fFobar'; use U.unique_chars(s.toLowerCase) to convert to lc first
U.unique_chars = function(s) {
	return s.replace(/(.)(?=.*\1)/g, "")
}

// use jquery to strip html and convert entities to text
U.html_to_text = function(html) {
	return $(sr('<div>$1</div>', html)).text()
}

U.get_block_width = function(params) {
	// U.get_block_width({val:s, style:'font-weight:bold', cls:'k-cls', tag:'div'})

	// old-style fn signature had 4 separat parameters
	if (arguments.length > 1) {
		// [val, cls, style, tag] = arguments
		params = {val: arguments[0], cls: arguments[1], style: arguments[2], tag: arguments[3]}
	}
	let {val, cls, style, tag} = params

	if (!style) style = ''
	if (!tag) tag = 'div'
	let $sizer = $(`<${tag} class="${cls}" style="width:auto; position:fixed; left:-2000px; top:-2000px; ${style}">${val}</${tag}>`)
	// append to v-application so that we inherit application styles
	$('.v-application').append($sizer)
	let w = $sizer.outerWidth()
	$sizer.remove()
	return w
}

U.get_max_font_size_for_block_width = function(params) {
	// U.get_max_font_size_for_block_width({size_hi:18, size_lo:12, width:660, val:s, style:'font-weight:bold', cls:'k-cls', tag:'div'})
	
	let s = params.size_hi
	let base_style = params.style || ''
	for (; s >= params.size_lo; --s) {
		params.style = `${base_style}; font-size:${s}px;`
		let w = U.get_block_width(params)
		if (w <= params.width) break
	}
	return s
}

// this fn trims common forms of blank space at the start and end of an html string
U.trim_blank_html = function(s) {
	s = $.trim(s)

	s = s.replace(/^(<p>(<br>)*\s*<\/p>)+/, '')
	s = s.replace(/(<p>(<br>)*\s*<\/p>)+$/, '')

	s = s.replace(/^(<br>)+\s*/, '')
	s = s.replace(/\s*(<br>)+$/, '')

	s = s.replace(/<li>\s*(<br>)*\s*<\/li>/g, '')
	return s
}

// convert a string of html that may include block elements (p's and div's) to text with line breaks specified by br's
U.blocks_to_breaks = function(incoming_html) {
	let nodes = $(`<div>${incoming_html}</div>`)[0].childNodes
	let html = ''
	for (let node of nodes) {
		let jq = $(node)

		// for text nodes...
		if (node.nodeType == 3) {
			// skip blank nodes
			let s = $.trim(jq.text())
			if (empty(s)) continue
			// otherwise include text with a space around it
			html += ` ${s} `
			continue
		}

		// convert blank lines to empty <br>'s
		if (empty(jq.html()) || empty(jq.html().replace(/(&nbsp;)|\s+/g, ''))) {
			html += '<br>'
			continue
		}

		// for p's and divs...
		if (node.tagName == 'P' || node.tagName == 'DIV') {
			// if html isn't currently empty, insert a <br>; then the contents of the div
			if (!empty(html)) html += '<br>'
			html += jq.html()
			continue
		}
	}

	html = $.trim(html.replace(/ +/g, ' '))
	
	return html
}

// easy natural sort algorithm that actually seems to work!
// https://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
U.natural_sort = function(a, b) {
	return a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true})
}

// extend the marked library...
setTimeout(()=>{
	window.original_marked_function = window.marked
	window.markedInline = window.marked.parseInline
	window.marked = function(src, opt, callback) {
		src = window.original_marked_function(src, opt, callback)

		// handle roman numeral ordered lists
		src = src.replace(/\n([ivx]+)\. (.*)/g, ($0, $1, $2) => {
			let start = ['', 'i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x', 'xi', 'xii', 'xiii', 'xiv', 'xv', 'xvi', 'xvii', 'xviii', 'xix', 'xx', 'xi', 'xii', 'xiii', 'xiv', 'xv'].indexOf($1)
			return `<ol type="i" start="${start}"><li>${$2}</li></ol>`
		})

		// handle lettered ordered lists
		src = src.replace(/\n([a-z])\. (.*)/g, ($0, $1, $2) => {
			let start = ['', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'].indexOf($1)
			return `<ol type="a" start="${start}"><li>${$2}</li></ol>`
		})
		src = src.replace(/\n([A-Z])\. (.*)/g, ($0, $1, $2) => {
			let start = ['', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].indexOf($1)
			return `<ol type="A" start="${start}"><li>${$2}</li></ol>`
		})
		
		return src
	}
}, 0)

// set the following to true to debug latex
U.verbose_latex = false

// partial results of experiments with other rendering systems are still here...
// U.latex_rendering_system = 'katex'
// U.latex_rendering_system = 'mathjax'
// U.latex_rendering_system = 'mathlive-delayed'
U.latex_rendering_system = 'mathlive'

U.inject_mathlive_styles = function() {
	// call this when the app is initialized; we do it this way so that we can also include the styles in print view
	// this is modeled on the `injectStylesheet` fn in https://github.com/arnog/mathlive/blob/master/src/common/stylesheet.ts
	// we have a static version of the MathLive core css file in `mathlive_core_css.js` (included in main.js), set to U.mathlive_core_css
	// (adapted from https://github.com/arnog/mathlive/blob/49f11e27e44cb71f02fbcd99336199e41335a1c7/css/core.less)
	const styleNode = window.document.createElement('style');
    styleNode.id = `mathlive-style-core`;
    styleNode.append(window.document.createTextNode(U.mathlive_core_css));
    window.document.head.appendChild(styleNode);
}

U.render_latex = function(s) {
	// skip expensive regexp if no latex
	if (empty(s) || !s.includes('$')) return s

	let original_s = s
	// by convention, latex is coded like this:
	// For example, we define $5^{(1/3)}$ to be the cube root of 5 because we want $[5^{(1/3)}]^3$ = $5^{[(1/3) x 3]}$ to hold, so $[5^{(1/3)}]^3$ must equal 5.
	s = '[' + s + ']'
	s = s.replace(/((<p[^>]*>\s*)|[\s({\[>]|\&nbsp;)\$(\S([^$]*?\S)?)\$((\s*<\/p[^>]*>)|[\s)}\].,;:%!?<]|\&nbsp;)/g, ($0, $start, $x1, $latex, $x2, $end) => {
		// note that we search for $ followed immediately by a non-space char at the start, and a non-space char followed immediately by $ at the end
		// also there needs to be a space, >, or an opening paren, bracket, or brace before the opening $, then a space, <, a closing paren, bracket, or brace, or a punctuation mark after the closing $
		// (this is why we add [] around the full string at the start and take the [] back out below; this makes the fn work if the string has a LaTeX formula at the start or end of the string)
		// we render everything between the two $'s, and replace everything including the $'s with the rendered html

		let ks
		if (U.latex_rendering_system == 'mathjax') {
			// let mathjax_options = {em: 12, ex: 6, display: false, containerWidth:100, scale:20}	// https://docs.mathjax.org/en/latest/web/typeset.html
			let mathjax_options = {display: false}	// https://docs.mathjax.org/en/latest/web/typeset.html
			ks = MathJax.tex2svg($latex, mathjax_options)
			if (ks) ks = ks.outerHTML	// MathJax will return DOM

			// get a "clearspeak" translation of the equation for screenreaders
			ks = U.clearspeak_span($latex, ks)

			return $start + ks + $end

		} else if (U.latex_rendering_system == 'mathlive-delayed') {
			// ks = `<span data-latex="${$latex}$" aria-hidden="true" translate="no" style="display: inline-flex;">${MathLive.convertLatexToMarkup($latex)}</span>`
			// ks = MathLive.convertLatexToMarkup($latex)
			ks = `\\(${$latex}\\)`

			// get a "clearspeak" translation of the equation for screenreaders
			ks = U.clearspeak_span($latex, ks)

			return $start + ks + $end

		} else {
			if (U.verbose_latex) console.log($latex)

			// dollar signs within equations need to elaborately coded so we find them properly; do a transform and then un-transform here to find them properly
			// This LaTeX formula includes dollar signs: $&amp;dollar;33 \times 2 = &amp;dollar;66$
			$latex = $latex.replace(/&amp;dollar;/g, '\\textnormal{DXD}')

			// replace other &amp;'s with &
			$latex = $latex.replace(/&amp;/g, '&')

			// replace &lt;/$gt;/etc. with latex codes
			$latex = $latex.replace(/&(lt|gt|le|ge|ne);/g, '\\$1')

			// take out \left and \right (?)
			$latex = $latex.replace(/\\(left|right)\b/g, '')

			// MathLive wants "textrm" instead of "rm"
			$latex = $latex.replace(/\\rm/g, '\\textrm')

			// replace newlines? currently seems that this isn't necessary
			// $latex = $latex.replace(/\\\\/g, '\\newline')

			// matrices
			$latex = $latex.replace(/\[ *\{\\begin\{array\}\{\*\{20}\{c\}\}(.*?)\\end\{array\}\} *\]/g, '\\begin{bmatrix}$1\\end{bmatrix}')
			$latex = $latex.replace(/\\begin\{array\}\{\*\{20}\{c\}\}(.*?)\\end\{array\}/g, '\\begin{matrix}$1\\end{matrix}')

			if (U.verbose_latex) console.log($latex)
			if (U.verbose_latex) console.log('------')

			let span_attributes = ''
			if (U.latex_rendering_system == 'mathlive') {
				let mathstyle = ($start.match(/^<p/) && $end.match(/^<\/p/)) ? 'displaystyle' : 'textstyle'
				ks = MathLive.convertLatexToMarkup($latex, {mathstyle: mathstyle})	// mathstyle:'textstyle' uses 'inline' mode; alternative is 'displaystyle'

				// if we're using this method, add additional span_attributes when we generate the clearspeak_span
				span_attributes = `data-latex="${$latex}" class="k-mathlive-span" aria-hidden="true" translate="no" style="display: inline-flex;"`
				// ks = `<span data-latex="${$latex}" class="k-mathlive-span" aria-hidden="true" translate="no" style="display: inline-flex;">${ks}</span>`
				// ks = `<span class="k-mathlive-span" aria-hidden="true" translate="no">${ks}</span>`

			} else {
				ks = window.katex.renderToString($latex, {throwOnError: false})
			}

			// we have to replace with the entity, instead of $, so that we don't re-process it below
			ks = ks.replace(/<span class="mord textrm">DXD<\/span>/g, '&dollar;')

			// get a "clearspeak" translation of the equation for screenreaders
			ks = U.clearspeak_span($latex, ks, span_attributes)

			return $start + ks + $end
		}
	})

	s = s.replace(/^\[([\s\S]*)\]$/, '$1')

	// if we made a change and there is still a $ in the string, we may need to run through the re again, so try that here
	// e.g. `$x = 0.4444444\ldots$ $9x = 4\ldots$`
	if (s != original_s && s.indexOf('$') > -1) {
		s = U.render_latex(s)
	}

	return s
}

U.clearspeak_span = function(latex_string, rendered_string, span_attributes) {
	// have to take out the 'displaystyle' 
	latex_string = latex_string.replace(/^\\displaystyle{(.*)}$/, '$1')
	// let cs = U.mathjax.generate_clearspeak(latex_string)
	let cs = MathLive.convertLatexToSpeakableText(latex_string)

	// transforms on clearspeak output
	// cs = cs.replace(/ backslash /g, ' ')	// when MathJax fails to render things, sometimes it will just print out " backslash degree"

	if (U.verbose_latex) console.log('clearspeak: ' + cs)

	// add additional span_attributes if provided
	if (!empty(span_attributes)) span_attributes = ' ' + span_attributes
	else span_attributes = ''

	// // adding a comma to the start and the end is necessary because otherwise, the screen reader will often ignore the initial letter in the equation (e.g. it will read “${g_n}$” as "sub n", skipping the "g")
	// return `<span aria-label=" , ${cs} , ">${rendered_string}</span>`
	return `<span aria-label="${cs}"${span_attributes}>${rendered_string}</span>`
}

U.preserve_latex = function(s) {
	if (U.latex_rendering_system == 'mathjax') return s

	// skip expensive regexp if no latex
	if (!s.includes('$')) return s

	// we have to preserve some special characters that marked will otherwise mess up
	s = '[' + s + ']'
	s = s.replace(/([\s({\[>])\$(\S([^$]*?\S)?)\$([\s)}\].,;:%!?<])/g, ($0, $1, $2, $3, $4) => {
		// $2 is the LaTeX formula itself
		$2 = $2.replace(/ /g, 'KKXXKK')
		$2 = $2.replace(/\\/g, 'KKBSKK')
		$2 = $2.replace(/\&/g, 'KKAMPKK')
		$2 = $2.replace(/\*/g, 'KKASTKK')
		$2 = $2.replace(/\_/g, 'KKUNDKK')
		$2 = $2.replace(/\~/g, 'KKTILKK')
		return $1 + '$' + $2 + '$' + $4
	})
	s = s.replace(/^\[([\s\S]*)\]$/, '$1')
	return s
}

U.preserve_latex_reverse = function(s) {
	if (U.latex_rendering_system == 'mathjax') return s

	// skip expensive regexps if nothing was preserved
	if (!s.includes('KK')) return s
	
	s = s.replace(/KKTILKK/g, '~')
	s = s.replace(/KKUNDKK/g, '_')
	s = s.replace(/KKASTKK/g, '*')
	s = s.replace(/KKAMPKK/g, '&')
	s = s.replace(/KKBSKK/g, '\\')
	return s.replace(/KKXXKK/g, ' ')
}

U.marked_latex = function(s) {
	// preserve latex; then do marked, then unpreserve and render latex
	if (U.verbose_latex) console.log('original: ' + s)
	s = U.preserve_latex(s)
	if (U.verbose_latex) console.log('preserve: ' + s)
	s = marked(s)
	if (U.verbose_latex) console.log('  marked: ' + s)

	s = U.preserve_latex_reverse(s)
	if (U.verbose_latex) console.log('reversed: ' + s)
	s = U.render_latex(s)
	if (U.verbose_latex) console.log('=================')
	return s
}

// formats seconds into "hh:mm:ss", with leading zeros inserted as appropriate and hours optional
U.time_string = function(seconds, force_hours) {
	seconds = Math.round(seconds)
	let hours = Math.floor(seconds / 3600)
	let minutes = Math.floor((seconds - hours*3600) / 60)
	seconds = seconds % 60
	if (seconds < 10) seconds = '0' + seconds
	let ts = sr('$1:$2', minutes, seconds)
	if (hours > 0 || force_hours) {
		if (minutes < 10) ts = '0' + ts
		ts = sr('$1:$2', hours, ts)
	}

	return ts
}

U.today = function(return_timestamp) {
	let d = new Date()
	d.setHours(0,0,0,0)	// set to midnight of current timezone -- use setUTCHours for UTC

	if (return_timestamp) return d.getTime() / 1000
	else return d
}

U.yesterday = function(return_timestamp) {
	let d = U.today()
	d.setHours(-24,0,0,0)

	if (return_timestamp) return d.getTime() / 1000
	else return d
}

U.tomorrow = function(return_timestamp) {
	let d = U.today()
	d.setHours(24,0,0,0)

	if (return_timestamp) return d.getTime() / 1000
	else return d
}

// generates RFC-compliant GUIDs
// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
// this is "e7" of Jeff Ward's answer
U.guid_lut = []; for (var i=0; i<256; i++) { U.guid_lut[i] = (i<16?'0':'')+(i).toString(16); }
U.new_uuid = function() {
	var d0 = Math.random()*0xffffffff|0;
	var d1 = Math.random()*0xffffffff|0;
	var d2 = Math.random()*0xffffffff|0;
	var d3 = Math.random()*0xffffffff|0;
	return U.guid_lut[d0&0xff]+U.guid_lut[d0>>8&0xff]+U.guid_lut[d0>>16&0xff]+U.guid_lut[d0>>24&0xff]+'-'+
		U.guid_lut[d1&0xff]+U.guid_lut[d1>>8&0xff]+'-'+U.guid_lut[d1>>16&0x0f|0x40]+U.guid_lut[d1>>24&0xff]+'-'+
		U.guid_lut[d2&0x3f|0x80]+U.guid_lut[d2>>8&0xff]+'-'+U.guid_lut[d2>>16&0xff]+U.guid_lut[d2>>24&0xff]+
		U.guid_lut[d3&0xff]+U.guid_lut[d3>>8&0xff]+U.guid_lut[d3>>16&0xff]+U.guid_lut[d3>>24&0xff];
}
U.is_uuid = function(s) {
	return s.search(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/) == 0
}

U.is_letter = function(char) {
	return ('ABCDEFGHIJKLMNOPQRSTUVWXYZ'.indexOf(char) > -1)
}

// this is called in froala-plugin to try to get iframeable urls from original urls
U.get_iframeable_video_url = function(url) {
	// youtube videos
	if (url.search(/(youtube|youtu\.be)/) > -1) {
		let video_id = U.youtube_id_from_url(url)
		// <iframe width="560" height="315" src="https://www.youtube.com/embed/ps80qZbbdbM?si=X3RFlF1i6kkk__Bv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
		url = `https://www.youtube.com/embed/${video_id}`

	// for google drive urls, convert to an iframeable version
	// https://drive.google.com/file/d/1x9CnpdZcXwBXcuxQD6Gxi55I6vBkrlYC/view
	} else if (url.search(/https:\/\/drive.google.com\/file\/d\/(.*?)\//) > -1) {
		url = `https://drive.google.com/file/d/${RegExp.$1}/preview`

	} else if (url.search(/https:\/\/drive.google.com\/uc?id=(.*?)(&|$)/) > -1) {
		url = `https://drive.google.com/file/d/${RegExp.$1}/preview`

	// Vimeo: https://vimeo.com/channels/shortoftheweek/1021931365
	// https://vimeo.com/673608912
	} else if (url.search(/https:\/\/[^\/]*?vimeo.com\b.*\/(.+?)(\?|$)/) > -1) {
		url = `https://player.vimeo.com/video/${RegExp.$1}`

	// loom: https://www.loom.com/share/ea524cd1244e4303b9df80bfeb6a4364?t=0
	} else if (url.search(/https:\/\/.*?loom.com\/share\/(.+?)(\?|$)/) > -1) {
		url = `https://www.loom.com/embed/${RegExp.$1}`
	}
	// we can add additional transforms here as needed

	return url
}

// this is used for iframed videos (or non-video urls) that aren't youtube
U.add_video_iframe_footer = function($parent) {
	// pass the parent element's jquery in; this will first remove any previously-added footers, and then add footers to the iframes
	$parent.find('.k-exercise-video-iframe-footer').remove()
	$parent.find('.k-exercise-video-iframe').each((index, el)=>{
		let url = $(el).attr('src')
		let html = `<div class="k-exercise-video-iframe-footer"><a href="${url}" target="_blank"><i class="fas fa-arrow-up-right-from-square mr-2" style="font-size:16px"></i>Open in New Tab</a></div>`
		$(el).after(html)
	})
}

// try to extract a youtube ID from a url
U.youtube_id_from_url = function(url) {
	// the 'url' could already be an id alone; but try to extract the url from known forms of youtube urls
	// https://www.youtube.com/watch?v=jofNR_WkoCE&foo
	let video_id = url.replace(/.*\bv=([A-Za-z0-9_-]+).*/, '$1')
	// https://youtu.be/BtN-goy9VOY
	if (video_id == url) video_id = url.replace(/.*youtu\.be\/([A-Za-z0-9_-]+).*/, '$1')

	// if what we have at this point contains any characters not in youtube IDs, return an empty string
	if (video_id.search(/[^A-Za-z0-9_-]/) > -1) {
		return ''
	}

	// otherwise return the video_id
	return video_id

	// caller can check against the empty string to see if it's a valid url or "bare" id; this will generally work fine
}

// Sound effects, using howler
// https://medium.com/game-development-stuff/how-to-create-audiosprites-to-use-with-howler-js-beed5d006ac1
U.sounds = {
	sprite: {
	  "complete_blank": [
		0,
		2953.378684807256
	  ],
	  "complete_exercise": [
		4000,
		1819.0249433106578
	  ],
	  "pop_1": [	// correct guess
		7000,
		996.8934240362817
	  ],
	  "pop_2": [	// incorrect guess
		9000,
		996.8934240362817
	  ],
	  "pop_3": [	// hint
		11000,
		996.8934240362817
	  ],
	  "pop_4": [
		13000,
		748.9569160997736
	  ],
	  "pop_click": [	// open blank
		15000,
		480.7709750566893
	  ]
	},

	// U.sounds.on = false // turn off sound effects
	on: true
}

U.sounds.initialize = function() {
	if (U.sounds.loaded === true) return

	// delay so nothing else will wait for sound initialization to finish
	setTimeout(()=>{
		U.sounds.howl = new Howl({
			src: ['/audio/sparklsounds.ogg', '/audio/sparklsounds.mp3'],
			volume: 0.1,
			sprite: U.sounds.sprite,
		})

		U.sounds.howl.once('load', ()=>{
			U.sounds.loaded = true
			console.log('sounds loaded!')
		})
	}, 0)
}

U.sounds.play = function(id) {
	if (!U.sounds.loaded || !U.sounds.on) return

	if (empty(U.sounds.sprite[id])) {
		console.log(sr('Tried to play nonexistant sound effect “$1”', id))
	} else {
		U.sounds.howl.play(id)
	}
}

// utilities for using local storage to save and retrieve settings
U.local_storage_set = function(key, val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	window.localStorage.setItem(key, JSON.stringify(val));
}

U.local_storage_clear = function(key) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	window.localStorage.removeItem(key);
}

U.local_storage_get = function(key, default_val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	let val = window.localStorage.getItem(key)
	if (!empty(val)) {
		// PW 3/22/2025: if val is "undefined", this will fail; not sure why we never encountered this before!
		try {
			val = JSON.parse(val);
		} catch(e) {
			console.warn(`local_storage_get: couldn’t parse “${val}” for key: ${key}`)
			val = null
		}
	}

	if (empty(val)) {
		return default_val
	} else {
		// do some type checking
		if (default_val === true || default_val === false) {
			if (val === 'true') val = true
			if (val === 'false') val = false
			if (val != true && val != false) {
				throw new Error(sr('Boolean argument expected for key $1; $2 received', key, val))
			}

		} else if (typeof(default_val) == 'number') {
			if (isNaN(val*1)) {
				throw new Error(sr('Numeric argument expected for key $1; $2 received', key, val))
			}
			val = val * 1

		} else if (typeof(default_val) == 'string') {
			if (typeof(val) != 'string') {
				throw new Error(sr('String argument expected for key $1; $2 received', key, val))
			}
			val = val + ''
		}
		return val
	}
}

U.cookie_set = function(key, val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	document.cookie = sr('$1=$2', key, val)
}

U.cookie_clear = function(key) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
}

U.cookie_get = function(key, default_val) {
	// use a consistent prefix for every key
	key = 'pwet__' + key

	let cookies = document.cookie.split(';')
	for (let c of cookies) {
		let arr = c.split('=')
		if ($.trim(arr[0]) == key) {
			let val = arr[1]
			// do some type checking
			if (default_val === true || default_val === false) {
				if (val === 'true') val = true
				if (val === 'false') val = false
				if (val != true && val != false) {
					throw new Error(sr('Boolean argument expected for key $1; $2 received', key, val))
				}

			} else if (typeof(default_val) == 'number') {
				if (isNaN(val*1)) {
					throw new Error(sr('Numeric argument expected for key $1; $2 received', key, val))
				}
				val = val * 1

			} else if (typeof(default_val) == 'string') {
				if (typeof(val) != 'string') {
					throw new Error(sr('String argument expected for key $1; $2 received', key, val))
				}
				val = val + ''
			}
			return val
		}
	}
}

// normalize strings for response validation
U.normalize_string_for_validation = function(s) {
	if (empty(s)) return ''

	// remove html
	s = s.replace(/<[\w|\/].*?>/g, ' ')

	// remove entities
	s = s.replace(/\&\w+;/g, ' ');

	// normalize single-quotes and remove single-quotes that aren't contractions
	s = s.replace(/[‘’]/g, "'")
	s = s.replace(/'\s/g, ' ')
	s = s.replace(/\s'/g, ' ')

	// remove all chars except letters, numbers, and basic punctuation (see list in regex) (?)
	s = s.replace(/[^\w'().,;:?!]/g, ' ')

	// collapse multiple spaces and trim
	s = $.trim(s.replace(/\s+/g, ' '))

	// use lower case for everything (?)
	s = s.toLowerCase()
	return s
}

// Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)
// Returns a function, that, as long as it continues to be invoked, will not be triggered.
// The function will be called after it stops being called for N milliseconds.
// If `immediate` is passed, trigger the function on the leading edge, instead of the trailing.
// To call:
	// // establish the debounce fn if necessary
	// if (empty(this.fn_debounced)) {
	// 	this.fn_debounced = U.debounce(function(x) {
	// 		...
	// 	}, 1000)
	// }
	// // call the debounce fn
	// this.fn_debounced(x)
U.debounce = function(func, wait, immediate) {
  	var timeout

  	// This is the function that is actually executed when the DOM event is triggered.
  	return function executedFunction() {
    	// Store the context of this and any parameters passed to executedFunction
    	var context = this
    	var args = arguments

    	// The function to be called after the debounce time has elapsed
    	var later = function() {
      		// null timeout to indicate the debounce ended
      		timeout = null

      		// Call function now if you did not on the leading end
      		if (!immediate) func.apply(context, args)
    	}

    	// Determine if you should call the function on the leading or trail end
    	var callNow = immediate && !timeout

    	// This will reset the waiting every function execution. This is the step that prevents the function
    	// from being executed because it will never reach the inside of the previous setTimeout
    	clearTimeout(timeout)

    	// Restart the debounce waiting period. setTimeout returns a truthy value (it differs in web vs node)
    	timeout = setTimeout(later, wait)

    	// Call immediately if you're dong a leading end execution
    	if (callNow) func.apply(context, args)
  	}
}

// apply a shake animation to the given element (specified as jquery)
// dir is 'h' or 'v'; add an id if multiple things are likely to be shaken in quick succession; otherwise the clearTimeouts will conflict
U.shake = function(jq, dir, id) {
	if (empty(id)) id = 'x'

	// clear previous timeout if found
	if (!empty(U['shake_timeout_' + id])) clearTimeout(U['shake_timeout_' + id])

	// add shake class
	jq.addClass('k-shake-' + dir)

	// remove class after delay for shake
	U['shake_timeout_' + id] = setTimeout(x=>jq.removeClass('k-shake-' + dir), 600)
}

/*
seedrandom

// Make a predictable pseudorandom number generator.
var myrng = new Math.seedrandom('hello.');
console.log(myrng());                // Always 0.9282578795792454
console.log(myrng());                // Always 0.3752569768646784
*/
!function(f,a,c){var s,l=256,p="random",d=c.pow(l,6),g=c.pow(2,52),y=2*g,h=l-1;function n(n,t,r){function e(){for(var n=u.g(6),t=d,r=0;n<g;)n=(n+r)*l,t*=l,r=u.g(1);for(;y<=n;)n/=2,t/=2,r>>>=1;return(n+r)/t}var o=[],i=j(function n(t,r){var e,o=[],i=typeof t;if(r&&"object"==i)for(e in t)try{o.push(n(t[e],r-1))}catch(n){}return o.length?o:"string"==i?t:t+"\0"}((t=1==t?{entropy:!0}:t||{}).entropy?[n,S(a)]:null==n?function(){try{var n;return s&&(n=s.randomBytes)?n=n(l):(n=new Uint8Array(l),(f.crypto||f.msCrypto).getRandomValues(n)),S(n)}catch(n){var t=f.navigator,r=t&&t.plugins;return[+new Date,f,r,f.screen,S(a)]}}():n,3),o),u=new m(o);return e.int32=function(){return 0|u.g(4)},e.quick=function(){return u.g(4)/4294967296},e.double=e,j(S(u.S),a),(t.pass||r||function(n,t,r,e){return e&&(e.S&&v(e,u),n.state=function(){return v(u,{})}),r?(c[p]=n,t):n})(e,i,"global"in t?t.global:this==c,t.state)}function m(n){var t,r=n.length,u=this,e=0,o=u.i=u.j=0,i=u.S=[];for(r||(n=[r++]);e<l;)i[e]=e++;for(e=0;e<l;e++)i[e]=i[o=h&o+n[e%r]+(t=i[e])],i[o]=t;(u.g=function(n){for(var t,r=0,e=u.i,o=u.j,i=u.S;n--;)t=i[e=h&e+1],r=r*l+i[h&(i[e]=i[o=h&o+t])+(i[o]=t)];return u.i=e,u.j=o,r})(l)}function v(n,t){return t.i=n.i,t.j=n.j,t.S=n.S.slice(),t}function j(n,t){for(var r,e=n+"",o=0;o<e.length;)t[h&o]=h&(r^=19*t[h&o])+e.charCodeAt(o++);return S(t)}function S(n){return String.fromCharCode.apply(0,n)}if(j(c.random(),a),"object"==typeof module&&module.exports){module.exports=n;try{s=require("crypto")}catch(n){}}else"function"==typeof define&&define.amd?define(function(){return n}):c["seed"+p]=n}("undefined"!=typeof self?self:this,[],Math);

// https://stackoverflow.com/questions/1293147/javascript-code-to-parse-csv-data
window.CSV = {
	parse: function(csv, reviver) {
	    reviver = reviver || function(r, c, v) { return v; };
	    var chars = csv.split(''), c = 0, cc = chars.length, start, end, table = [], row;
	    while (c < cc) {
	        table.push(row = []);
	        while (c < cc && '\r' !== chars[c] && '\n' !== chars[c]) {
	            start = end = c;
	            if ('"' === chars[c]){
	                start = end = ++c;
	                while (c < cc) {
	                    if ('"' === chars[c]) {
	                        if ('"' !== chars[c+1]) { break; }
	                        else { chars[++c] = ''; } // unescape ""
	                    }
	                    end = ++c;
	                }
	                if ('"' === chars[c]) { ++c; }
	                while (c < cc && '\r' !== chars[c] && '\n' !== chars[c] && ',' !== chars[c]) { ++c; }
	            } else {
	                while (c < cc && '\r' !== chars[c] && '\n' !== chars[c] && ',' !== chars[c]) { end = ++c; }
	            }
	            row.push(reviver(table.length-1, row.length, chars.slice(start, end).join('')));
	            if (',' === chars[c]) { ++c; }
	        }
	        if ('\r' === chars[c]) { ++c; }
	        if ('\n' === chars[c]) { ++c; }
	    }
	    return table;
	},

	stringify: function(table, replacer) {
	    replacer = replacer || function(r, c, v) { return v; };
	    var csv = '', c, cc, r, rr = table.length, cell;
	    for (r = 0; r < rr; ++r) {
	        if (r) { csv += '\r\n'; }
	        for (c = 0, cc = table[r].length; c < cc; ++c) {
	            if (c) { csv += ','; }
	            cell = replacer(r, c, table[r][c]);
	            if (/[,\r\n"]/.test(cell)) { cell = '"' + cell.replace(/"/g, '""') + '"'; }
	            csv += (cell || 0 === cell) ? cell : '';
	        }
	    }
	    return csv;
	}
};

// simple TSV equivalent to CSV fns above
window.TSV = {
	parse: function(tsv) {
		let lines = tsv.split('\n')
		for (let i = 0; i < lines.length; ++i) {
			let line = $.trim(lines[i])

			if (empty(line)) {
				lines[i] = []
			} else {
				lines[i] = line.split('\t')
			}
		}
		return lines
	},

	stringify:function(lines) {
		let tsv = ''
		for (let i = 0; i < lines.length; ++i) {
			tsv += lines[i].join('\t') + '\n'
		}
		return tsv
	}
};

// compress an image selected by the user from the filesystem (or a picture taken with a phone camera) client-side, and return the dataURL that represents the image
U.create_image_data_url = function(image_file, params) {
	// console.log('create_image_data_url: ' + params.max_width)
	// params: max_width is required; max_height is optional
	let max_width = params.max_width
	let max_height = params.max_height
	// required callback fn to receive the image data_url and placeholder
	let callback_fn = params.callback_fn

	// 0.9 = slightly compressed jpg
	let compression_level = dv(params.compression_level, 0.9)

	let image_format = dv(params.image_format, 'webp')	// jpeg, webp, or png; png is the only one that's required for browsers to support...

	// create a canvas element to do the conversion
	let canvas = document.createElement("canvas")

	// load the image into memory using a FileReader to get the image size
	let reader = new FileReader()
	reader.onload = e => {
		let image = new Image()
		image.onload = e => {
			let natural_image_width = (image.naturalWidth) ? image.naturalWidth : image.width
			let natural_image_height = (image.naturalHeight) ? image.naturalHeight : image.height

			let scaled_image_width, scaled_image_height
			// if image is smaller than width/height, or if max_width is 'full', return as is
			if (max_width == 'full' || natural_image_width < max_width*1) {
				scaled_image_width = natural_image_width
				scaled_image_height = natural_image_height
			} else {
				// else start by scaling to make the image width fit in the max_height
				scaled_image_width = max_width
				scaled_image_height = natural_image_height / (natural_image_width / scaled_image_width)
			}

			// if we have max_height and this makes the height taller than max_height, scale to make the image height fit in the vertical space
			if (max_height && max_height != 'full' && scaled_image_height > max_height) {
				scaled_image_height = max_height
				scaled_image_width = natural_image_width / (natural_image_height / scaled_image_height)
			}

			// set the canvas width, then draw the image to the canvas
			canvas.width = scaled_image_width
			canvas.height = scaled_image_height
			canvas.getContext('2d').drawImage(image, 0, 0, scaled_image_width, scaled_image_height)

			// extract the image dataURL
			let img_url = canvas.toDataURL('image/' + image_format, compression_level)
			// console.log('img_url size:' + img_url.length)

			callback_fn({
				img_url: img_url,
				width: scaled_image_width,
				height: scaled_image_height,
				natural_width: natural_image_width,
				natural_height: natural_image_height,
			})
		}
		image.onerror = e => {
			console.log('IMAGE ONERROR!!', e)
			callback_fn({error: e, source:'image'})
		}
		image.src = e.target.result
	}
	reader.onerror = e => {
		console.log('READER ONERROR!', e)
		callback_fn({error: e, source:'reader'})
	}
	// trigger the FileReader to load the image file
	reader.readAsDataURL(image_file)
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
U.format_bytes = function(bytes, decimals) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = (!decimals || decimals < 0) ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

U.data_url_to_blob = function(data_url) {
    let arr = data_url.split(',')
	let mime = arr[0].match(/:(.*?);/)[1]
	let bstr = atob(arr[1])
	let n = bstr.length
	let u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], {type:mime})
}

// https://stackoverflow.com/a/40975730
// returns 1 for 1/1/XXXX, 32 for 2/1/XXXX, 365 for 12/31/XXXX (if not a leap year), etc.
U.days_into_year = function(d) {
	// convert string to date, assuming YYYY-MM-DD
	if (typeof(d) == 'string') d = date.parse(d, 'YYYY-MM-DD')

    return (Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()) - Date.UTC(d.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
}
U.diy_to_date = function(diy, year) {
	return new Date(year, 0, diy)
}

/*
date and time functions:
npm i date-and-time
https://github.com/knowledgecode/date-and-time

in main.js:
import date from 'date-and-time'
import 'date-and-time/plugin/meridiem';
date.plugin('meridiem')
window.date = date

to convert from timestamp:
date.format(new Date(this.entry.date_added*1000), 'MMMM D, YYYY h:mm A')	// January 1, 2019 3:12 PM
date.format(new Date(this.post.date_created*1000), 'MMM D, YYYY h:mm A')	// Jan 1, 2019 3:12 PM

to convert from mysql date to js Date, then to an alternate format:
let d = date.parse(data.results.created_at, 'YYYY-MM-DD HH:mm:ss')
date.format(d, 'MMM D, YYYY h:mm A')	// Jan 3, 2020 3:04 PM

token   meaning	  example
YYYY    year      0999, 2015
YY      year      05, 99
Y       year      2, 44, 888, 2015
MMMM    month     January, December
MMM     month     Jan, Dec
MM      month     01, 12
M       month     1, 12
DDD (*) day       1st, 2nd, 3rd
DD      day       02, 31
D       day       2, 31
dddd    day/week  Friday, Sunday
ddd     day/week  Fri, Sun
dd      day/week  Fr, Su
HH      24-hour   23, 08
H       24-hour   23, 8
A       meridiem  AM, PM
a (*)   meridiem  am, pm
AA (*)  meridiem  A.M., P.M.
aa (*)  meridiem  a.m., p.m.
hh      12-hour   11, 08
h       12-hour   11, 8
mm      minute    14, 07
m       minute    14, 7
ss      second    05, 10
s       second    5, 10
SSS     msec      753, 022
SS      msec      75, 02
S       msec      7, 0
Z       timezone  +0100, -0800
*/

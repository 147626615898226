<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><v-dialog v-model="dialog_open" persistent max-width="800px"><v-card>
	<v-card-title>
		<h3>My Archived Lessons and Resources</h3>
		<v-spacer />
		<v-btn color="secondary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-times</v-icon>Done</v-btn>
	</v-card-title>
	<v-card-text v-show="items_loaded">
		<div v-if="!has_archived"><i>No archived items found.</i></div>
		<div v-else>
			<div class="d-flex align-center" style="font-size:14px; line-height:20px">
				<div style="flex:1 1 50%; background-color:#eee; padding:4px; border-radius:5px;">{{archived_items.length}} archived item(s) found.<br>Use the kebab menu (<v-icon small>fas fa-ellipsis-v</v-icon>) to view and/or unarchive items.</div>
				<v-spacer/>
				<v-text-field class="k-collection-search-input" flat light rounded outlined solo hide-details clearable dense
					placeholder="Search…"
					v-model="search_terms"
					prepend-inner-icon="fas fa-search" @click:prepend-inner="execute_search_start"
					@click:clear="execute_search_clear"
					@keyup="search_field_keyup"
				></v-text-field>
			</div>
			<div class="d-flex align-center mt-3 mb-3">
					<div class="mr-2">Sort by:</div>
					<div>
						<v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="default_collection_sort_by" mandatory>
							<v-btn x-small width="90px" light :value="'title'" @click.stop="previous_default_collection_sort_by='title'">Title</v-btn>
							<v-btn x-small width="90px" light :value="'created_at'" class="k-tight-btn" @click.stop="set_created_at_sort_order">Date Created</v-btn>
						</v-btn-toggle>
					</div>
				</div>
			<div v-for="(item, i) in archived_items" class="k-resource-folder-resource-container-width-full k-resource-folder-resource-item-full-wrapper k-resource-folder-resource-item-hovered">
				<ResourceCollectionItem :key="i"
					:item="item"
					:from_archived="true"
					:full_width_resource="true"
					@unarchive_item="unarchive_item"
				/>
			</div>
		</div>
	</v-card-text>
</v-card></v-dialog></template>

<script>
import { mapState } from 'vuex'
import ResourceCollectionItem from './ResourceCollectionItem.vue'

export default {
	components: { ResourceCollectionItem },
	data() { return {
		dialog_open: true,
		search_terms: '',
		showing_archived_lessons: [],
		showing_archived_resources: [],
		all_archived_lessons: [],
		all_archived_resources: [],
		items_loaded: false,
	}},
	computed: {
		...mapState(['user_info', 'my_lessons', 'my_resources', 'my_ca_mappings']),
		has_archived() {
			return this.archived_items.length > 0
		},
		archived_items() {
			let arr = this.showing_archived_lessons.concat(this.showing_archived_resources)

			arr.sort((a,b)=>{
				if (this.default_collection_sort_by == 'title') {
					a = (a.lesson_id) ? a.lesson_title : a.description
					b = (b.lesson_id) ? b.lesson_title : b.description
					return U.natural_sort(a,b)
				} else {
					a = (a.lesson_id) ? a.created_at*1 : date.parse(a.created_at, 'YYYY-MM-DD HH:mm:ss').valueOf()/1000
					b = (b.lesson_id) ? b.created_at*1 : date.parse(b.created_at, 'YYYY-MM-DD HH:mm:ss').valueOf()/1000
					if (this.$store.state.lst.default_collection_sort_by_created_at_order == 'desc') return a-b
					else return b-a
				}
			})

			return arr
		},
		default_collection_sort_by: {
			get() { return this.$store.state.lst.default_collection_sort_by },
			set(val) { this.$store.commit('lst_set', ['default_collection_sort_by', val]) }
		},
	},
	created() {
		vapp.archive_viewer_component = this

		U.loading_start()
		U.ajax('get_archived', {user_id: this.user_info.user_id}, result=>{
			if (result.my_archived_lessons) {
				for (let item_data of result.my_archived_lessons) {
					this.showing_archived_lessons.push(new Lesson(item_data))
					this.all_archived_lessons.push(new Lesson(item_data))
				}
			}
			if (result.my_archived_resources) {
				for (let item_data of result.my_archived_resources) {
					this.showing_archived_resources.push(new Resource(item_data))
					this.all_archived_resources.push(new Resource(item_data))
				}
			}
			this.items_loaded = true
			U.loading_stop()
		})
	},
	mounted() {
		this.previous_default_collection_sort_by = this.default_collection_sort_by
	},
	methods: {
		execute_search_start() {
			// establish the debounce fn if necessary
			if (empty(this.fn_debounced)) {
				this.fn_debounced = U.debounce(() => {
					this.execute_search_clear()

					this.search_term_res = U.create_search_re(this.search_terms)

					let matched_lessons = []
					let matched_resources = []

					for (let i of this.archived_items) {
						// normalize items
						const description = i.description ? i.description : i.lesson_title
						const is_lesson = i.description ? false : true
						if (U.strings_match_search_term_res(this.search_term_res, [description])) {
							if (is_lesson) matched_lessons.push(i)
							else matched_resources.push(i)
						}
					}

					this.search_results = matched_lessons.concat(matched_resources)
					if (this.search_results.length == 0) {
						this.$inform({text:'No items matched your search terms.', color:'pink darken-4'})
					} else {
						this.showing_archived_lessons = matched_lessons
						this.showing_archived_resources = matched_resources
					}
				}, 500)
			}

			// call the debounce fn
			this.fn_debounced()
		},
		execute_search_clear() {
			this.showing_archived_lessons = this.all_archived_lessons
			this.showing_archived_resources = this.all_archived_resources
		},
		search_field_keyup(evt) {
			this.execute_search_start()
		},
		unarchive_item(item) {
			const is_lesson = !empty(item.lesson_title)
			const payload = {
				user_id: this.user_info.user_id,
				asset_id: is_lesson ? item.lesson_id : item.resource_id,
				asset_type: is_lesson ? 'lesson' : 'resource'
			}
			U.ajax('add_default_ca_mapping', payload, res => {
				if (is_lesson) {
					this.my_lessons.push(item)
					this.all_archived_lessons = this.all_archived_lessons.filter(l => l !== item)
					this.showing_archived_lessons = this.showing_archived_lessons.filter(l => l !== item)
				} else {
					this.my_resources.push(item)
					this.all_archived_resources = this.all_archived_resources.filter(r => r !== item)
					this.showing_archived_resources = this.showing_archived_resources.filter(r => r !== item)
				}
				this.$store.commit('generate_default_collection')
				this.$store.commit('add_to_array', [this.my_ca_mappings, res.ca_mapping])
			})
		},

		// this is lifted from CollectionUnit
		set_created_at_sort_order(val) {
			if (this.previous_default_collection_sort_by == 'created_at') {
				if (this.$store.state.lst.default_collection_sort_by_created_at_order == 'desc') this.$store.commit('lst_set', ['default_collection_sort_by_created_at_order', 'asc'])
				else this.$store.commit('lst_set', ['default_collection_sort_by_created_at_order', 'desc'])
			}
			this.previous_default_collection_sort_by = 'created_at'
		},
	}
}
</script>

<style lang="scss">
</style>

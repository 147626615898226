<!-- Copyright 2024, Common Good Learning Tools LLC -->
<template><v-dialog v-model="dialog_open" max-width="1200" :fullscreen="$vuetify.breakpoint.smAndDown" :persistent="!pop_up_closable" :transition="false" overlay-opacity="0.9" scrollable @click:outside="clicked_outside" style="border-radius:16px" :content-class="$vuetify.breakpoint.smAndDown?'k-home-dialog-card-small':''">
<v-card class="k-home-dialog-card">
	<v-card-title>
		<div style="width: 100%;">
			<div class="d-flex">
				<div v-if="header_icon" style="width:44px; height:44px; text-align:center; padding-top:6px;background-color:#000; border-radius:36px;">
					<v-icon style="color:#fff;">{{ header_icon }}</v-icon>
				</div>

				<v-spacer/>

				<div>
					<div v-if="pop_up_closable && button">
						<v-btn v-if="button.icon" icon small @click="get_started_clicked">
							<v-icon>{{ button.text }}</v-icon>
						</v-btn>
						<v-btn v-else color="primary" class="xk-tight-btn" @click="get_started_clicked">
							{{ button.text }}
							<v-icon small class="ml-2">fas fa-arrow-right</v-icon>
						</v-btn>
					</div>

					<v-btn v-else color="primary" class="xk-tight-btn" @click="get_started_clicked">
						Sign In to {{ site_config.app_name }}
						<v-icon small class="ml-2">fas fa-sign-in</v-icon>
					</v-btn>
				</div>
			</div>

			<!-- home_header_html can be set in config file -->
			<div v-if="site_config.home_header_html" v-html="unsanitize_html(site_config.home_header_html)"></div>
		</div>
	</v-card-title>

	<v-card-text>
		<div class="k-home-dialog-wrapper-inner">
			<div class="fr-view" v-html="unsanitize_html(pop_up_content)"></div>

			<div v-if="!backdoor_login_only" style="clear:both; border-top:1px solid #ccc; margin-top:20px;">
				<!-- this would show the user's info (e.g. email and name); since the default will now be that we show the dialog in addition to the home page, we shouldn't need this here anymore -->
				<!-- <div v-if="oidc_info" class="mt-3 pt-2 mb-1" style="font-size:16px;" v-html="oidc_info"></div> -->
				<div v-if="!signed_in" class="text-center mt-4 mb-2">
					<v-btn v-if="pop_up_closable" color="secondary" @click="sign_in">Sign In to {{ site_config.app_name }}<v-icon small class="ml-2">fas fa-sign-in</v-icon></v-btn>
					<v-btn v-if="show_guest_mode_btn" color="secondary" @click="open_in_guest_mode">Open {{ site_config.app_name }} in “Guest Mode”</v-btn>
				</div>
			</div>

			<!-- site_footer_html can be set in config file -->
			<div v-if="site_config.site_footer_html" v-html="unsanitize_html(site_config.site_footer_html)"></div>
		</div>
	</v-card-text>
</v-card>
</v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		pop_up_closable: { type: Boolean, required: true },
	},

	data() { return {
		dialog_open: true,
		// add to includer component
	}},

	computed: {
		...mapState(['user_info', 'site_config']),

		...mapGetters(['signed_in', 'role', 'backdoor_login_only']),

		show_guest_mode_btn() {
			// if pop_up_closable is false (which will never be the case if the user is signed in) and the user isn't *required* to sign in, show a "open in guest mode" btn
			return !this.pop_up_closable && this.site_config.require_sign_in != 'yes'
		},

		header_icon() {
			return this.site_config.pop_up?.header_icon || false
		},

		button() {
			return this.site_config.pop_up?.close_button
		},

		home_page_variant() {
			// if we have a pop_up variant, return that
			if (this.site_config.home_page_variants.includes('pop_up')) return 'pop_up'

			// if we only have one variant, return it
			if (this.site_config.home_page_variants.length == 1) {
				return this.site_config.home_page_variants[0]
			}

			// if not signed in and we have an unsignedin variant, return it
			if (!this.signed_in && this.site_config.home_page_variants.includes('unsignedin')) return 'unsignedin'

			// else try to return varient matching role, but if we don't have a student or parent varient, just return 'staff', which will serve for 'signedin'
			if (this.role == 'student' && this.site_config.home_page_variants.includes('student')) return 'student'
			if (this.role == 'parent' && this.site_config.home_page_variants.includes('parent')) return 'parent'	// in this case you should be viewing FamilyHome...
			return 'staff'
		},
		
		pop_up_content() {
			this.$store.dispatch('get_home_page_content', this.home_page_variant)
			return this.$store.state.home_page_content[this.home_page_variant]
		},

		oidc_info() {
			let s = ''
			if (this.user_info.first_name) s += sr('<div>You are signed in as $1 $2 ($3)</div>', this.user_info.first_name, this.user_info.last_name, this.user_info.email)
			
			if (this.site_config.show_user_system_data_on_home == 'yes') {
				s += '<ul class="ml-4">'
				if (this.user_info.oidc_data?.system_name) s += sr('<li>School system: $1</li>', this.user_info.oidc_data.system_name)
				if (this.user_info.oidc_data?.school_name) s += sr('<li>School: $1</li>', this.user_info.oidc_data.school_name)
				if (this.user_info.oidc_data?.user_role) s += sr('<li>Role: $1</li>', this.user_info.oidc_data.user_role)
				if (this.user_info.first_name) s += '</ul>'
			}
			return s
		}
	},
	methods: {
		unsanitize_html(html) {
			if (empty(html)) return ''
			html = html.replace(/%3A/g, ':')
			html = html.replace(/%24/g, '$')
			return html
		},

		clicked_outside() {
			// if pop_up_closable is false, the dialog can't be closed
			if (!this.pop_up_closable) return
			this.$emit('dialog_cancel')
		},

		get_started_clicked() {
			// if they click the get started button and pop_up_closable is false, send them to the sign in screen
			if (!this.pop_up_closable) this.sign_in()
			// else close the dialog
			this.$emit('dialog_cancel')
		},

		open_in_guest_mode() {
			// if they click the guest mode btn, tell vapp to go to main
			this.$emit('dialog_cancel', 'main')
		},

		sign_in() {
			// this.$emit('dialog_cancel')
			vapp.redirect_to_login()
		},
	}
}
</script>

<style lang="scss">
.k-home-dialog-card {
	border-radius:16px!important;
	width:100%;
	overflow:auto;
}

.k-home-dialog-wrapper-inner {
	background-color:#fff;
	padding: 0 16px;

	.fr-view {
		font-size:18px;
		line-height:1.4em;
		max-width:800px;
		margin: 0 auto;
		text-align:left;

		h2 {
			font-size:27px;
			line-height:33px;
		}
		h3 {
			font-size:22px;
			line-height:27px;
		}
	}

}

.k-home-dialog-split-content {
	display:flex;
	margin:16px 12px 0 12px;
	line-height:1.4em;
}

.k-home-dialog-split-content-left {
	flex:0 0 50%;
	margin-right:12px;
	padding-top:8px;
}

.k-home-dialog-split-content-right {
	flex:0 0 50%; 
	border-left:1px solid #ccc;
	padding-left:12px;
	padding-top:8px;
}

.k-home-dialog-card-small {
	border-radius:0!important;

	.k-home-dialog-card {
		border-radius:0!important;
	}

	.k-home-dialog-social-btns { 
		visibility:hidden; 
	}

	.k-home-dialog-footer {
		border-radius:0!important;
	}

	.k-home-dialog-split-content {
		display:block;
		margin:0;
	}

	.k-home-dialog-split-content-left {
		margin-right:0;
		padding-top:0px;
	}

	.k-home-dialog-split-content-right { 
		border-left:0;
		padding-left:0px;
		padding-top:0px;
		border-top:1px solid #666;
		margin-top:8px;
	}
}
</style>

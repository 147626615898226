<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div>
	<div v-if="unit.standards.length==0" class="pa-2 text-center"><i>This unit does not have any standards specified.</i></div>
	<div class="mx-1 my-2" v-if="unit.standards.length>0">
		<div class="d-flex">
			<div class="k-unit-view-header ml-2">{{ standards_button_text }}</div>
			<v-spacer/>
			<v-btn-toggle v-if="assets_showing" dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="collection_view_mode" mandatory>
				<v-btn x-small light :value="'tiles'" @click.stop="">Tile View</v-btn>
				<v-btn x-small light :value="'list'" @click.stop="">List View</v-btn>
			</v-btn-toggle>
		</div>
		<div class="k-lp-unit-description mt-2 ml-3 mr-3">
			<div v-if="!standards_tree_loaded" class="pa-4" style="font-size:16px">Loading...</div>
			<div v-for="(o) in unit_standards_tree" class="k-lp-unit-standard" :style="standard_style(o)" :class="standard_class(o)" v-show="standard_showing[o.cfitem.identifier]">
				<div class="k-lp-unit-standard-statement-wrapper">
					<div style="flex:0 0 26px; margin:-1px 0 0 0;">
						<v-icon v-if="o.children.length>0" style="font-size:17px;" color="#666" @click.stop="toggle_children(o)">fa-fw fas {{standard_children_showing(o) ? 'fa-circle-chevron-down' : 'fa-circle-chevron-right'}}</v-icon>
						<v-icon v-else style="font-size:17px;" color="#666">fa-fw fas fa-circle-dot</v-icon>
					</div>
					<div class="k-lp-unit-standard-statement" @click.stop="o.children.length>0?toggle_children(o):show_standard_resources(o)"><b v-if="o.cfitem.humanCodingScheme" style="margin-right:6px" v-html="o.cfitem.humanCodingScheme"></b><span class="k-case-tree-item-statement" v-html="U.marked_latex(o.cfitem.fullStatement)"></span></div>
					<v-spacer/>
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" icon small color="secondary" style="margin:-2px 6px 0 4px" @click.stop="show_standard_in_satchel(o.cfitem)"><v-icon small>fas fa-map</v-icon></v-btn></template>Show in standards framework</v-tooltip>
					<div style="flex:0 0 88px" class="text-center"><v-btn x-small :disabled="!o.branch_assets.length" class="k-tight-btn k-nocaps-btn" color="secondary" :outlined="standard_resources_showing[o.cfitem.identifier]" @click.stop="show_standard_resources(o)">{{o.branch_assets.length}} {{U.ps('Resource', o.branch_assets.length)}}<v-icon small class="ml-1" :style="standard_resources_showing[o.cfitem.identifier]?'transform:rotate(180deg)':''">fas fa-caret-down</v-icon></v-btn></div>
				</div>
				<!-- note that we're relying on a lot of the CollectionResourceFolder styles here -->
				<div v-if="show_resources_list_for_standard(o)" class="k-lp-unit-standard-resources" :class="`k-resource-folder-${collection_view_mode}`" :style="o.branch_assets.length==0?'border-width:0!important':''">
					<!-- <div v-if="o.primary_assets.length>0" style="margin:8px 0; padding-left:8px; color:#444;"><b>Primary Alignments</b> ({{ o.primary_assets.length }})</div> -->
					<div v-if="o.primary_assets.length>0" class="k-resource-folder-resources-inner">
						<div v-for="(asset) in o.primary_assets" :key="asset.asset_type+'-'+asset.asset_id" :class="`k-resource-folder-resource-container-width-${collection_view_mode=='list'?'full':'half'}`"><div style="height:100%" :class="`k-resource-folder-resource-item-${collection_view_mode=='list'?'full':'half'}-wrapper`">
							<ResourceCollectionItem :item="asset.resource?asset.resource:asset.lesson" 
								:full_width_resource="collection_view_mode=='list'"
								@copy_to_shadow_unit_finish="$emit('copy_to_shadow_unit_finish', $event)"
								@edit_item_saved="edit_item_saved"
								@lesson_shift_update="$emit('lesson_shift_update',$event)"
							/>
						</div></div>
					</div>

					<div v-if="o.secondary_assets.length>0" :style="o.primary_assets.length>11110?'margin:16px 0 8px 0':'margin:8px 0'" style="margin:8px 0; padding-left:8px; color:#444;"><b>Secondary Alignments</b> ({{ o.secondary_assets.length }})<v-btn class="ml-3" x-small outlined color="primary" @click.stop="show_secondary_resources(o)">{{ standard_secondary_resources_showing[o.cfitem.identifier]?'Hide':'Show' }}<v-icon small class="ml-2">fas fa-caret-{{ standard_secondary_resources_showing[o.cfitem.identifier]?'up':'down' }}</v-icon></v-btn></div>
					<div v-if="o.secondary_assets.length>0" v-show="standard_secondary_resources_showing[o.cfitem.identifier]" class="k-resource-folder-resources-inner">
						<div v-for="(asset) in o.secondary_assets" :key="asset.asset_type+'-'+asset.asset_id" :class="`k-resource-folder-resource-container-width-${collection_view_mode=='list'?'full':'half'}`"><div style="height:100%" :class="`k-resource-folder-resource-item-${collection_view_mode=='list'?'full':'half'}-wrapper`">
							<ResourceCollectionItem :item="asset.resource?asset.resource:asset.lesson" 
								:full_width_resource="collection_view_mode=='list'"
								@copy_to_shadow_unit_finish="$emit('copy_to_shadow_unit_finish', $event)"
								@edit_item_saved="edit_item_saved"
								@lesson_shift_update="$emit('lesson_shift_update',$event)"
							/>
						</div></div>
					</div>
					<div v-if="o.direct_assets.length < o.branch_assets.length" style="flex:0 0 100%; cursor:pointer" class="text-center" :class="o.direct_assets.length>0?'my-2':''" @click.stop="toggle_children(o)"><i>See child standards for {{o.branch_assets.length-o.direct_assets.length}} {{o.direct_assets.length>0 ? 'additional ': ''}}content {{U.ps('item', o.branch_assets.length-o.direct_assets.length)}}</i><v-icon small class="ml-2">fas fa-caret-{{standard_children_showing(o)?'up':'down'}}</v-icon></div>
				</div>
			</div>
		</div>
	</div>
	<div v-if="user_can_edit_unit" class="text-center mt-4">
		<v-btn small class="k-edit-btn xk-tight-btn xk-nocaps-btn mx-1 mb-3 elevation-0" color="#ddd" @click="$emit('edit_unit')"><v-icon class="mr-2" small>fas fa-edit</v-icon>Edit Unit</v-btn>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ResourceCollectionItem from '../resources/ResourceCollectionItem'

export default {
	components: { ResourceCollectionItem },
	props: {
		lp: { type: Object, required: true },
		unit: { type: Object, required: true },
		is_collection_admin: { type: Boolean, required: true },
	},
	data() { return {
		standards_tree_loaded: false,
		max_level: 0,
		// tried_to_load_lsdoc_list: false,
		// tried_to_load_framework: false,
	}},
	computed: {
		...mapState(['user_info', 'my_lessons', 'my_resources', 'site_config']),
		...mapGetters(['immediately_show_standards_aligned_resources']),
		standards_button_text() { return this.site_config.unit_mode_toggle_option?.standards || 'Standards' },
		standard_showing() { return this.unit.standard_showing },
		standard_resources_showing() { return this.unit.standard_resources_showing },
		standard_secondary_resources_showing() { return this.unit.standard_secondary_resources_showing },
		is_default_collection() { return (this.lp.course_code == 'default') },
		user_can_edit_unit() {
			return (this.is_collection_admin && !this.is_default_collection)
		},
		unit_standards_tree() {
			// for our purposes in constructing asset lists, treat two assets as equivalent (and therefore only list one of them) if...
			let asset_already_in_list = (list, a2) => {
				for (let a1 of list) {
					if (a2.asset_type == 'lesson') {
						if (a1.asset_type != 'lesson') continue
						// for lessons, we'll just check the lesson_titles
						if (a1.lesson.lesson_title == a2.lesson.lesson_title) return true
					
					} else {
						if (a1.asset_type != 'resource') continue
						// for resources, check urls
						if (a1.resource.url == a2.resource.url) return true
					}
				}

				// if we get to here, the asset isn't already in the list
			}

			this.max_level = 0
			// Note: we used to load the framework from SuitCASE here; but as of 10/2024 that's no longer necessary, because we're keeping the standards in synch with SuitCASE on the back end
			let top_items = []

			// start with all items that aren't children of other items, in the order they were loaded
			let last_child_of = ''
			let all_top_level_are_siblings = true
			for (let standard of this.unit.standards) {
				if (empty(standard.childOf) || !this.unit.standards.find(x=>x.identifier == standard.childOf)) {
					top_items.push({standard:standard, children:[]})
					if (empty(last_child_of)) last_child_of = standard.childOf
					else if (standard.childOf != last_child_of) all_top_level_are_siblings = false
				}
			}

			// if top_items items are all children of the same item, order by sequenceNumber
			if (!empty(last_child_of) && all_top_level_are_siblings) {
				top_items.sort((a,b)=>a.standard.sequenceNumber - b.standard.sequenceNumber)
			}

			// recursive fn for creating a tree structure for the standards
			let add_descendents = (parent) => {
				for (let standard of this.unit.standards) {
					if (standard.childOf == parent.standard.identifier) {
						parent.children.push({standard:standard, children:[]})
					}
				}
				// sort by sequenceNumber
				parent.children.sort((a,b)=>a.standard.sequenceNumber - b.standard.sequenceNumber)

				// add descendents of children
				for (let child of parent.children) {
					add_descendents(child)
				}
			}

			// now for each item in top_items, add its descendents
			for (let o of top_items) {
				add_descendents(o)
			}

			let arr = []

			let add_item = (parent, level) => {
				// do some calculations while we're going through all the items here...
				if (level > this.max_level) this.max_level = level

				// reveal the first X levels of children
				let levels_to_show = 1
				if (this.standard_showing[parent.standard.identifier] === undefined) {
					this.$set(this.standard_showing, parent.standard.identifier, (level <= levels_to_show))
				}

				// reveal resources for all levels if immediately_show_standards_aligned_resources is true
				if (this.immediately_show_standards_aligned_resources == true) {
					if (this.standard_resources_showing[parent.standard.identifier] == undefined) this.$set(this.standard_resources_showing, parent.standard.identifier, true)
				}

				let direct_assets = []
				let primary_assets = []
				
				// if we've fully loaded the standards_aligned_assets, get lists of direct and primary assets, using the following algorithm (we'll construct the list of secondary_assets below)
				// if (this.unit.standards_aligned_assets_loaded) for (let x of this.unit.standards_aligned_assets) {
				if (this.unit.standards_aligned_assets_loaded) {
					for (let x of this.unit.standards_aligned_assets) {
						if (x.cfitem_identifier != parent.standard.identifier) continue

						// don't add duplicate items
						if (asset_already_in_list(direct_assets, x)) continue

						// if we've loaded the full assets, check for primary (i.e. non-secondary) assets (note that some resourses have 8 or more such alignments, and that for cureums besides ALEX, we didn't have a secondary option prior to 2025)
						if (this.unit.standards_aligned_assets_loaded) {
							if (!x.is_secondary) {
								primary_assets.push(x)
							}
						}
						// if we get to here include the item in direct_assets
						direct_assets.push(x)
					}
				}

				// construct the parent item object
				let p = {
					cfitem: parent.standard, 
					children: parent.children,
					level: level,
					direct_assets: direct_assets,
					primary_assets: primary_assets,
					secondary_assets: [],
					branch_assets: direct_assets.concat([])
				}
				arr.push(p)

				// add children
				for (let child of parent.children) {
					let cr = add_item(child, level+1)
					// add child's branch_assets to parent's branch_assets, but don't add the same asset twice
					for (let asset of cr.branch_assets) {
						if (!p.branch_assets.find(x=>x.asset_type==asset.asset_type && x.asset_id==asset.asset_id)) {
							p.branch_assets.push(asset)
						}
					}
				}

				// if standards_aligned_assets_loaded...
				if (this.unit.standards_aligned_assets_loaded) {
					// get the "secondary" alignments -- everything for the branch that isn't primary. this will include assets aligned to an item's children
					for (let a of p.direct_assets) {
						if (!p.primary_assets.includes(a)) p.secondary_assets.push(a)
					}

					let sort_fn = (a,b) => {
						// lessons, then sparkls, then other resources
						if (a.asset_type == 'lesson' && b.asset_type != 'lesson') return -1
						if (b.asset_type == 'lesson' && a.asset_type != 'lesson') return 1
						
						if (a.asset_type == 'sparkl' && b.asset_type != 'sparkl') return -1
						if (b.asset_type == 'sparkl' && a.asset_type != 'sparkl') return 1

						// then by name/description
						if (a.asset_type == 'lesson') {
							return U.natural_sort(a.lesson.lesson_title, b.lesson.lesson_title)
						} else {
							return U.natural_sort(a.resource.description, b.resource.description)
						}
					}
					
					p.primary_assets.sort(sort_fn)
					p.secondary_assets.sort(sort_fn)
				}
				
				return p
			}
			for (let item of top_items) add_item(item, 0)

			this.standards_tree_loaded = true
			return arr
		},
		collection_view_mode: {
			get() {
				if (this.lp.collection_type === 'pd') return 'list'
				return this.$store.state.lst.collection_view_mode
			},
			set(val) { this.$store.commit('lst_set', ['collection_view_mode', val]) }
		},
		assets_showing() {
			for (let item of this.unit_standards_tree) {
				if (item.branch_assets.length > 0) return true
			}
			return false
		},
	},
	watch: {
		unit: { immediate: true, handler() {
			// load the unit's standards_aligned_assets if needed
			if (!this.unit.standards_aligned_assets_loaded) {
				this.unit.get_standards_aligned_assets()
			}
		}}
	},
	created() {
	},
	mounted() {
		vapp.collection_unit_standards_component = this
	},
	methods: {
		standard_children_showing(o) {
			// if the item has children and one of them is showing, the item's children are showing
			return (o.children.length > 0 && this.standard_showing[o.children[0].standard.identifier])
		},

		toggle_children(o, val) {
			// by default we toggle, but allow val to be specified directly
			if (typeof(val) != 'boolean') val = !this.standard_children_showing(o)

			// if new val is false, hide children, as well as their grandchildren
			if (!val) {
				for (let child of o.children) {
					// have to recurse first here, before we change standard_showing for the child
					this.toggle_children(child, false)

					// for some reason extobj (which uses $.extend) works here, whereas object_copy (which uses JSON.stringify) does not -- object_copy(this.standard_showing) produces [] every time
					let ss = extobj(this.standard_showing)
					ss[child.standard.identifier] = false
					this.$store.commit('set', [this.unit, 'standard_showing', ss])
				}

			// else show children
			} else {
				let ss = extobj(this.standard_showing)
				for (let child of o.children) ss[child.standard.identifier] = true
				this.$store.commit('set', [this.unit, 'standard_showing', ss])
			}
		},

		standard_style(o) {
			let s = ''
			s += `padding-left:${8+o.level*20}px;`

			// if (this.max_level == 0) bcolors = ['#fff']
			// else if (this.max_level == 1) bcolors = ['#eee', '#fff']
			// else if (this.max_level == 2) bcolors = ['#ddd', '#eaeaea', '#fff',]
			// else bcolors = ['#ddd', '#eee', '#f8f8f8', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']
			// s += `background-color:${bcolors[o.level]};`

			return s
		},

		standard_class(o) {
			let bcolors
			if (this.max_level == 0) bcolors = [0]
			else if (this.max_level == 1) bcolors = [0, 4]
			else if (this.max_level == 2) bcolors = [0, 4, 5]
			else if (this.max_level == 3) bcolors = [0, 3, 4, 5]
			else if (this.max_level == 4) bcolors = [0, 2, 3, 4, 5]
			else if (this.max_level == 5) bcolors = [0, 2, 3, 4, 5, 6]
			else if (this.max_level == 6) bcolors = [0, 2, 3, 4, 5, 6, 7]
			else bcolors = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

			return `k-case-tree-item-type-color-${bcolors[o.level]}`
		},

		show_resources_list_for_standard(o) {
			// don't show (yet) if resources aren't yet fully loaded
			if (!this.unit.standards_aligned_assets_loaded) return false

			// don't show if we don't have any branch assets
			if (o.branch_assets.length == 0) return false
			
			// show if standard_resources_showing is true
			if (this.standard_resources_showing[o.cfitem.identifier]) return true

			return false
		},

		show_standard_in_satchel(case_item) {
			// show the framework, highlight this item, and send in selected_items and limit_to_selected_items if there
			let data = { 
				framework_identifier: case_item.framework_identifier, 
				item_identifier: case_item.identifier, 
				no_framework_reset: true,
			}

			if (!data.framework_identifier) {
				// if case_item doesn't have a framework_identifier, it was probably selected before we started recording this.
				// so trace up through parents looking for a case_framework_identifier value; we should find it in in CourseView
				let parent = this.$parent
				while (parent) {
					if (parent.case_framework_identifier) { data.framework_identifier = parent.case_framework_identifier; break; }
					parent = parent.$parent
				}
			}

			// add all unit standards as selected_items
			if (this.unit.standards.length > 0) {
				data.selected_items = []
				for (let standard of this.unit.standards) data.selected_items.push(standard.identifier)
				data.limit_to_selected_items = 'only'
			}

			vapp.$refs.satchel.execute('show').then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
				})
			})
		},

		show_secondary_resources(o) {
			let srs = object_copy(this.standard_secondary_resources_showing)
			srs[o.cfitem.identifier] = !srs[o.cfitem.identifier]
			this.$store.commit('set', [this.unit, 'standard_secondary_resources_showing', srs])
		},

		show_standard_resources(o) {
			// if we don't already have it, get the full list of assets for the unit
			if (!this.unit.standards_aligned_assets_loaded) {
				this.unit.get_standards_aligned_assets()
			}

			// reveal the resources for the standard
			let srs = object_copy(this.standard_resources_showing)
			srs[o.cfitem.identifier] = !srs[o.cfitem.identifier]
			this.$store.commit('set', [this.unit, 'standard_resources_showing', srs])

			// as soon as the assets are loaded from the service, they'll appear under each standard

			// if children have additional assets, show children
			if (o.branch_assets.length > o.direct_assets.length) this.toggle_children(o, true)
		},

		edit_item_saved(args) {
			// this is called from ResourceCollectionItem; see the original version of this fn in CollectionResourceFolder
			// args will include `type` and `updated_resource` or `updated_lesson`
			console.log('edit_item_saved (CollectionUnitStandards)', args)

			if (args.type == 'resource' || args.type == 'activity') {
				// note that sparkl activities are saved as resources
				let updated_resource = new Resource(args.updated_resource)

				// call update_content_item mutation to make sure the item's changes are reflected everywhere
				this.$store.commit('update_content_item', updated_resource)

			} else if (args.type == 'lesson') {
				let updated_lesson = args.updated_lesson

				// apply *_showing values from edited_lesson if we have it
				if (args.edited_lesson) {
					updated_lesson.resources_showing = args.edited_lesson.resources_showing
					updated_lesson.standards_showing = args.edited_lesson.standards_showing
					updated_lesson.student_description_showing = args.edited_lesson.student_description_showing
					for (let i = 0; i < args.edited_lesson.lesson_plan.length; ++i) {
						updated_lesson.lesson_plan[i].lc_showing = args.edited_lesson.lesson_plan[i].lc_showing
						updated_lesson.lesson_plan[i].lc_open_for_editing = args.edited_lesson.lesson_plan[i].lc_open_for_editing
					}
				}

				// call update_content_item mutation to make sure the item's changes are reflected everywhere
				let l = new Lesson(updated_lesson)
				this.$store.commit('update_content_item', l)
			}
		},
	}
}
</script>

<style lang="scss">
.k-lp-unit-standard {
	max-width:900px;
	margin:0 auto;
	padding-top:4px;
	padding-bottom:4px;
	padding-right:12px;
	font-size:15px;
	line-height:19px;
	border-top:1px solid #bbb;
	border-bottom:1px solid #bbb;
	margin-bottom:-1px;
}

.k-lp-unit-standard-top-category {
	font-weight:bold;
	color:$v-deep-orange-darken-4;
	margin-top:8px;
}

.k-lp-unit-standard-statement-wrapper {
	display:flex;
	align-items: flex-start;
}

.k-lp-unit-standard-statement {
	cursor:pointer;
}
.k-lp-unit-standard-statement:hover {
	text-decoration:underline;
}

.k-lp-unit-standard-resources {
	background-color:#fff;
	font-size:14px;
	margin-left: 16px;
	margin-right:-2px;

	.k-resource-folder-resource-container-width-half {
		padding:2px 4px;
	}
}

.k-lp-unit-standard-resources.k-resource-folder-list {
	border:1px solid #999;
	margin-top:4px;
	margin-bottom:4px;
	border-radius:6px;
	overflow:hidden;
}

// from Satchel
.k-case-tree-item-statement {
	// see also k-case-tree-item-association-title in CASEItemAssociations
	display:inline;
	p:first-of-type {
		display:inline;
	}

	ul, ol {
		margin-top:4px;
	}

	table {
		border-collapse:collapse;
		margin:4px 0;
	}
	th, td {
		border:1px solid #ddd;
		padding:4px;
	}
	th {
		background-color:#f8f8f8;
	}
	td {
		background-color:#fff;
	}
	code {
		// display:inline-block;
		background-color:transparent!important;	// vuetify wants to use a light gray background color
		padding:0 2px!important;
		font-size:0.92em;	// this makes it a little bigger than what it would be otherwise
	}
}

.k-lp-unit-standard-children {
	margin-left:32px;
	margin-bottom:8px;
}

// from Satchel
.k-case-tree-item-type-color-0 { background-color: $v-indigo-lighten-5; }
.k-case-tree-item-type-color-1 { background-color: $v-purple-lighten-5; }
.k-case-tree-item-type-color-2 { background-color: $v-blue-lighten-5; }
.k-case-tree-item-type-color-3 { background-color: $v-teal-lighten-5; }
.k-case-tree-item-type-color-4 { background-color: $v-lime-lighten-5; }
.k-case-tree-item-type-color-5 { background-color: $v-orange-lighten-5; }
.k-case-tree-item-type-color-6 { background-color: $v-brown-lighten-5; }
.k-case-tree-item-type-color-7 { background-color: $v-red-lighten-5; }
.k-case-tree-item-type-color-8 { background-color: $v-deep-purple-lighten-5; }
.k-case-tree-item-type-color-9 { background-color: $v-light-blue-lighten-5; }
.k-case-tree-item-type-color-10 { background-color: $v-green-lighten-5; }
.k-case-tree-item-type-color-11 { background-color: $v-yellow-lighten-5; }
.k-case-tree-item-type-color-12 { background-color: $v-deep-orange-lighten-5; }
</style>

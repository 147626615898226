<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<div v-if="filtered_origin_teacher_id" class="k-collections-view-wrapper-origin-teacher-header">
		Viewing Lessons and Activities for <span style="font-weight:900">{{ filtered_coteacher_course.origin_teacher_first_name }} {{ filtered_coteacher_course.origin_teacher_last_name }}</span>
			<v-btn class="ml-2 k-tight-btn" x-small color="#fff" @click="filter_coteaching_list(null)">
			<v-icon small class="mr-1">fas fa-xmark</v-icon> Cancel
		</v-btn>
	</div>

	<div :class="top_css_class" :style="top_css_style"><div class="k-collections-view-wrapper-inner">

		<h2 v-if="!show_collection_only" class="k-page-title d-flex align-center">
			<v-icon v-show="collection_icon" :style="collection_icon_css_class" class="mr-3" style="font-size:30px">fas {{collection_icon}}</v-icon>
			<span v-if="initialized==0">LOADING…</span>
			<span v-else v-html="page_title"></span>
			<b v-if="initialized&&collection.active=='no'&&is_collection_admin" class="ml-8 red--text">[Inactive]</b>
			<v-spacer/>

			<!-- <div v-if="collection.subscription_only" style="font-size:16px" class="mr-2">SUBSCRIPTION ONLY</div> -->

			<v-btn v-if="is_default_collection" color="secondary" class="k-tight-btn k-nocaps-btn" @click="create_new_mycollection"><v-icon small class="mr-2">fas fa-cubes-stacked</v-icon>Create a New Content Collection</v-btn>

			<div v-if="site_config.limit_courses_to_academic_year=='yes'&&collection.collection_type=='course'" class="k-course-view-academic-year ml-3 mr-1" :style="can_change_academic_year?'cursor:pointer':''" @click="change_academic_year">Academic Year<br>{{academic_year_display}}</div>

			<div v-if="block_mode_switch_available" class="k-course-view-term-mode-selector ml-3"><v-btn-toggle dense active-class="k-toggle-btn-active-class" v-model="term_mode" mandatory>
				<v-btn small class="k-tight-btn" light value="normal">Quarters</v-btn>
				<v-btn small class="k-tight-btn" light value="block">Blocks</v-btn>
			</v-btn-toggle></div>

			<v-menu v-if="show_coteachers" bottom right>
				<template v-slot:activator="{ on }">
					<v-btn class="ml-3 mr-3" v-on="on" small fab
						:color="filtered_origin_teacher_id ? 'pink accent-4' : 'pink accent-4'" dark>
						<v-icon>fas fa-person-chalkboard</v-icon>
					</v-btn>
				</template>
				<v-list dense>
					<v-list-item @click="manage_coteachers">
						<v-list-item-icon><v-icon small>fas fa-person-chalkboard</v-icon></v-list-item-icon>
						<v-list-item-title>Manage my co-teachers for this course</v-list-item-title>
					</v-list-item>

					<v-list-item v-show="coteaching_filter_list.length > 0" style="border-top:1px solid #999">
						<v-list-item-title>Show lessons & activities for origin teacher:</v-list-item-title>
					</v-list-item>
					<v-list-item v-for="coteaching_course in coteaching_filter_list"
						:key="coteaching_course.origin_teacher_user_id"
						@click="filter_coteaching_list(coteaching_course)">
						<v-list-item-title>
							<v-icon class="mr-2" small
								v-visible="coteaching_course.origin_teacher_user_id == filtered_origin_teacher_id">fas
								fa-circle-check</v-icon>
							{{ coteaching_course.origin_teacher_first_name }} {{
								coteaching_course.origin_teacher_last_name }}
						</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>

			<v-menu v-if="show_collection_menu" bottom right><template v-slot:activator="{on}"><v-btn v-on="on" class="ml-2" small fab color="#999" dark><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template><!-- v-if="course_removable||(course_addable&&$vuetify.breakpoint.xsOnly)||user_is_superuser" -->
				<v-list>
					<v-list-item @click="export_common_cartridge"><v-list-item-icon><v-icon small>fas fa-file-zipper</v-icon></v-list-item-icon><v-list-item-title>Export content from this collection…</v-list-item-title></v-list-item>
					<v-list-item v-if="course_removable" @click="remove_from_my_courses"><v-list-item-icon><v-icon small>fas fa-minus-circle</v-icon></v-list-item-icon><v-list-item-title>Remove from My Favorite Collections</v-list-item-title></v-list-item>
					<v-list-item v-if="course_addable" @click="add_to_my_courses"><v-list-item-icon><v-icon small>fas fa-plus-circle</v-icon></v-list-item-icon><v-list-item-title>Add to My Favorite Collections</v-list-item-title></v-list-item>
					<v-list-item v-if="is_default_collection" @click="view_archived=true"><v-list-item-icon><v-icon small>fas fa-archive</v-icon></v-list-item-icon><v-list-item-title>View archived Lessons and Resources</v-list-item-title></v-list-item>
					<v-list-item v-if="collection_is_shareable" @click="open_share_dialog"><v-list-item-icon><v-icon small>fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>{{is_course?'Share “My Content” for this collection with other users':'Share collection with other users…'}}</v-list-item-title></v-list-item>
					<v-list-item v-if="collection.subscription_only&&!collection_is_shareable"><v-list-item-icon><v-icon small>fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>This collection is available only to subscribed users</v-list-item-title></v-list-item>
					<!-- TODO: to be done by Mathew -->
					<!-- <v-list-item v-if="signed_in&&course_or_repo" @click=""><v-list-item-icon><v-icon small>fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Manage subscriptions to other users’ “My Content” for this collection</v-list-item-title></v-list-item> -->
					<v-list-item v-if="user_can_archive_collection" @click="archive_collection"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Remove this collection…</v-list-item-title></v-list-item>
					<v-list-item v-if="user_subscribed_to_collection" @click="remove_collection_subscription"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>“Unsubscribe” from this collection…</v-list-item-title></v-list-item>
					<v-list-item v-if="user_can_remove_edit_lock" @click="remove_edit_lock"><v-list-item-icon><v-icon small>fas fa-unlock</v-icon></v-list-item-icon><v-list-item-title>Remove “edit lock” for this collection</v-list-item-title></v-list-item>
					<v-list-item v-if="user_can_view_report" @click="todo_report_showing=true"><v-list-item-icon><v-icon small>fas fa-list-check</v-icon></v-list-item-icon><v-list-item-title>Show Resource Completion Report</v-list-item-title></v-list-item>
					<v-list-item v-if="show_school_admin_btn" @click="school_admin_interface_showing=!school_admin_interface_showing"><v-list-item-icon><v-icon small>fas fa-screwdriver-wrench</v-icon></v-list-item-icon><v-list-item-title>School Admin Tools</v-list-item-title></v-list-item>
				</v-list>
			</v-menu>
		</h2>

		<div v-if="initialized" v-show="!show_collection_editor">
			<div v-if="studentish_role&&teacher_display" class="k-course-view-teacher_display" v-html="teacher_display"></div>

			<div v-if="!show_collection_only&&collection.description" class="k-collections-collection-description mx-auto" :class="collection_descriptions_collapsed?'':'elevation-1'">
				<div v-if="!collection_descriptions_collapsed" class="fr-view white" :style="(true||collection_show_full_description_btn)?'border-radius:10px 10px 0 0;':'border-radius:10px;margin-bottom:8px;'">
					<div class="k-collections-collection-description-inner py-2 px-3" :style="collection_description_style" v-html="U.render_latex(collection.description)"></div>
					<div style="clear:both;padding-bottom:1px;"></div><!-- this prevents annoying extra margin at the bottom of the description div -->
				</div>
				<div class="d-flex" style="cursor:pointer;border-radius:0 0 8px 8px;" :style="collection_descriptions_collapsed?'':'border-top:1px solid #ccc'">
					<v-btn v-if="!collection_descriptions_collapsed&&collection_show_full_description_btn" small text color="secondary" style="border-radius:0 0 0 8px" @click="collection.full_description_showing=!collection.full_description_showing">Show {{collection.full_description_showing?'Less':'More'}}<v-icon small class="ml-2">fas fa-caret-{{collection.full_description_showing?'up':'down'}}</v-icon></v-btn>
					<v-spacer/>
					<v-btn v-if="!collection_descriptions_collapsed" small text color="secondary" class="k-tight-btn" style="border-radius:0 0 8px 0" @click="collection_descriptions_collapsed=!collection_descriptions_collapsed">Collapse Collection Descriptions<v-icon small class="ml-2">fas fa-xmark</v-icon></v-btn>
					<v-btn v-if="collection_descriptions_collapsed" small text color="secondary" class="k-tight-btn" style="border-radius:0 0 8px 0" @click="collection_descriptions_collapsed=!collection_descriptions_collapsed">Show Collection Descriptions<v-icon small class="ml-2">fas fa-caret-down</v-icon></v-btn>
				</div>
			</div>

			<div class="mt-2 mb-2 mx-1 d-flex align-center" v-if="!course_is_inactive_and_uneditable">
				<div class="d-flex align-center flex-wrap">
					<v-btn v-if="show_course_standards_btn" color="#fff" small class="mt-1 mr-2 elevation-0 k-tight-btn k-nocaps-btn" @click="show_course_standards"><v-icon small class="mr-1">fas fa-bullseye</v-icon> {{site_config.course_standards_btn_text}}</v-btn>
					<v-btn v-if="show_tcc_btn" v-for="(rc, index) in collection.resource_collections" :key="index" color="#fff" small class="mt-1 mr-2 elevation-0 k-tight-btn k-nocaps-btn" @click="show_resource_collection(rc)"><v-icon small class="mr-1">fas fa-book</v-icon>{{rc.description}}</v-btn>
					<v-btn v-if="show_tcc_btn&&ebook_resource" color="#fff" small class="mt-1 mr-2 elevation-0 k-tight-btn k-nocaps-btn" @click="open_ebook">Ebook: <span class="ml-1" v-html="ebook_resource.description"></span></v-btn>
					<v-btn v-if="show_pd_resources_btn" color="#fff" small class="mt-1 mr-2 elevation-0 k-tight-btn k-nocaps-btn" @click="pd_resources_showing=!pd_resources_showing"><v-icon small class="mr-1">fas fa-user-graduate</v-icon> PD Resources</v-btn>
					<v-btn v-if="show_alt_cureum_btn" color="#fff" small class="mt-1 mr-2 elevation-0 k-tight-btn k-nocaps-btn" @click="open_alt_cureum"><v-icon small class="mr-1">fas fa-box-archive</v-icon> {{site_config.course_alt_cureum_btn_text}}</v-btn>
					<v-tooltip bottom><template v-slot:activator="{on}"><div v-if="show_safari_btn" class="k-safari_btn mr-2 mt-1 elevation-0" v-on="on" @click="safari_search"><img src="./../../images/safari-search-logo.png"></div></template>Search SAFARI Montage<br>for additional resources</v-tooltip>
				</div>

				<v-spacer/>

				<div v-if="!lp_is_empty"><v-btn small outlined color="#000" class="k-search-initialize-btn" @click="initiate_search">{{ search_btn_text }}<div class="k-search-initialize-btn-icon-div"><v-icon small>fas fa-search</v-icon></div></v-btn></div>
			</div>

			<div v-if="collection_has_units&&!course_is_inactive_and_uneditable">
				<CollectionUnitsVertical v-if="collection.lp_layout=='tree'" ref="collection_units"
					:collection="collection" :unit_showing="unit_showing" :instance_units="instance_units"
					:unit_descriptions_collapsed="unit_descriptions_collapsed"
					:unit_show_full_description_btn="unit_show_full_description_btn" :unit_description_style="unit_description_style"
					:is_collection_admin="is_collection_admin"
					:filtered_origin_teacher_id="filtered_origin_teacher_id"
					@add_unit="add_unit" @toggle_unit="toggle_unit" 
					@get_instance_units="get_instance_units"
					@lesson_shift_update="lesson_shift_update"
				/>
				<CollectionUnitsHorizontal v-if="collection.lp_layout=='map'" ref="collection_units"
					:collection="collection" :unit_showing="unit_showing" :instance_units="instance_units"
					:unit_descriptions_collapsed="unit_descriptions_collapsed"
					:unit_show_full_description_btn="unit_show_full_description_btn" :unit_description_style="unit_description_style"
					:is_collection_admin="is_collection_admin" :term_mode="term_mode"
					:filtered_origin_teacher_id="filtered_origin_teacher_id"
					@add_unit="add_unit" @hide_unit="hide_unit" @show_unit="show_unit" 
					@get_instance_units="get_instance_units"
					@lesson_shift_update="lesson_shift_update"
				/>
			</div>
			<div v-if="course_is_inactive_and_uneditable" class="text-center mt-4"><i>This course’s learning progression is not yet available in {{site_config.app_name}}.</i></div>

			<div v-if="!collection_has_units&&!manage_assignments" class="text-center mt-5 mb-3">
				<i>This collection does not currently include any content.</i>
				<!-- if enable_sandbox_simulate_role_menu is on, show an additional message... -->
				<div v-if="site_config.enable_sandbox_simulate_role_menu=='yes'" class="mt-2"><b><i>Please see courses marked with an asterisk (*) for sample content collections.</i></b></div>
			</div>

			<div v-if="show_assignments_or_messages" class="mt-3">
				<div class="d-flex mt-4" v-if="manage_assignments">
					<v-spacer/>
					<v-btn-toggle class="k-course-view-toggle-btn elevation-1" active-class="k-course-view-unit-toggle-btn-active-class" v-model="collection_assignments_or_messages_mode" mandatory>
						<v-btn v-if="manage_assignments" style="width:184px" small class="k-tight-btn k-nocaps-btn" :value="'course_assignments'"><v-icon small class="mr-1" :color="(collection_assignments_or_messages_mode == 'course_assignments') ? '#fff' : '#888'">fas fa-shapes</v-icon>Course-Wide Materials</v-btn>
						<v-btn v-if="manage_assignments&&!lp_is_empty" style="width:224px" small class="k-tight-btn k-nocaps-btn" :value="'unit_assignments'"><v-icon small class="mr-1" :color="(collection_assignments_or_messages_mode == 'unit_assignments') ? '#fff' : '#888'">fas fa-bolt</v-icon>{{unit_assignments_header_text}}</v-btn>
						<v-btn v-if="use_message_center" style="width:184px" small class="k-tight-btn k-nocaps-btn" :value="'messages'"><v-icon small class="mr-1" :color="(collection_assignments_or_messages_mode == 'messages') ? '#fff' : '#888'">fas fa-comments</v-icon>{{ messages_button_text }}</v-btn>
					</v-btn-toggle>
					<v-spacer/>
				</div>
				<!-- Assignment	Center -->
				<div class="" v-if="manage_assignments && (collection_assignments_or_messages_mode=='unit_assignments' || collection_assignments_or_messages_mode=='course_assignments')">
					<DirectivesWrapper :lp="collection" :filtered_origin_teacher_id="filtered_origin_teacher_id" />
				</div>
				<div v-if="collection_assignments_or_messages_mode=='messages' && use_message_center">
					<MessagesView :course_code="course_code" />
				</div>
			</div>

			<div v-show="is_collection_admin&&!is_default_collection" class="mt-4 mb-1 text-right">
				<span v-if="collection.sparkl_origin_override" class="pr-3" style="font-size:14px">Sparkl origin override: {{collection.sparkl_origin_override}}</span>
				<v-btn small class="k-edit-btn elevation-0" color="#bbb" @click="edit_collection"><v-icon small class="mr-2">fas fa-edit</v-icon>Edit Collection</v-btn>
			</div>

		</div>
		<CollectionEdit v-if="show_collection_editor" :learning_progression="collection" @editor_cancel="cancel_collection_editing"></CollectionEdit>
		<CollectionManageSharing v-if="show_collection_share_dialog" :learning_progression="collection" :course_code="course_code" @cancel_share="close_share_dialog"></CollectionManageSharing>
		<PDResourceCollection v-if="pd_resources_showing" :lp="collection" @hide="pd_resources_showing=false" />
		<TodoReportCollection v-if="todo_report_showing" :collection="collection" @dialog_cancel="todo_report_showing=false" />
		<SchoolAdminInterface v-if="school_admin_interface_showing" :collection="collection" @dialog_cancel="school_admin_interface_showing=false" />
		<CollectionCartridgeExport v-if="cartridge_export_showing" :collection="collection"  @dialog_cancel="cartridge_export_showing=false" />
		<ArchiveViewer v-if="view_archived" @dialog_cancel="view_archived=false"/>
		<ResourceCollectionTree v-if="resource_collection_showing.description" :resource_collection="resource_collection_showing" @dialog_cancel="hide_resource_collection"/>
		<CoteacherDialog v-if="coteacher_dialog_showing" :course_code="course_code" @dialog_cancel="coteacher_dialog_showing=false" />
	</div></div>
</div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import CollectionUnitsVertical from './CollectionUnitsVertical'
import CollectionUnitsHorizontal from './CollectionUnitsHorizontal'
import CollectionEdit from './CollectionEdit'
import CollectionManageSharing from './CollectionManageSharing'
import PDResourceCollection from '../resources/PDResourceCollection'
import TodoReportCollection from '../reports/TodoReportCollection'
import SchoolAdminInterface from '../reports/SchoolAdminInterface'
import DirectivesWrapper from '../directives/DirectivesWrapper'
import CollectionCartridgeExport from './CollectionCartridgeExport'
import ResourceCollectionTree from './ResourceCollectionTree'
import ArchiveViewer from '../resources/ArchiveViewer'
import MessagesView from '../messages/MessagesView'
import CoteacherDialog from './CoteacherDialog'

export default {
	components: { CollectionUnitsVertical, CollectionUnitsHorizontal, CollectionEdit, CollectionManageSharing, PDResourceCollection, TodoReportCollection, SchoolAdminInterface, DirectivesWrapper, CollectionCartridgeExport, ArchiveViewer, ResourceCollectionTree, MessagesView, CoteacherDialog },
	props: {
		course_code: { type: String, required: true },
		unit_id: { type: String, required: true },
		collections_list: { type: Array, required: false, default() { return [] }},
		show_collection_only: { type: Boolean, required: false, default() { return false }},
		custom_class: { type: String, required: false, default() { return '' }},
	},
	data() { return {
		initialized: 0,
		// collection_min_description_height: 280,
		// unit_min_description_height: 280,
		unit_showing_val: null,
		unit_description_style: '',
		show_collection_editor: false,
		show_collection_share_dialog: false,
		show_unit_editor: false,
		unit_descriptions_collapsed: false,
		pd_resources_showing: false,
		resource_collection_showing: {},
		school_admin_interface_showing: false,
		todo_report_showing: false,
		term_mode: 'normal',
		cartridge_export_showing: false,
		view_archived: false,
		instance_units: {},
		instance_unit_showing: null,
		coteacher_dialog_showing: false,
		filtered_origin_teacher_id: null,
	}},
	computed: {
		...mapState(['user_info', 'all_courses', 'lp_edit_locked', 'my_lessons', 'my_resources', 'my_ca_mappings', 'site_config', 'my_coteachers', 'my_coteaching_courses']),
		...mapGetters(['academic_year_display', 'signed_in', 'role', 'system_role', 'studentish_role', 'user_is_principal_or_ap', 'use_term_mode', 'manage_assignments', 'use_message_center', 'small_screen', 'unread_message_count']),
		collection_min_description_height() {
			// if the collection description includes an image, set this to a larger value so there's space for the image
			if (this.collection.description && this.collection.description.includes('<img ')) return 280
			return 200
		},
		unit_min_description_height() {
			// if the unit description includes an image, set this to a larger value so there's space for the image
			if (this.unit_showing?.text &&this.unit_showing.text.includes('<img ')) return 280
			return 200
		},
		show_collection_menu() {
			if (this.studentish_role) return false
			return true
		},
		show_school_admin_btn() {
			return false
			// config option determines if this is ever available
			if (this.site_config.show_school_leader_tools == 'no') return false
			if (this.collection.shifted_for_lesson) return false	// don't show if we're shifted for a lesson
			if (this.is_default_collection) return false
			if (this.user_is_principal_or_ap) return true
			if (this.role == 'admin') return true
		},
		collection_id() {
			// for HenryConnects, the course_code is used as the collection_id
			return this.course_code
		},
		user_is_superuser() {
			return vapp.has_admin_right('su')
		},
		user_can_remove_edit_lock() {
			// superusers can remove edit locks for anything
			if (this.user_is_superuser) return true

			// for non-agency-sanctioned collections, any editor can remove the edit lock
			if (!this.collection.agency_sanctioned && this.is_collection_admin) return true
			return false
		},
		is_collection_admin() {
			if(this.collection_is_empty) return false
			if (this.initialized <= 0) return false

			if (this.is_default_collection) return true

			return this.collection.user_is_lp_admin()
		},
		user_subscribed_to_collection() {
			if (this.collection_is_empty || !this.collection.user_is_subscribed_to_lp) return false
			return this.collection.user_is_subscribed_to_lp()
		},
		course_or_repo() { return ['course','repo'].includes(this.collection.collection_type) },
		is_course() { return ['course'].includes(this.collection.collection_type) },
		is_repo() { return ['repo'].includes(this.collection.collection_type) },
		search_btn_text() {
			if (this.collection.collection_type == 'course') return 'Search course content'
			if (this.is_default_collection) return 'Search my content'
			return 'Search collection content'
		},
		collection_is_shareable() {
			if (!this.signed_in) return false
			if (this.is_default_collection) return false
			// for 'my' collections, or for shareable repos, admins of the collection can share it
			if (this.collection.collection_type == 'my' || (this.is_repo && this.collection.subscription_only)) {
				return this.collection.user_is_lp_admin()
			}
			return false
		},
		collection() {
			if (this.initialized <= 0) return {}

			// we always pull from all_courses here, not the user's individual courses
			let l = this.all_courses.find(o=>o.course_code == this.course_code)
			if (empty(l)) return {}
			return l
		},
		collection_icon() {
			if (this.collection.collection_type == 'course') return 'fas fa-chalkboard'
			else if (this.collection.collection_type == 'repo') return 'fas fa-diagram-project'
			else if (this.collection.collection_type == 'my') return 'fas fa-cubes-stacked'
			else if (this.collection.collection_type == 'pd') return 'fas fa-user-graduate'
			else return ''
		},
		collection_is_empty() {
			return Object.keys(this.collection).length === 0
		},
		is_default_collection() { 
			if (this.initialized <= 0) return false
			return (this.collection.course_code == 'default') 
		},
		page_title() {
			return sr('<b>$1</b>', this.collection.title)
		},
		collection_icon_css_class() {
			// return U.subject_tile_css(this.collection) + '-text'
			if (!this.initialized) return {}
			return U.collection_color_style(this.collection, 'text')
		},
		top_css_class() {
			let s = ''
			if (!this.show_collection_only) {
				s = 'k-collections-view-wrapper k-page-wrapper'
				// s += U.subject_tile_css(this.collection) + '-border'
			}
			if (this.unit_showing) s += ' k-collections-view-wrapper--unit-showing'

			// add custom_class (blank by default)
			s += ' ' + this.custom_class

			if (this.collection.shifted_for_lesson) s += ' k-collections-view-wrapper-shifted-for-lesson'
			if (this.filtered_origin_teacher_id) s += ' k-collections-view-wrapper-origin_teacher_activities'

			return s
		},
		top_css_style() {
			if (!this.initialized) return {}
			return U.collection_color_style(this.collection, 'border')
		},
		unit_showing() {
			if (empty(this.collection.units)) return

			// if we only have one unit, always show it
			if (this.collection.units.length == 1) {
				this.unit_showing_val = this.collection.units[0]
				return this.unit_showing_val
			}

			// If an instance unit has been selected, show it
			if (this.instance_unit_showing) {
				return this.instance_unit_showing
			}

			// unit_id either comes from the url via vuerouter, in which case the router will update it when necessary,
			// or it's sent in by the including component, in which case it'll be sent in as 0 and we'll manipulate unit_showing_val directly below

			// if unit_id is something other than 0, get the unit to show;
			// otherwise use the last unit showing (which could be null)
			if (this.unit_id != 0) {
				let u = this.collection.units.find(x=>x.lp_unit_id == this.unit_id)
				if (empty(u)) return null
				this.unit_showing_val = u
			}
			return this.unit_showing_val
		},
		lp_is_empty() {
			// if we don't know what lp this is coming from, assume the lp is *not* empty (??)
			if (empty(this.collection)) return false

			// return true if the course doesn't have any units or terms
			return ((!this.collection.terms || this.collection.terms.length == 0) && (!this.collection.units || this.collection.units.length == 0))
		},
		unit_assignments_header_text() {
			if (!this.manage_assignments) return ''
			if (this.role == 'parent') return 'Unit Activities'	// My Child’s 
			else if (this.role == 'student') return 'Unit Activities'	// My
			else return 'My Lessons & Activities: All Units'
		},
		collection_assignments_or_messages_mode: {
			// NOTE: possible values include 'unit_assignments', 'messages', or 'course_assignments' (with the latter value added after the first two were established)
			get() { 
				if (this.use_message_center && !this.manage_assignments) return 'messages'
				let val = this.$store.state.lst.collection_assignments_or_messages_mode
				if (this.manage_assignments && !this.use_message_center && val == 'messages') return 'course_assignments'
				if (val == 'unit_assignments' && this.lp_is_empty) return 'course_assignments'
				return val
			},
			set(val) { 
				this.$store.commit('lst_set', ['collection_assignments_or_messages_mode', val]) 
			}
		},
		show_coteachers() {
			// RULES:
			// 1. Only allow this for signed-in teachers
			// 2. Only allow this if the manage_assignments vuex getter is True
			// 3. Only allow this in Course collections (not 'my' collections, sandbox, etc)
			return this.signed_in && this.manage_assignments && this.user_is_teacher && this.collection.collection_type === 'course'
		},
		show_assignments_or_messages() {
			// only show assignments/messages for course collections
			if (this.collection.collection_type != 'course') return false

			if (!this.manage_assignments && !this.use_message_center) return false
			if (!this.signed_in) return false
			// don't show the course-wide my_content/messages area if a unit is showing
			if (this.unit_showing) return false
			// if we get to here show
			return true
			// (below is from HC original)
			// // if role is staff or admin, show
			// if (this.role=='staff' || this.role=='admin') return true
			// // or if manage_assignments is on, show
			// if (this.manage_assignments) return true
			// // if we get to here, don't show
			// return false
		},
		messages_button_text() {
			const n = this.unread_message_count(this.collection.course_code)
			return `Message Center${n ? ` (${n})` : ''}`
		},
		last_collections_viewed: {
			get() { return this.$store.state.lst.last_collections_viewed },
			set(val) { this.$store.commit('lst_set', ['last_collections_viewed', val]) }
		},
		case_framework_identifier() {
			if (this.collection.subject_case_identifier == 'none') return ''
			if (this.collection.subject_case_identifier) return this.collection.subject_case_identifier
			return this.$store.state.subjects[this.collection.subject]?.framework_identifier	// may return null
		},
		sis_course() { 
			// note that this returns the first-listed sis_classes record for this course_code. If this is a teacher that teaches both block and quarter classes, they may have two sis_classes records, but that's OK for our purposes here.
			return this.$store.state.sis_classes.find(x=>x.course_code==this.collection.course_code) 
		},
		is_sis_course() {
			return !empty(this.sis_course)
		},
		in_added_my_courses() {
			if (this.$store.state.added_my_courses.find(x=>x==this.collection.course_code)) return true
			return false
		},
		in_removed_my_courses() {
			if (this.$store.state.removed_my_courses.find(x=>x==this.collection.course_code)) return true
			return false
		},
		course_case_identifier() {
			return this.collection.course_case_identifier
		},
		subject_case_identifier() {
			return this.collection.subject_case_identifier == 'none' ? '' : this.collection.subject_case_identifier
		},
		course_addable() {
			return (this.in_removed_my_courses || (!this.is_sis_course && !this.in_added_my_courses))
		},
		course_removable() {
			return ((this.in_added_my_courses || this.is_sis_course) && !this.in_removed_my_courses)
		},
		collection_description_style() {
			// console.log('collection_description_style computed ', this.collection.full_description_height)
			if (!empty(this.collection.description)) {
				// initialize full_description_height if necessary
				if (this.collection.full_description_height == -1) {
					// if we haven't yet calculated the height, set to visibility:hidden so that the timeout below will run and set the height to a larger negative value; the computed will then re-run since full_description_height has been updated
					setTimeout(x=>{
						this.collection.full_description_height = -1 * $('.k-collections-collection-description-inner').height()
					}, 0)
					return 'height:auto; visibility:hidden;'

				} else if (this.collection.full_description_height < -0) {
					// now we know the full height; set the height to show the full description, then after a delay, set full_description_height to a positive value, which will cause the computed to run again and reduce the size if full_description_showing is false.
					setTimeout(x=>{
						this.collection.full_description_height *= -1
					}, 300)
					return `height:${this.collection.full_description_height*-1 + 16}px;`

				} else {
					// The result of all this will be an animation (because we have transition:height set in the stylesheet) where a long description starts by showing, then collapses to the min value
					// console.log('this.collection.full_description_height', this.collection.full_description_height, this.collection.full_description_showing)
					if (this.collection.full_description_showing) {
						return `height:${this.collection.full_description_height + 16}px;`
					} else if (this.collection.full_description_height < this.collection_min_description_height) {
						return `height:${this.collection.full_description_height + 16}px;`
					} else {
						return `height:${this.collection_min_description_height}px; overflow:hidden;`
					}
				}
			}
		},
		collection_show_full_description_btn() {
			// see code in initialize
			return (this.collection.full_description_height > this.collection_min_description_height)
		},
		unit_show_full_description_btn() {
			// see unit_showing watcher below
			if (!this.unit_showing) return false
			return (this.unit_showing.full_description_height > this.unit_min_description_height)
		},
		user_can_archive_collection() {
			// Assumptions: User can not delete default collection...
			if (this.is_default_collection) return false
			// ...User can only delete 'my' collection type...
			if (this.collection.collection_type !== 'my') return false
			// ...Only the collection *owner* can delete (not -- at least currently -- the collection admin or a superuser)...
			// if (this.user_is_superuser) return true
			// if (this.is_collection_admin) return true
			if (this.collection.owner_id == this.user_info.user_id) return true
			// ...otherwise...
			return false
		},
		collection_has_units() {
			return this.collection.units?.length > 0
		},
		collection_descriptions_collapsed: {
			get() { return this.$store.state.lst.collection_descriptions_collapsed },
			set(val) { this.$store.commit('lst_set', ['collection_descriptions_collapsed', val]) }
		},
		user_can_view_report() {
			// the "resource completion report" is only available for pd collections
			if (this.collection.collection_type != 'pd') return false
			return vapp.has_admin_right('pd_rep.any')
		},
		can_change_academic_year() {
			return this.is_collection_admin || (this.$store.state.allow_academic_year_change_for_all_staff && this.user_info.system_role == 'staff')
		},
		course_in_my_sis_courses() {	// was `course_in_my_courses`
			// some things should only be shown to family members/students if the course is included in my_courses
			return (this.$store.getters.my_sis_course_collections_all.findIndex(x=>x.course_code == this.collection.course_code) > -1)
		},
		show_course_standards_btn() {
			if (this.collection.shifted_for_lesson) return false	// don't show if we're shifted for a lesson
 			return !empty(this.course_case_identifier) || !empty(this.subject_case_identifier)
		},
		show_pd_resources_btn() {
			if (this.site_config.show_pd != 'yes') return false
			if (this.collection.shifted_for_lesson) return false	// don't show if we're shifted for a lesson
			if (this.collection.collection_type != 'course') return false
			if (!this.signed_in || this.studentish_role) return false
			if (!this.collection.pd_resources || this.collection.pd_resources.length == 0) return false
			return true
		},
		show_alt_cureum_btn() {
			if (this.site_config.show_course_alt_cureum_btn != 'yes') return false
			if (this.collection.shifted_for_lesson) return false	// don't show if we're shifted for a lesson
			if (this.collection.collection_type != 'course') return false
			if (!this.signed_in || this.studentish_role) return false
			return true
		},
		show_safari_btn() {
			if (this.site_config.show_safari_integration != 'yes') return false
			if (this.collection.shifted_for_lesson) return false	// don't show if we're shifted for a lesson
			if (this.collection.collection_type != 'course') return false
			if (!this.signed_in || this.studentish_role) return false
			return true
		},
		ebook_resource() {
			// ported from Henry; not sure if this will work...
			if (!this.collection.units) return null
			// if the course's units' resources include at least one ebook link, include the *last*-listed ebook as a link highlighted at the top of the course
			let er
			for (let u of this.collection.units) {
				if (!u.resources) continue
				for (let r of u.resources) {
					if (r.lp_category == 'course_ebook') {
						er = r
					}
				}
			}
			return er
		},
		show_tcc_btn() {
			// if the user is staff or higher, you can view the ebook resources
			if (!this.studentish_role) return true
			// for parents or students, if this is one of their courses, you can view ebook resources
			if (this.course_in_my_sis_courses) return true
			// if user's SYSTEM role is staff or admin, you're probably viewing as a teacher/student; allow to view ebook resources
			if (this.system_role == 'staff' || this.system_role == 'admin') return true

			return false
		},
		tcc_btn_text() {
		},
		teacher_display() {
			if (!this.studentish_role) return ''
			if (!this.is_sis_course) return ''

			// ported from HenryConnects; may need to be updated to generalize...
			let s = ''
			if (this.sis_course.teachers && this.sis_course.teachers.length > 0 && this.sis_course.teachers[0] && this.sis_course.teachers[0].length > 0) {
				// note teachers[0][0] format, which may be needed because of the complex course structure in IC
				let teacher = this.sis_course.teachers[0]
				if ($.isArray(teacher)) teacher = teacher[0]
				// console.log(teacher)
				s = teacher.givenName + ' ' + (teacher.middleName ? (teacher.middleName + ' ') : '') + teacher.familyName

				s += sr(' <a style="font-size:14px; font-weight:bold; margin-left:6px;" href="mailto:$1">$1</a>', teacher.email.toLowerCase())
			}

			if (s) return `<b>Teacher:</b> ${s}`
			return ''
		},
		block_mode_switch_available() {
			// only show for courses
			if (this.collection.collection_type != 'course') return false

			// if term mode isn't being used for this instance, don't show this
			if (!this.use_term_mode) return false

			// if this is a HS course and the user is an editor, always show the toggle
			if (U.grade_value(this.collection.grade_high) >= 9 && this.is_collection_admin) return true

			// if the signed-in user is teaching/enrolled in block or term classes *only*, set term_mode appropriately and don't show the switch
			let sis_classes = this.$store.state.sis_classes.filter(o => o.course_code == this.course_code)
			let format = ''
			for (let sis_class of sis_classes) {
				if (!format) {
					format = sis_class.format
				} else if (format != sis_class.format) {
					format = 'both'
					break
				}
			}

			if (format == 'both') {
				// user is teaching both formats; get last-selected format for this course
				this.term_mode = this.$store.state.lst.lp_format_showing[this.course_code+'']
				if (!this.term_mode) this.term_mode = 'normal'

				// return true so they see the switcher
				return true

			} else if (format) {
				this.term_mode = (format == 'B') ? 'block' : 'normal'
				// user is only using one mode, so don't show the switcher
				return false
				
			} else {
				// if the user isn't teaching/enrolled in the course, return true (show the block mode switch) iff grade_high is >= 9 (high school course), and get the last-selected format
				// user is teaching both formats; get last-selected format for this course
				this.term_mode = this.$store.state.lst.lp_format_showing[this.course_code+'']
				if (!this.term_mode) this.term_mode = 'normal'

				return (U.grade_value(this.collection.grade_high) >= 9)
			}
		},
		state_course_code() {
			// FOR HENRYCONNECTS
			// *most* of the state codes we need for inspire links are x*.xxxx
			// some of the state codes are, e.g., 23.0040000, which has too many 0's.
			// unfortunately the math codes in inpire are 23.xxxxx
			// but if there are more than 5 numbers after the decimal, it is definitely invalid (I think), so don't use it here
			let scc = this.collection.state_course_code
			if (scc && scc.search(/\.\d\d\d\d\d\d+/) > -1) scc = ''

			// 11/4/2024: some hacks to fix for Columbia SC demo -- courses taught by Frank ...
			if (scc == '45.0810') scc = '45.081'	// US History
			else if (this.collection.course_code == '6642') scc = '45.0150'	// Psychology
			else if (this.collection.course_code == '6512') scc = '45.0570'	// American Govt./Civics
			else if (this.collection.course_code == '6522') scc = '45.0711'	// World Geography
			return scc
			// 	return this.state_course_code	// could be empty
			// 	// return window.hc_to_state_course_code_mappings[this.course_code]
			// 	if (this.course_code == '6632') return '45.0610'	// PF & Econ
			// 	if (this.course_code == '1545') return '45.0060'	// SS 5
			// 	if (this.course_code == '1335') return '27.01400'	// Math 3
			// 	return ''
		},
		course_is_inactive_and_uneditable() {
			return (!this.lp_is_empty && !this.is_default_collection && this.collection.active == 'no' && !this.collection.user_can_view_lp())
		},

		// Coteacher related computed properties
		coteaching_filter_list() {
			const arr = this.my_coteaching_courses.filter(x=>x.course_code == this.course_code).reduce((acc, curr) => {
				const existing = acc.find(item => item.origin_teacher_user_id === curr.origin_teacher_user_id)
				if (!existing) {
					acc.push(curr)
				}
				return acc
			}, [])
			return arr
		},
		filter_on_user_id() {
			// if we are a coteacher and the filtered_origin_teacher_id has been set, filter on that
			// otherwise filter on user id
			if (this.user_is_coteacher_for_course && this.filtered_origin_teacher_id) return this.filtered_origin_teacher_id
			return this.user_info.user_id
		},
		filtered_coteacher_course() {
			if (this.filtered_origin_teacher_id === null) return null
			return this.my_coteaching_courses.find(c => c.origin_teacher_user_id == this.filtered_origin_teacher_id && c.course_code == this.course_code)
		},
		user_is_teacher() {
			return this.role == 'staff'
		},
		user_is_coteacher_for_course() {
			return this.my_coteaching_courses.length > 0 && this.my_coteaching_courses.some(course => course.course_code === this.course_code)
		},
	},
	watch: {
		unit_showing: { deep:true, immediate:true, handler() {
			// we have to use this watcher rigamarole instead of the simpler computed we use above for the collection description, because the unit changes depending on what the user chooses
			if (!this.unit_showing || !this.unit_showing.text) return

			// initialize full_description_height if necessary
			if (this.unit_showing.full_description_height == -1) {
				// if we haven't yet calculated the height, set to visibility:hidden and unit_descriptions_collapsed to false so that the timeout below will run and set the height to a larger negative value; the watcher will then re-run since full_description_height (which is part of unit_showing) has been updated
				this.unit_descriptions_collapsed = false
				let sfdh = () => {
					let fdh = (-1 * $('.k-collection-unit-description-inner').height())
					if (isNaN(fdh)) {
						// for some reason this happens sometimes; but returning here seems to be fine -- we get the full_description_height from another iteration or something
						// console.warn(`bad full_description_height for ${this.unit_showing.title}: ${$('.k-collection-unit-description-inner').height()}`)
						return
					// } else {
						// console.log(`got full_description_height for ${this.unit_showing.title}: ${$('.k-collection-unit-description-inner').height()}`)
					}
					this.unit_showing.full_description_height = fdh
					this.unit_descriptions_collapsed = this.$store.state.lst.unit_descriptions_collapsed
				}
				setTimeout(x=>sfdh(), 0)		// note that setTimeout-0 seems to work better than nextTick here
				this.unit_description_style = 'visibility:hidden; height:auto; '

			} else if (this.unit_showing.full_description_height < 0) {
				// now we know the full height; set the height to show the full description, then after a delay, set full_description_height to a positive value, which will cause the watcher to run again and reduce the size if full_description_showing is false.
				setTimeout(x=>{
					this.unit_showing.full_description_height *= -1
				}, 300)
				let h = this.unit_showing.full_description_height*-1
				if (h < this.unit_min_description_height) {
					this.unit_description_style = `height:auto;`
				} else {
					this.unit_description_style = `height:${h + 40}px;`
				}

			} else {
				// The result of all this will be an animation (because we have transition:height set in the stylesheet) where a long description starts by showing, then collapses to the min value
				if (this.unit_showing.full_description_showing) {
					// PW 4/18/2024: we used to use this first line, but that sometimes results in some of the description get cut off (I think when there is an image that isn't loaded when we do the height calculation); setting to height:auto seems to work fine
					// this.unit_description_style = `height:${this.unit_showing.full_description_height + 40}px;`
					this.unit_description_style = `height:auto;`
				} else if (this.unit_showing.full_description_height < this.unit_min_description_height) {
					// this.unit_description_style = `height:${this.unit_showing.full_description_height + 40}px;`
					this.unit_description_style = `height:auto;`
				} else {
					this.unit_description_style = `height:${this.unit_min_description_height}px;`
				}
			}
			// console.warn(`unit_showing: ${this.unit_showing.full_description_height} / ${this.unit_min_description_height} / ${this.unit_description_style}`)
		}},
		'$store.state.lst.unit_descriptions_collapsed'() { this.unit_descriptions_collapsed = this.$store.state.lst.unit_descriptions_collapsed },
		'$route.fullPath': {
			handler() {
				// add collection to last_collections_viewed, removing any at the end of the list -- but not if it's already in last_collections_viewed
				if (this.$route.fullPath.includes(`collection/${this.course_code}`)) {
					// if (!this.collections_list.find(x=>x.course_code == this.course_code)) {
					if (!this.last_collections_viewed.find(x=>x == this.course_code)) {
						// console.log('add to top bar: ' + this.course_code)
						let arr = this.last_collections_viewed.concat([])
						let index = arr.indexOf(this.course_code)
						if (index > -1) arr.splice(index, 1)
						arr.unshift(this.course_code)
						arr.splice(this.$store.state.n_collections_to_show_in_banner, 1000)
						this.last_collections_viewed = arr
					}
				}

				// whenever the url is updated, cancel any lesson shift that has happened
				this.lesson_shift_update({cancel_last: true})
			}, deep:true, immediate:true
		},
		term_mode() {
			// when term_mode is set, change lst value for the course
			let o = this.$store.state.lst.lp_format_showing
			o[this.course_code + ''] = this.term_mode
			this.$store.commit('lst_set', ['lp_format_showing', o])
		},
	},
	created() {
		vapp.collection_component = this
	},
	mounted() {
		this.initialize()
	},
	methods: {
		initialize() {
			// if we don't already have the full collection data for this course...
			// if (empty(this.collection.lp_id)) {
			if (!this.collection.fully_loaded) {
				// if title is empty and the user is an admin, we must be creating it now, so go right to the editor
				if (this.collection.title == '' && this.is_collection_admin) {
					++this.initialized
					this.edit_collection()
					return
				}

				// try to load it now
				this.$store.dispatch('get_learning_progression', this.course_code).then((found)=>{
					if (found == 'not_authenticated' || found == 'no_admin_rights') {
						vapp.go_to_home('home')
						return
					}

					if (!found) {
						if (this.all_courses.find(x=>x.course_code==this.course_code) && empty(this.collection.collection_type)) {
							// if we didn't get a full lp from the service, but the collection was in all_courses already, it is probably a sis_class that doesn't have a full LP specified,
							// so pretend it's fully loaded and fall through
							this.$store.commit('set', [this.collection, 'fully_loaded', true])
						} else {
							this.$alert(`The collection id (${this.course_code}) was not found.`)
							vapp.go_to_home('home')
							return
						}
					}

					this.$nextTick(()=>{
						this.initialize_finish()
					})

				}).catch((e)=>{
					console.log(e)
					this.$alert('error in get_collection?')
				})

			} else {
				this.initialize_finish()
			}
		},

		initialize_finish() {
			++this.initialized
			this.$nextTick(x=>{
				// we have to do these things on next tick so that the collection computed gets updated to reflect the actual collection we're viewing

				// unsigned-in users and studentish users can't visit pd or my collections, or subscription_only collections. We can't check this until now because only now do we have all the info about the collection
				if (this.studentish_role && (['pd','my'].includes(this.collection.collection_type) || this.collection.subscription_only)) {
					vapp.go_to_home('home')
					return
				}

				this.get_instance_units()
				this.create_folders_for_legacy_courses()
				this.move_course_guidance_items_to_unit_1()
				this.add_course_wide_resources_unit_for_inspire()
				this.open_folders_if_directed()
			})
		},

		create_folders_for_legacy_courses() {
			// this is only needed for certain instances
			if (!['HenryConnects', 'Inspire', 'ADEL', 'ALEX'].includes(this.site_config.app_name)) return

			// console.warn('create_folders_for_legacy_courses')
			if (this.is_default_collection) return
			if (this.lp_is_empty) return

			// this.collection might not have been computed in this component, so get lp directly from the store
			let this_collection = this.all_courses.find(o=>o.course_code == this.course_code)

			for (let this_unit of this_collection.units) {
				// if the unit doesn't have any resources or lessons, there's no legacy stuff to process
				// NO: this unit might not have any resources, but other units in the LP might.
				// if (this_unit.resources.length == 0 && this_unit.lessons.length == 0) continue

				// if the unit already has folder_assignments...
				if (this_unit.resource_tree.folder_assignments.length > 0) {
					// look for items that *don't* have folder_assignments; if found, put in the top folder
					for (let lesson of this_unit.lessons) {
						if (!this_unit.resource_tree.folder_assignments.find(x=>x.resource_id==lesson.lesson_id)) {
							console.log('adding lesson to top', lesson.lesson_title)
							this_unit.add_item_to_folder({item:lesson, parent_folder_id:'top'})
						}
					}
					for (let resource of this_unit.resources) {
						if (!this_unit.resource_tree.folder_assignments.find(x=>x.resource_id==resource.resource_id)) {
							console.log('adding resource to top', resource.description)
							this_unit.add_item_to_folder({item:resource, parent_folder_id:'top'})
						}
					}

					// then continue, because there are no other legacy things we need to process
					continue
				}

				// IF WE'RE HERE, THIS COLLECTION UNIT WAS CREATED PRIOR TO THE VERSION OF CUREUM WHERE WE ORGANIZE RESOURCES INTO FOLDERS,
				// SO TRY TO CREATE DEFAULT FOLDERS FOR THE CONTENT

				let create_folder = (arr, folder_title, folder_color, rcis, parent_folder_id, folder_id) => {
					// if we don't get parent_folder_id assume top
					if (empty(parent_folder_id)) parent_folder_id = 'top'

					// if we get a folder_id val, add collection/unit id; otherwise use null, which will generate a new folder_id
					if (!empty(folder_id)) folder_id = `${folder_id}-${this_collection.lp_id}-${this_unit.lp_unit_id}`

					return this_unit.create_resource_folder({folder_id:folder_id, title:folder_title, parent_folder_id:parent_folder_id, color:folder_color, rcis:rcis, items:arr})
				}

				// if this is a henry course...
				if (this.site_config.app_name == 'HenryConnects' && this_collection.collection_type == 'course') {
					// don't do this more than once
					if (this_unit.resource_tree.folders.find(x=>x.folder_id.includes('course_guidance'))) continue

					// if we're here, this is probably a "legacy" HENRY unit where items were originally separated into up to a bunch of "buckets" (see below); simulate those buckets as folders here
					// console.log('creating folders for HENRY unit...')

					let folders = {
						'leader_resources': {title:'Leader Resources', color:'brown', resources:[]},
						'course_guidance': {title:'Resources for Course Guidance', color:'cyan', cross_unit_key:'rcg', resources:[]},
						'unit_planning': {title:'Unit Planning Documents', color:'green', resources:[]},
						'tcc': {title:'tcc items', color:'indigo', resources:[], rcis: this_unit.resource_collection_inclusions},	// publisher resources -- transfer the unit's resource_collection_inclusions to the folder's rcis
						'assessments': {title:'Assessments', color:'pink', resources:[]},
						
						'template_lessons': {title:'Template Lesson Plans', color:'blue', resources:[]},
						'template_activities': {title:'Template Student Activities', color:'orange', resources:[]},

						'supplementals': {title:'Supplemental Resources', color:'blue-grey', resources:[]},
						'student_ese': {title:'Additional Student ESE Resources', color:'teal', resources:[]},
						'student_advanced': {title:'Additional Student Advanced Learning Resources', color:'purple', resources:[]},
						'additional_student': {title:'Additional Student Resources from Unit Planning Guides', color:'', resources:[]},
						'additional_teacher': {title:'Additional Teacher Resources from Unit Planning Guides', color:'', resources:[]},
					}

					// skip items with duplicate names
					let names = {}
					let included_resource_ids = []
					let duplicates = []
					for (let unit of this_collection.units) {
						// PW: we used to need to do this for other units to pull all the course guidance resources in; this is now handled by the other routine, so now we can just do the current folder
						if (unit != this_unit) continue
						for (let resource of unit.resources) {
							// we want to pull cross_unit resources, as well as all resources marked as course_guidance, from other units. so we include each unit's resources if they are from the current unit or they're cross-unit or they're course_guidance
							if (unit.lp_unit_id != this_unit.lp_unit_id) {
								// special case: if "learning targets" is in the title, put it in course guidance (and let it through)
								if (resource.description.search(/learning targets/i) > -1) {
									this.$store.commit('set', [resource, 'lp_category', 'course_guidance'])
								// let all course_guidance items through
								} else if (resource.lp_category == 'course_guidance') {
									// console.warn('course_guidance: ' + resource.description)
								// let all cross_unit items through, and put in course_guidance
								} else if (resource.cross_unit) {
									this.$store.commit('set', [resource, 'lp_category', 'course_guidance'])
								} else {
									continue
								}
							}

							if (names[resource.description]) {
								// console.log('      SKIPPING DUPLICATE', resource.description, resource.resource_id)
								if (!duplicates.find(x=>x.resource_id == resource.resource_id) && !included_resource_ids.includes(resource.resource_id)) duplicates.push(resource)
								continue
							}
							names[resource.description] = true
							included_resource_ids.push(resource.resource_id)

							if (resource.lp_category == 'course_guidance' || resource.description.search(/learning targets/i) > -1) {
								// console.log('   add ' + resource.description, this_unit.resources.findIndex(x=>x.resource_id == resource.resource_id))
								if (!folders.course_guidance.resources.find(x=>x.resource_id==resource.resource_id)) {
									folders.course_guidance.resources.push(resource)
									// if the resource wasn't already in this unit, add it
									if (this_unit.resources.findIndex(x=>x.resource_id == resource.resource_id) == -1) {
										// console.log('       adding to unit.resources: ' + resource.resource_id)
										this.$store.commit('set', [this_unit.resources, 'PUSH', resource])
									}
								}

							// special case: if "unit planning" is in the title, put it in unit planning
							} else if (resource.description.search(/unit planning/i) > -1) {
								folders.unit_planning.resources.push(resource)
								this.$store.commit('set', [resource, 'lp_category', 'unit_planning'])	// and set the category to unit_planning
							} else if (resource.lp_category == 'leader_resource') folders.leader_resources.resources.push(resource)
							else if (resource.lp_category == 'unit_planning') folders.unit_planning.resources.push(resource)
							else if (resource.type == 'assessment') folders.assessments.resources.push(resource)

							// any teacher_facing resources left over here go in additional_teacher
							else if (resource.teacher_facing) folders.additional_teacher.resources.push(resource)

							else if (resource.type == 'sparkl' && resource.description.indexOf('GAVS') == 0) folders.template_activities.resources.push(resource)

							// anything left are public (aka student-facing) resources
							else {
								if (resource.target_students == 'ese') folders.student_ese.resources.push(resource)
								else if (resource.target_students == 'adv') folders.student_advanced.resources.push(resource)
								else folders.additional_student.resources.push(resource)							
							}
						}
					}

					// remove duplicates from unit.resources
					for (let resource of duplicates) {
						let index = this_unit.resources.findIndex(x=>x.resource_id == resource.resource_id)
						if (index > -1) {
							// console.log('       removing from unit.resources: ' + resource.resource_id)
							this.$store.commit('set', [this_unit.resources, 'SPLICE', index])
						}
					}

					let supplemental_folder_id = null
					for (let key in folders) {
						let f = folders[key]
						// for the tcc folder...
						if (key == 'tcc') {
							// if we aren't including any tcc resources for this unit, skip it
							if (U.object_has_keys(f.rcis)) {
								// name the folder after the tcc collection
								for (let rcid in f.rcis) {
									let rc = this_collection.resource_collections.find(x=>x.resource_id==rcid)
									if (rc) {
										create_folder([], rc.description, f.color, f.rcis, null, key)
										break
									}
								}
							}

						// currently adding all folders even if they're empty; but we could decide later to skip empty ones
						} else if (true || f.resources.length > 0) {
							// sort resources by description (title), EXCEPT for template_activities (GAVS)
							if (key != 'template_activities') f.resources.sort((a,b) => { return U.natural_sort(a.description, b.description)})

							let parent_folder_id = supplemental_folder_id ?? 'top'
							let uf = create_folder(f.resources, f.title, f.color, null, parent_folder_id, key)
							if (key == 'supplementals') supplemental_folder_id = uf.folder_id
						}
					}

				} else if (['Inspire', 'ADEL', 'ALEX'].includes(this.site_config.app_name)) {
					// if we're here, this is probably a "legacy" INSPIRE unit where items were originally separated into up to 4 categories (see below); simulate those same categories here
					console.log(`creating folders for ${this.site_config.app_name} unit...`)
					let folder_count = 0, arr1 = [], arr2 = [], arr3 = [], arr4 = []
					for (let resource of this_unit.resources) { if (resource.lp_category == 'unit_planning') { arr1.push(resource); if (arr1.length == 1) ++folder_count} }
					for (let lesson of this_unit.lessons) { arr2.push(lesson); if (arr2.length == 1) ++folder_count }
					for (let resource of this_unit.resources) { if (resource.type == 'sparkl') { arr3.push(resource); if (arr3.length == 1) ++folder_count} }
					for (let resource of this_unit.resources) { if (!(resource.lp_category == 'unit_planning' || resource.type == 'sparkl')) { arr4.push(resource); if (arr4.length == 1) ++folder_count} }

					if (arr1.length > 0) create_folder(arr1, 'Planning Resources', 'cyan')
					if (arr2.length > 0) create_folder(arr2, 'Lesson Templates', 'blue')
					if (arr3.length > 0) create_folder(arr3, `${this.site_config.sparkl_app_name} Student Activities (BETA)`, 'purple')
					// if we have additional resources...
					if (arr4.length > 0) {
						// if we made other folders, put them in a folder
						if (folder_count > 1) {
							create_folder(arr4, `Additional Resources`, 'indigo')

						// otherwise just put them in the top folder
						} else {
							for (let r of arr4) this_unit.add_item_to_folder({item:r, parent_folder_id:'top'})
						}
					}
				}
			}
		},

		add_course_wide_resources_unit_for_inspire() {
			if (this.is_default_collection) return
			if (this.lp_is_empty) return

			// this.collection might not have been computed in this component, so get lp directly from the store
			let lp = this.all_courses.find(o=>o.course_code == this.course_code)

			// if this LP originally used the "map" view that had course_wide_resources and/or assessment_* resources separate, add a unit for those, with folders
			let cw_unit_title = ''
			let cw_unit = new LP_Unit({
				lp_unit_id: 9999999,
			})
			if (lp.course_wide_resources.length > 0) {
				cw_unit_title += 'Course-Wide'
				cw_unit.create_resource_folder({parent_folder_id:'top', title:'Course-Wide Resources', items:lp.course_wide_resources, color:'cyan'})
				cw_unit.resources = cw_unit.resources.concat(lp.course_wide_resources)
				this.$store.commit('set', [lp, 'course_wide_resources', []])
			}
			if (lp.assessment_resources_general.length > 0 || lp.assessment_resources_annotated_examples.length > 0 || lp.assessment_resources_sample_items.length > 0) {
				if (cw_unit_title) cw_unit_title += ' and Assessment'
				else cw_unit_title += 'Assessment'

				if (lp.assessment_resources_general.length) cw_unit.create_resource_folder({parent_folder_id:'top', title:'Assessment Guides', items:lp.assessment_resources_general, color:'pink'}) 
				if (lp.assessment_resources_annotated_examples.length) cw_unit.create_resource_folder({parent_folder_id:'top', title:'Annotated Example Assessment Items', items:lp.assessment_resources_annotated_examples, color:'pink'}) 
				if (lp.assessment_resources_sample_items.length) cw_unit.create_resource_folder({parent_folder_id:'top', title:'Sample Assessment Items', items:lp.assessment_resources_sample_items, color:'pink'}) 

				cw_unit.resources = cw_unit.resources.concat(lp.assessment_resources_general)
				cw_unit.resources = cw_unit.resources.concat(lp.assessment_resources_annotated_examples)
				cw_unit.resources = cw_unit.resources.concat(lp.assessment_resources_sample_items)

				this.$store.commit('set', [lp, 'assessment_resources_general', []])
				this.$store.commit('set', [lp, 'assessment_resources_annotated_examples', []])
				this.$store.commit('set', [lp, 'assessment_resources_sample_items', []])
			}

			if (cw_unit_title) {
				cw_unit.title = `${cw_unit_title} Resources`
				this.$store.commit('set', [lp.units, 'UNSHIFT', cw_unit])
			}
		},

		open_folders_if_directed() {
			// if site_config says to do so, open all folders for course collections
			if (this.site_config.open_folders_by_default_for_course_collections == 'yes' && this.is_course) {
				let o = this.$store.state.lst.collections_opened_folders
				for (let unit of this.collection.units) {
					for (let folder of unit.resource_tree.folders) {
						if (folder.folder_id != 'top') o[folder.folder_id] = 'open'
					}
				}
				this.$store.commit('set', ['collections_opened_folders', o])
			}
		},

		// 12/2/2024: this is the initialize fn for the new search method
		initiate_search() {
			vapp.initiate_search({
				caller_component: this,
				dialog_title: 'Search Collection Content',
				home_collection: this.collection,
				default_source: 'home_collection',
			})
		},

		hide_unit() {
			if (!empty(this.unit_showing)) {
				// if we're only showing the collection, we're not using the router, so just set unit_showing_val to null
				// instance_unit_showing also needs to be set to null here
				if (this.show_collection_only) {
					this.unit_showing_val = null
					this.instance_unit_showing = null

				} else {
					// otherwise use the router; when we hide the unit, we have to set unit_showing_val & instance_unit_showing to null, but after a tick to let unit_id get to 0
					this.$nextTick(()=> {
						this.instance_unit_showing = null
						this.unit_showing_val = null
					})
					let base = window.location.pathname.replace(/^.(\w+).*/, '$1')	// repo or course
					this.$router.push({ path: `/${base}/${this.collection_id}/0` })
				}
			}
		},

		show_unit(unit, flag) {
			if (empty(this.unit_showing) || unit != this.unit_showing) {
				// if we're only showing the collection, we're not using the router, so just set unit_showing_val to the unit
				if (this.show_collection_only) {
					this.unit_showing_val = unit
				} else {
					// If this is an instance unit, we store it in instance_unit_showing
					this.instance_unit_showing = unit.instance_unit_of !== 0 ? unit : null

					let base = window.location.pathname.replace(/^.(\w+).*/, '$1')	// repo or course
					this.$router.push({ path: `/${base}/${this.collection_id}/${unit.lp_unit_id}` })
				}
			}
			// if the user clicked on the "reveal search results" icon, show folders in the unit, so that the searched items will be visible
			if (flag == 'search') {
				setTimeout(x=>this.$refs.collection_units.$refs.collection_unit.open_all_folders())
			}
		},

		toggle_unit(unit) {
			if (!empty(this.unit_showing) && unit == this.unit_showing) {
				this.hide_unit()
			}
			else {
				// If this is neither a flex unit or an instance unit, just show it as normal
				if (!unit.is_flex_unit && unit.instance_unit_of == 0) {
					this.show_unit(unit)
					return
				}
				// If it is a flex unit or an instance unit....
				let unit_to_show = unit
				const flex_unit_id = unit.is_flex_unit ? unit.lp_unit_id : unit.instance_unit_of
				const default_instance_unit_id = this.$store.state.lst.default_instance_unit[flex_unit_id] ?? null
				if (default_instance_unit_id && default_instance_unit_id !== flex_unit_id) {
					const default_instance_unit = this.instance_units[`${flex_unit_id}`]?.find((u) => u.lp_unit_id === default_instance_unit_id) ?? null
					if (default_instance_unit) unit_to_show = new LP_Unit(default_instance_unit)
				}
				this.show_unit(unit_to_show)
			}
		},

		add_unit() {
			this.edit_collection()
			this.$inform({text:'Add a new unit using the lavender “Units” section at the bottom of the <nobr>Content Collection Editor.</nobr>', color:'purple darken-3', snackbarY:'top', snackbarTimeout:10})
		},

		edit_collection() {
			// if the lp is already saved (i.e. it isn't brand new), we have to request to edit it, to make sure no one else already has it opened for editing
			if (!empty(this.collection.lp_id) && this.collection.lp_id != 0) {
				this.$store.dispatch('edit_access_control', {lp_id: this.collection.lp_id, lp_updated_at: this.collection.updated_at, action: 'checkout'}).then((result)=>{
					console.log('edit request: ' + result.status)
					// flag that we've requested to edit the LP; once the edit lock is aquired show lp_editor
					// we have to delay this a bit in case the unit has to first be hidden
					this.show_collection_editor = true
				}).catch((e)=>{
					// if this doesn't work, don't enter edit mode
					console.log('error requesting editor checkout for collection', e)
				})
			} else {
				// in this case it is a brand new lp, so no edit lock to create
				this.show_collection_editor = true
			}
		},

		cancel_collection_editing() {
			// when editing is canceled (including after a save), clear the edit lock
			// note that edit_access_control_checkin is called separately when editing a unit (CourseUnit) 
			this.$store.dispatch('edit_access_control_checkin', {lp_id: this.collection.lp_id})
			this.show_collection_editor = false
		},

		remove_edit_lock() {
			this.$store.dispatch('edit_access_control', {lp_id: this.collection.lp_id, action: 'checkin'}).then((result)=>{
				if (result.status == 'ok') {
					this.$alert('Edit lock removed.')
				} else {
					this.$alert('A problem occurred when attempting to remove the edit lock: ' + result.status)
				}
			}).catch((e)=>{
				console.log(e)
				this.$alert('A problem occurred when attempting to remove the edit lock.')
			})
		},

		show_course_standards() {
			let data = {framework_identifier:this.case_framework_identifier}
			// if we have a course_case_identifier...
			if (this.course_case_identifier) {
				// have that item open and selected, and (initially) limit to showing just that item and its descendents
				data.item_identifier = this.course_case_identifier
				data.selected_items = [this.course_case_identifier]
				data.limit_to_selected_items = 'children'
				data.no_framework_reset = true
			}

			vapp.$refs.satchel.execute('show').then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
				})
			})
		},

		show_resource_collection(rc) {
			this.resource_collection_showing = rc
		},

		hide_resource_collection() {
			this.resource_collection_showing = {}
		},
		
		open_ebook() {
			// TODO: brought in from HenryConnects; need to test/make this work
			// open the top ebook link using the same method we use to open resources (ResourceLink.vue)
			let payload = {resource_id: this.ebook_resource.resource_id, get_lti_form: 'yes'}
			this.$store.dispatch('get_resource_record', payload).then(result=>{
				// if we got back an lti_form, launch
				if (!empty(result.lti_form)) {
					// for the lti_form we open a new window and write out the form, which submits itself
					// see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
					let w = window.open()
					w.document.write(result.lti_form)
				} else {
					// else we just open the new window to the resource's url
					window.open(this.ebook_resource.url, '_blank')
				}
			})
		},

		open_alt_cureum() {
			// construct the url for this course in the external cureum (e.g. inspire)
			let url = `${this.site_config.external_cureum_origin}/course/${this.state_course_code}/0`
			// if we're not able to sso in, go to the login page -- if the user can't login, we won't show them the "Import to HenryConnects" option
			let no_sso_url = `${url}?login`
			vapp.open_cureum_in_new_window(url, no_sso_url)
		},

		safari_search() {
			// TODO: brought in from HenryConnects; need to test/make this work
			let o = {
				tool: 'safari',
				endpoint: this.$store.state.site_config.safari_montage_lti_endpoint,
				search_params: {}
			}

			// send grade low/high
			if (!empty(this.collection.grade_low)) o.search_params.fromgrade = this.collection.grade_low
			if (!empty(this.collection.grade_high)) o.search_params.tograde = this.collection.grade_high

			// send course CASE identifier?
			// not doing this currently for Henry, because they don't seem to have any resources available...
			// if (!empty(this.course_case_identifier)) {
			// 	o.search_params.standardsparentguid = this.course_case_identifier
			// } else if (!empty(this.case_framework_identifier)) {
			// 	o.search_params.standardsparentguid = this.case_framework_identifier
			// }

			console.log(o)
			this.$store.dispatch('lti_launch', o)

			// this.$store.dispatch('lti_launch', { tool: 'safari', endpoint: 'https://montage.henry.k12.ga.us/SAFARI/api/imslti.php' })
		},

		add_to_my_courses() {
			this.$store.dispatch('update_my_courses', {course_code:this.course_code, action:'add'}).then(()=>{
				this.$alert(sr('“$1” has been added to your Favorite Collections. If you wish to remove the course in the future, click the “three-dot” button in the upper-right corner of the interface.', this.collection.title))
			})
		},

		remove_from_my_courses() {
			this.$store.dispatch('update_my_courses', {course_code:this.course_code, action:'remove'})
		},

		change_academic_year() {
			// if ths user is an admin for the LP, allow them to click the Academic Year display to change years (also special case for staff trainings)
			if (!this.can_change_academic_year) return
			vapp.change_academic_year()
		},

		archive_collection() {
			this.$confirm({
				text: 'Are you sure you want to remove this collection?',
				acceptText: 'Remove Collection',
			}).then(y => {
				this.$store.dispatch('archive_learning_progression', { course_code: this.course_code }).then((result) => {
					if (result.status == 'ok') {
						this.$router.push('/mycollections');
					} else {
						this.$alert('A problem occurred when attempting to remove the collection: ' + result.status)
					}
				}).catch((e) => {
					console.log(e)
					this.$alert('A problem occurred when attempting to remove the collection.')
				})
			}).catch(n=>{}).finally(f=>{});
		},

		remove_collection_subscription() {
			this.$confirm({
				text: 'Are you sure you want to unsubscribe from this collection?',
				acceptText: 'Unsubscribe',
			}).then(y => {
				const is_repo = this.is_repo
				this.$store.dispatch('unsubscribe_from_shared_collection', this.collection.course_code).then((result) => {
					this.$inform('You have been unsubscribed.')
					if (is_repo) this.$router.push('/repos');
					else this.$router.push('/mycollections');
				})
			}).catch(n=>{}).finally(f=>{});
		},

		open_share_dialog() {
			this.show_collection_share_dialog = true
		},

		close_share_dialog() {
			this.show_collection_share_dialog = false
		},

		export_common_cartridge() {
			this.cartridge_export_showing = true
		},

		show_my_content() {
			this.$alert('This will show a list of course content the user has adapted for their own use.')
		},

		create_new_mycollection() {
			// if we're here, it means that the user opened their window right to the default collection, then clicked to create a new collection; set state.create_my_collection to true, then open the MyCollectionsIndex path
			this.$store.commit('set', ['create_my_collection', true])
			this.$router.push({ path: '/mycollections' })
		},

		// when viewing a collection, we allow for lessons to be "shifted" over to the right so that the user can view other resources in a unit. this fn shifts the collection over to the left when this happens
		lesson_shift_update(params) {
			// if params.cancel_last is true, we're clearing things out from a previous shift (e.g. when the user shifts to another unit)
			if (params.cancel_last == true) {
				if (this.collection.shifted_for_lesson && this.collection.shifted_for_lesson.close_lesson) {
					this.collection.shifted_for_lesson.close_lesson()
				}
				this.$store.commit('set', [this.collection, 'shifted_for_lesson', false])
			} else {
				// else store params.shifted_for_lesson in this.collection; this can be false if no lesson is shifted, or a reference to the lesson's ResourceCollectionItem if it *is* shifted
				this.$store.commit('set', [this.collection, 'shifted_for_lesson', params.shifted_for_lesson])
			}
		},

		get_instance_units() {
			if (this.signed_in && this.collection.units) {
				// PW: we shouldn't use processing cycles/server bandwidth doing this if the collection doesn't have any flex units
				let has_flex_unit = false
				for (let unit of this.collection.units) {
					if (unit.is_flex_unit) {
						has_flex_unit = true
						break
					}
				}
				if (!has_flex_unit) return

				U.ajax('get_instance_units', {user_id: this.user_info.user_id, lp_id: this.collection.lp_id}, res => {
					this.instance_units = res.instance_units

					// If we are viewing a instance unit currently, we need to update the instance_unit_showing data object manually here
					// TODO: It would be nice if instance_unit_showing were a reactive computed property instead... But a bit tricky.
					if (this.instance_unit_showing) {
						const flex_unit_id = this.instance_unit_showing.instance_unit_of
						if (flex_unit_id in this.instance_units) {
							const instance_to_update = this.instance_units[flex_unit_id]?.find(i => i.lp_unit_id == this.unit_id) ?? null
							if (instance_to_update) this.instance_unit_showing = new LP_Unit(instance_to_update)
						}
					}
				})
			}
		},

		manage_coteachers() {
			this.coteacher_dialog_showing = true
		},

		// called when user clicks an origin teacher to view activities for that teacher, or when they click to cancel viewing origin teacher activities
		filter_coteaching_list(coteaching_course) {
			if (coteaching_course == null || coteaching_course.origin_teacher_user_id == this.filtered_origin_teacher_id) {
				this.filtered_origin_teacher_id = this.filtered_origin_teacher_name = null
				this.$store.commit('set', [this.collection, 'viewing_origin_teacher_activities', false])
			} else {
				this.filtered_origin_teacher_id = coteaching_course.origin_teacher_user_id
				this.$store.commit('set', [this.collection, 'viewing_origin_teacher_activities', true])
			}
		},
		move_course_guidance_items_to_unit_1() {
			// get all items in other units and put in unit 1
			if (this.is_default_collection || this.lp_is_empty) return

			const units = this.collection.units
			if (units.length < 1) return

			// const lessons_in_unit = units[0].lessons.reduce((a, v) => { a[v.lesson_id] = v; return a; }, {})
			const unit_1_course_guidance_folder = units[0].resource_tree.folders.find(({folder_id}) => folder_id.startsWith('course_guidance'))
			if (!unit_1_course_guidance_folder) return

			// get a list of all item_ids (resources and lessons) from the unit 1 course guidance folder and its descendants
			let unit_1_course_guidance_item_ids = units[0].items_in_folder_recursive(unit_1_course_guidance_folder.folder_id)
			// then go from there to a list of corresponding resource/lesson objects
			let unit_1_course_guidance_resources = units[0].resources.filter(x=>unit_1_course_guidance_item_ids.includes(x.resource_id))
			let unit_1_course_guidance_lessons = units[0].lessons.filter(x=>unit_1_course_guidance_item_ids.includes(x.resource_id))

			// For each unit in this collection, except the first unit
			for (const unit of units.slice(1)) {
				// Get the course guidance folder for this (non-first) unit. note that we assume non-unit-1 folders don't have subfolders
				const unit_course_guidance_folder = unit.resource_tree.folders.find(folder => folder.folder_id.startsWith('course_guidance') && folder.children.length > 0)
				if (unit_course_guidance_folder) {
					// get a list of all item_ids (resources and lessons) from *this* unit's course guidance folder and its descendants
					let unit_course_guidance_item_ids = unit.items_in_folder_recursive(unit_course_guidance_folder.folder_id)

					// For each child in the course_guidance_folder
					for (const child_id of unit_course_guidance_item_ids) {
						if (isNaN(child_id*1)) {	// resources have guids as ids
							// find the resource
							const index = unit.resources.findIndex(x=>x.resource_id == child_id)
							if (index == -1) {
								console.warn('Bad stuff in move_course_guidance_items_to_unit_1: ' + child_id)
								continue
							}
							let this_resource = unit.resources[index]

							// Remove the resource from the unit
							unit.resources.splice(index, 1)

							// unless a resource with this id or name already exists in the unit 1 course guidance folder...
							if (unit_1_course_guidance_resources.find(x=>x.resource_id == child_id || x.description == this_resource.description)) {
								// console.log('duplicate: ' + this_resource.description)
							} else {
								// remove the item from unit 1, in case it already existed somewhere else in the unit
								// console.log(units[0].resources.length, units[0].resource_tree.folder_assignments.length)
								units[0].remove_item(this_resource)
								// console.log(units[0].resources.length, units[0].resource_tree.folder_assignments.length)
							
								// now add it back to the unit 1 course_guidance folder
								units[0].resources.push(this_resource)
								units[0].add_item_to_folder({item:this_resource, parent_folder_id:unit_1_course_guidance_folder.folder_id, type: 'resource'})

								// console.warn(`added ${child_id} / ${this_resource.description}`)
								unit_1_course_guidance_resources.push(this_resource)
							}
							
						// Do the same if the child is a lesson
						} else {
							const index = unit.lessons.findIndex(x=>x.lesson_id == child_id)
							if (index == -1) {
								console.warn('Bad stuff in move_course_guidance_items_to_unit_1: ' + child_id)
								continue
							}
							let this_lesson = unit.lessons[index]

							// Remove the lesson from the unit
							unit.lessons.splice(index, 1)

							// unless a lesson with this id or name already exists in unit 1...
							if (unit_1_course_guidance_lessons.find(x=>x.lesson_id == child_id || x.description == this_lesson.lesson_title)) {
								// console.log('duplicate: ' + this_lesson.lesson_title)
							} else {
								// remove the item from unit 1, in case it already existed somewhere else in the unit
								units[0].remove_item(this_lesson)
							
								// now add it back to the unit 1 course_guidance folder
								units[0].lessons.push(this_lesson)
								units[0].add_item_to_folder({item:this_lesson, parent_folder_id:unit_1_course_guidance_folder.folder_id, type: 'lesson'})

								// console.warn(`added ${child_id} / ${this_lesson.lesson_title}`)
								unit_1_course_guidance_lessons.push(this_resource)
							}
						}
						// remove this resource or lesson's folder_assignment from the (non-first) unit
						let i = unit.resource_tree.folder_assignments.findIndex(x => x.resource_id == child_id)
						if (i > -1) {
							unit.resource_tree.folder_assignments.splice(i, 1)
						}
					}
					// Remove the children, it will regenerate with course_guidance_items()
					this.$store.commit('set', [unit_course_guidance_folder, 'children', []])
				}
			}
		}
	}
}


</script>

<style lang="scss">
.k-collections-view-wrapper {
	margin-top:20px!important;
	border-top:5px solid transparent;
	border-bottom:5px solid transparent;

	.k-page-title {
		margin-bottom:0;
	}

	.k-safari_btn {
		height:28px;
		display:inline-block;
	    cursor: pointer;
	    background-color: #fff;
	    border-radius: 10px;
	    padding: 3px 16px;
	    vertical-align: middle;
		img {
			height: 24px;
		}
	}
}
.k-collections-view-wrapper-origin_teacher_activities {
	padding-top:42px!important;
	background-color:rgba(252,228,236,0.9)!important;
	.k-cvlp-unit {
		background-color:$v-pink-lighten-5!important;
	}
}
.k-collections-view-wrapper-shifted-for-lesson {
	width: 50vw;
    margin-left: 0!important;
    padding-left: 8px!important;
    padding-right: 8px!important;
}

.k-collections-view-wrapper-inner {
	max-width: 1400px;
	margin:0 auto;

	.k-unit-view-header {
		// display:none;
		border:0;
		margin:0 auto;
		padding:0 0 0 4px;
		font-size:18px;
		font-weight:bold;
		color:#666;
	}
}

.k-collections-view-wrapper-origin-teacher-header {
	position:absolute;
	left:0;
	background-color:$v-pink-accent-4;
	color:#fff;
	width:100vw;
	text-align:center;
	font-weight:bold;
	font-size:18px;
	line-height:18px;
	padding:6px 0;
	// border-top:2px solid #000;
	border-bottom:1px solid #000;
}

.k-collections-collection-description {
	border:1px solid #ccc;
	background-color:#f2f2f2;
	color:#000;
	// max-width:800px;
	margin:20px auto 0 auto;
	// padding:4px 16px;
	border-radius:10px;
	font-size:16px;
	max-width:800px;

	.fr-view table td, .fr-view table th { 
		padding:4px;
	}
}

.k-collections-collection-description-inner {
	transition:height 0.25s;
}

.k-course-view-teacher_display {
	font-size:16px;
	line-height:19px;
	text-align:center;
	margin-top:8px;
}

.k-course-view-academic-year {
	color:$v-grey-darken-3;
	text-align:center;
	font-size:12px;
	line-height:16px;
	font-weight:normal;
}

.k-course-view-term-mode-selector {
	margin-top:-8px;
	.v-btn {
		width:72px;
	}
}

.k-collection-search-input {
	.v-input__slot, .v-input__control { min-height:24px!important; }
	.v-input__slot { padding-left:8px!important; padding-right:8px!important; }
	.fa-search:before { font-size:16px!important; }
}
.k-collection-search-input-focused {
	.v-input__slot, .v-input__control { min-height:40px!important; }
}

.k-course-view-unit-toggle-btn-active-class {
	background-color:#333!important;
	color:#fff!important;
}
</style>

<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template><div>
	<div class="k-learning-progression-table-wrapper-outer"><div class="k-learning-progression-table-wrapper" ><table class="k-learning-progression" :class="unit_showing?'k-learning-progression--unit-showing':''">
		<tr v-if="lp.use_terms&&terms.length>1">
			<td v-for="(term, i) in terms" :key="i" class="k-learning-progression-term-number" :class="term_css_class[i]" :colspan="term_colspan[i]" :style="[term_style[i], U.collection_color_style(lp,false)]">
				<div :style="{'color': U.get_contrast_color(lp.color)}" v-html="term_title_display(term.title)"></div>
			</td>
		</tr>
		<tr v-else><td :style="lp_css_class" class="k-learning-progression-term-number k-learning-progression-term-number--first k-learning-progression-term-number--last" :colspan="term_colspan"><div :style="{'color': U.get_contrast_color(lp.color)}" v-html="lp.unit_collection_title"></div></td></tr>

		<tr v-if="lp.use_unit_numbers||lp.use_unit_intervals">
			<td v-for="(unit, i) in lp.units" :key="i" class="k-learning-progression-unit-td" :class="unit_showing==unit?'k-learning-progression--showing'+unit_css_class[i]:unit_css_class[i]" :colspan="unit_duration_colspan(unit.duration)" :style="'width:'+unit_duration_width(unit.duration)+'%'">
				<div class="k-learning-progression-unit-header" :class="(unit_showing==null||unit_showing==unit)?'-light k-learning-progression--showing':'k-learning-progression-unit-header--not-selected'" :style="U.collection_color_style(collection,'light')">
					<span v-if="lp.use_unit_numbers" v-html="unit.display_number?unit.display_number:'COURSE-WIDE'" :style="[unit.display_number?{}:{'font-size': '0.8em;'}, {'color': U.get_contrast_color(lp.color, 'light')}]"></span>
					<span v-if="lp.use_unit_intervals" style="font-size:0.9em;text-transform:uppercase;color:#666;" :style=" {'color': U.get_contrast_color(lp.color, 'light')}" v-html="unit_duration_display(unit)"></span>
				</div>
			</td>
		</tr>
		<tr>
			<td v-for="(unit, i) in lp.units" :key="i" class="k-learning-progression-unit-td k-learning-progression-unit-title" :class="unit_title_css_class(unit, i)" :colspan="unit_duration_colspan(unit.duration)" :style="lp.terms.length>0?'':'width:'+unit_duration_width(unit.duration)+'%'">
				<div v-html="unit.title"></div>
			</td>
		</tr>
		<tr>
			<td v-for="(unit, i) in lp.units" :key="i" class="k-learning-progression-unit-td k-learning-progression-unit-buttons k-learning-progression-unit-title" :class="unit_showing==unit?'k-learning-progression--showing'+unit_css_class[i]:unit_css_class[i]" :colspan="unit_duration_colspan(unit.duration)" style="vertical-align:bottom" :style="lp.terms.length>0?'':'width:'+unit_duration_width(unit.duration)+'%'">
				<div>
					<div v-if="unit_showing!=unit" class="text-center mt-2 k-learning-progression-unit-show-btn"><v-btn color="secondary" small @click="$emit('show_unit',unit)">View <v-icon small class="ml-1">fas fa-caret-down</v-icon></v-btn></div>
					<div v-if="unit_showing==unit" class="text-center mt-2"><v-btn color="secondary" small @click="$emit('hide_unit')">Hide <v-icon small class="ml-1">fas fa-caret-up</v-icon></v-btn></div>
				</div>
			</td>
		</tr>
	</table></div></div>

	<div class="k-learning-progression-unit-showing-wrapper" v-if="unit_showing">
		<CollectionUnit ref="collection_unit"
			:collection="collection" :unit="unit_showing" :instance_units="instance_units" :unit_descriptions_collapsed="unit_descriptions_collapsed" :unit_show_full_description_btn="unit_show_full_description_btn" :unit_description_style="unit_description_style" :term_mode="term_mode"
			:is_collection_admin="is_collection_admin" :filtered_origin_teacher_id="filtered_origin_teacher_id"
			@add_unit="$emit('add_unit')" @toggle_unit="$emit('toggle_unit', $event)"
			@get_instance_units="$emit('get_instance_units')"
			@lesson_shift_update="$emit('lesson_shift_update',$event)"
		></CollectionUnit>
	</div>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CollectionUnit from './CollectionUnit'

export default {
	components: { CollectionUnit },
	props: {
		collection: { type: Object, required: true },
		unit_showing: { required: true },
		instance_units: { type: Object, required: true},
		is_collection_admin: { type: Boolean, required: true },
		unit_descriptions_collapsed: { type: Boolean, required: true },
		unit_show_full_description_btn: { type: Boolean, required: true },
		unit_description_style: { type: String, required: true },
		term_mode: { required: false, default() { return 'normal' }},
		filtered_origin_teacher_id: { required: false, default() { return null } },
	},
	data() { return {
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		// for the purposes of the legacy code here, lp is a synonym of collection
		lp() { return this.collection },
		lp_css_class() {
			// return U.subject_tile_css(this.lp)
			return U.collection_color_style(this.lp)
		},
		terms() {
			if (this.lp.terms.length > 0) return this.lp.terms
			else return [{
				title: 'Curriculum Map',
				duration: 0,
			}]
		},
		term_colspan() {
			if (!this.lp.use_terms || this.lp.terms.length <= 1) {
				let cs = 0
				for (let unit of this.lp.units) {
					cs += this.unit_duration_colspan(unit.duration)
				}
				return cs
			} else {
				let arr = []
				for (let i = 0; i < this.lp.terms.length; ++i) {
					let term = this.terms[i]
					arr[i] = term.duration * 2
				}
				return arr
			}
		},
		term_css_class() {
			let arr = []
			for (let i = 0; i < this.terms.length; ++i) {
				let s = ''
				if (i == 0) s += ' k-learning-progression-term-number--first'
				if (i == this.terms.length-1) s += ' k-learning-progression-term-number--last'

				arr.push(s)
			}
			return arr
		},
		term_style() {
			if (this.lp.terms.length == 0) {
				return ['width:100%']
			} else {
				let arr = []
				for (let i = 0; i < this.lp.terms.length; ++i) {
					let term = this.terms[i]
					arr[i] = 'width:' + (term.duration * 2 / 72 * 100) + '%'
				}
				return arr
			}
		},
		unit_css_class() {
			if (empty(this.lp.units)) return []
			let arr = []
			for (let i = 0; i < this.lp.units.length; ++i) {
				let s = ''
				if (i == 0) s += ' k-learning-progression-unit--first'
				if (i == this.lp.units.length-1) s += ' k-learning-progression-unit--last'
				arr.push(s)
			}
			return arr
		},

	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		term_title_display(term_title) {
			if (this.term_mode == 'block') {
				return term_title.replace(/Quarter/i, 'Block')
			} else {
				return term_title
			}
		},

		unit_title_css_class(unit, i) {
			let s = this.unit_css_class[i]

			if (this.unit_showing == unit) s += ' k-learning-progression--showing'
			
			return s
		},

		unit_duration_colspan(duration) {
			// if we're not using terms or unit intervals, just try to make all units evenly spaced
			if (!this.lp.use_terms || !this.lp.use_unit_intervals) return 1
			// else we finagle things to make the units line up with the terms

			// units that span two quarters or more arent really part of a single quarter, so use colspan and width of 0 for these
			if (duration <= 18) return duration*2
			else return 0
		},

		unit_duration_width(duration) {
			// if we're not using terms or unit intervals, just try to make all units evenly spaced
			if (!this.lp.use_terms || !this.lp.use_unit_intervals) return 100 / this.lp.units.length

			if (duration <= 18) return duration*2/72*100
			else return 0
		},

		unit_duration_display(unit) {
			let duration = unit.duration
			if (duration == 0) return ''
			if (this.term_mode == 'block') duration = duration / 2
			duration = duration + ''
			
			// if duration includes only letters, dashes, and periods, append with 'Weeks'
			if (duration == '1') duration = '1 Week'
			else if (duration.search(/^(\d|-|\.)+$/) > -1) duration = duration + ' Weeks'

			if (this.lp.use_unit_numbers) duration = '<br>' + duration

			// allow for a config that says to *not* show unit dates even if we have weeks
			if (this.$store.state.site_config.hide_unit_dates_in_lps != 'yes') {
				let td = this.lp.get_term_dates_for_unit(unit, this.term_mode, 'short')
				if (td) {
					duration += `<br><nobr style="font-size:12px">${td[0]} – ${td[1]}</nobr>`
				}
			}

			return duration
		},
	}
}
</script>

<style lang="scss">

.k-learning-progression-table-wrapper-outer {
	// padding:0px;
	padding:4px;
	overflow:auto;
	// border-radius: 10px;
}

.k-learning-progression-table-wrapper {
	border-radius: 10px;
	// max-width:1400px;
	min-width:980px;
	margin:0 auto 0 auto;
	box-shadow:0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.k-learning-progression {
	border-collapse:collapse;
	width:100%;
	font-size:16px;

	td {
		vertical-align:top;
	}

	.k-learning-progression-term-number {
		border-left:2px solid #bbb;
		border-right:2px solid #bbb;
		// border-bottom:2px solid #bbb;
		padding:10px;
		div {
			color:#fff;
			font-weight:900;
			text-align:center;
			text-transform:uppercase;
		}
	}
	.k-learning-progression-term-number--first {
		border-top-left-radius: 10px;
		border-left:0;
	}
	.k-learning-progression-term-number--last {
		border-top-right-radius:10px;
		border-right:0;
	}

	.k-learning-progression-unit-td {
		border-left:2px solid #bbb;
		border-right:2px solid #bbb;
		height:1px;	// for some reason this makes the height:100% in .k-learning-progression-unit-header "stick"
	}

	.k-learning-progression-unit-header {
		// white-space:nowrap;
		line-height:1.2em;
		padding:8px;
		color:#222;
		font-weight:bold;
		text-align:center;
		height:100%;
	}

	.k-learning-progression-unit-header--not-selected {
		background-color:#ccc;
	}

	.k-learning-progression-unit-title {
		text-align:center;
		font-size:15px;
		line-height:1.3em;
		padding:10px;
		background-color:#fff;
	}

	.k-learning-progression-unit--first {
		border-left:0;
	}
	.k-learning-progression-unit--last {
		border-right:0;
	}

	.k-learning-progression-unit-buttons.k-learning-progression-unit--first {
		border-bottom-left-radius:10px;
	}
	.k-learning-progression-unit-buttons.k-learning-progression-unit--last {
		border-bottom-right-radius:10px;
	}
}

.k-learning-progression--unit-showing {
	.k-learning-progression-unit-header {
		background-color:rgba(192,192,192,0.3);
		color:rgba(24,24,24,0.3);
	}
	.k-learning-progression-unit-title {
		background-color:rgba(255,255,255,0.3);
		color:rgba(24,24,24,0.3);
		.k-learning-progression-unit-show-btn .v-btn {
			background-color:#ccc!important;
		}
	}

	// note that unit-header is on a div inside the td, whereas unit-title is on the td itself
	.k-learning-progression--showing .k-learning-progression-unit-header, .k-learning-progression--showing.k-learning-progression-unit-title {
		background-color:#fff;
		color:#222;
	}

}

.k-learning-progression-unit-showing-wrapper {
	background-color:#fff;
	max-width:1000px;
	padding:8px;
	border-radius:10px;
	margin:12px auto;
}

</style>
